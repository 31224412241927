import React, {Component, Fragment} from 'react';
import AssociationService from "../../services/api/association";
import {Field, reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ClubService from "../../services/api/club";
import {numberFormat} from "../shared/numberFormat";

class ChangeClubsMembershipTypeForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			membershipTypes: [],
			membershipLoading: true,
			isAllSelected: false,
			type: 'selected',
		};
		this.getMembershipTypes = this.getMembershipTypes.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		this.getMembershipTypes();
		this.setState({type: this.props.isAllSelected ? 'filtered' : 'selected'})
	}

	getMembershipTypes(){
		this.setState({membershipLoading: true});
		AssociationService.getMembershipTypes(this.props.association.id, {include_used_membership: 'false'})
			.then(res => {
				let mt = res.membership_types.filter(membershipType => membershipType.active !== "false").map(membershipType => {return {...membershipType, ...{name: membershipType.code + " - " + membershipType.description}}});
				this.setState({membershipLoading: false, membershipTypes: mt.sort((a, b) => a['name']?.toString().localeCompare(b['name']?.toString()))});
			})
			.catch(err => {
				console.error(err);
				this.setState({membershipLoading: false, membershipTypes: []});
			})
	}

	onSubmit(values,dispatch,props) {
		let toChange=[];

		let params = {};


		if (this.state.isAllSelected) {
			toChange = this.state.membershipTypes.map(t => {return t.id;})
		} else {
			this.state.membershipTypes.forEach(t => {
				if(t.isChecked) {
					toChange.push(t.id);
				}
			});
		}


		let data = {membership_ids: toChange};

		if (this.props.isAllSelected) {
			params = {...params, ...props.filters};
			delete params['page'];
			delete params['per_page'];

			data.select_all = true;
			data.unchecked_items = [...this.props.clubs];
		} else {
			data.club_ids = [...this.props.clubs];
		}

		if(this.props.type === "apply") {

			return ClubService.addMembershipType(data, params)
				.then(res => {
					this.props.onSuccess(res);
				})
				.catch(err => {
					console.error(err)
				})
		} else {
			return ClubService.removeMembershipType(data, params)
				.then(res=>{
					this.props.onSuccess(res);
				}).catch(err => {
				console.error(err);
			})
		}

	}

	render() {
		const {handleSubmit, submitting} = this.props;
		return (
			<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
				<Fragment>

					<div className="row">
						<div className="col is-full">
							<h2 className="form-section__title">{this.props.association.name}</h2>
						</div>
					</div>

					<div className="row">
						<div className="col">
							<span className="label">Please chose the Membership Type(s):</span>
							<table className="data__table normal-overflow">
								<thead>
								<tr>
									<th className={'primary'}>
										<Field
											onClick={
												() => {
													let membershipTypes = this.state.membershipTypes.map(t => {
														t.isChecked = !this.state.isAllSelected;
														return t;
													});
													this.setState({membershipTypes,isAllSelected: !this.state.isAllSelected});
												}
											}
											checked={this.state.isAllSelected}
											id={'isAllSelected'}
											value={true}
											name={'isAllSelected'}
											component={'input'}
											type="checkbox"
										/>
										<label htmlFor={'isAllSelected'}></label>
									</th>
									<th>Membership Code - Membership Description</th>
									<th>Bill Rate</th>
								</tr>
								</thead>
								<tbody>
								{this.state.membershipTypes.length ? this.state.membershipTypes.map((type, key) => {
										return (
											<tr key={key}>
												<td className={'primary'}>
													<Field
														onClick={
															() => {
																let membershipTypes = this.state.membershipTypes.map(t => {
																	if(t.id === type.id){
																		type.isChecked = !type.isChecked;
																		return type
																	} else {
																		return t;
																	}
																});
																this.setState({membershipTypes, isAllSelected: false});
															}
														}
														checked={type.isChecked || this.state.isAllSelected}
														id={type.id + '.is_checked'}
														value={true}
														name={type.id + '.is_checked'}
														component={'input'}
														type="checkbox"
													/>
													<label htmlFor={type.id + '.is_checked'}></label>
												</td>
												<td>{type.name}</td>
												<td>{numberFormat(type.bill_rate)}</td>
											</tr>
										)})
									:
									<tr>
										<td colSpan={3}>
											{this.state.membershipLoading ? <span className="data-table__loader"></span> : <h1>No data available.</h1>}
										</td>
									</tr>
								}
								</tbody>
							</table>
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<button className="btn fill gray" onClick={()=>{this.props.closeModal()}}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button className="btn fill blue"
									disabled={submitting || this.state.membershipTypes.length === 0}
									type="submit">{this.props.type}</button>
						</div>
					</div>

				</Fragment>
			</form>
		);
	}
}

ChangeClubsMembershipTypeForm = reduxForm({
	form: 'changeClubsMembershipTypeForm',
	destroyOnUnmount: true
})(ChangeClubsMembershipTypeForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeClubsMembershipTypeForm);
