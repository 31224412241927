import React, {Fragment, useCallback, useEffect, useState} from "react";
import {addAlert} from "../../../../shared/actions";
import {bindActionCreators} from 'redux';
import {connect} from "react-redux";
import TableFilter from './OffersFilter';
import Modal from "react-modal";
import GhinTable from "../../../../shared/GhinTable";
import JoinAndRenewServices from "../../../../../services/api/joinAndRenew";
import moment from "moment/moment";
import DeleteOfferModal from "../../../../Modals/DeleteOfferModal";
import OfferDetails from "./OfferDetails";
import AddOrEditOfferModal from "../../../../Modals/AddOrEditOfferModal";
import OfferEditLogModal from "../../../../Modals/OfferEditLogModal";

function Offers(props) {
  const [filters, setFilters] = useState({});
  const [offers, set_offers] = useState([]);
  const [loading, set_loading] = useState(false);
  const [addOfferModalIsOpen, set_addOfferModalIsOpen] = useState(false);
  const [deleteModalIsOpen, set_deleteModalIsOpen] = useState(false)
  const [selectedOffer, set_selectedOffer] = useState(null)
  const [selectedDetailsOffer, set_selectedDetailsOffer] = useState({})
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [pages, setPages] = useState(null);
  const [total, setTotal] = useState(null);
  const [sorted, setSorted] = useState([{ id: 'created_at', desc: true }]);
  const [sortingChanged, setSortingChanged] = useState(false);
  const [displayDetails, set_displayDetails] = useState(false)
  const [expandedIndex, set_expandedIndex] = useState(-1)
  const [offerEditLogModalIsOpen, setOfferEditLogModalIsOpen] = useState(false);

  useEffect(() => {
    const length = Object.keys(filters).length;
    if (length !== 0)
      updateTable(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, page, perPage, props.associationId, sortingChanged])

  const updateTable = (params) => {
    const backup = displayDetails
    set_displayDetails(false)
    set_loading(true);
    params['page'] = page + 1;
    params['per_page'] = perPage;
    params['sorting_criteria'] = sorted[0].id || 'created_at';
    params['order'] = sorted[0].desc ? 'desc' : 'asc';

    JoinAndRenewServices.getPromotionalCodes(props.associationId, params)
      .then(res => {
        set_offers(res.promotional_codes);
        if (selectedDetailsOffer !== null)
          set_selectedDetailsOffer(res.promotional_codes[expandedIndex])
        set_loading(false);
        setTotal(parseInt(res["total-count"], 10));
        setPerPage(parseInt(res["per-page"], 10));
        setPages(parseInt(res["total-pages"], 10));
        set_displayDetails(backup)
      })
      .catch(err => {
        console.log(err);
        set_loading(false);
      })
  }

  const updateFilters = useCallback((newFilters) => {
    setFilters(newFilters);
  }, []);

  const columns = [{
    Header: "Date",
    colClassName: 'col_offer_date',
    sortable: true,
    Cell: props => moment(new Date(props.original.initial_created_at), 'YYYY/MM/DD HH:mm:ss').format('MM/DD/YYYY')
  }, {
    Header: "Offer Code", accessor: "code", className: 'col_offer_code force_tooltip', sortable: true, Cell: data => {
      return <>
        <div className="offer_code_name">{data.original.code}</div>
        <span className="cmd-button" style={{marginLeft: 0}}>
          <div className="blue_ribbon ribbon_hover"
               style={{marginTop: "2px", transform: "translateY(5px)"}}>
            {data.original.edited && <i className="material-icons-outlined blue"
                                        style={{lineHeight: "1px"}} onClick={() => {
              set_selectedOffer(data.original);
              setOfferEditLogModalIsOpen(true)
            }}>bookmark</i>}
          </div>
        </span>
      </>
    }
  }, {
    Header: <Fragment>Auto<br/>Apply</Fragment>,
    accessor: "auto_apply",
    colClassName: 'col_auto_apply',
    className: 'table-align-center',
    thClassName: 'table-align-center',
    sortable: true,
    Cell: data => {
      return <div>{data.original.auto_apply ? 'Yes' : 'No'}</div>
    }
  }, {
    Header: <Fragment>Offer<br/>Type</Fragment>,
    accessor: "offer_type",
    colClassName: 'col_offer_type',
    className: 'table-align-center',
    thClassName: 'table-align-center',
    sortable: true,
    Cell: data => {
      return <div>{data.original.offer_type === 'discount' ? "D" : "P"}</div>
    }
  }, {
    Header: <Fragment>Discount<br/>Amount</Fragment>,
    accessor: "discount_value",
    colClassName: 'col_offer_discount_amount',
    className: 'table-align-center',
    thClassName: 'table-align-center',
    sortable: false,
    Cell: data => {
      return <>{data.original.offer_type === 'promotion' ? '-' : data.original.discount_type === 'dollar' ? `$${data.original.discount_value.toFixed(2)}` : `${data.original.discount_value}%`}</>
    }
  }, {
    Header: <Fragment>Club<br/>Discount?</Fragment>,
    accessor: "club_discount",
    colClassName: 'col_club_discount',
    className: 'table-align-center',
    thClassName: 'table-align-center',
    sortable: true,
    Cell: data => {
      return <div>{data.original.offer_type === 'discount' ? (data.original.club_discount ? 'Yes' : 'No') : "-"}</div>
    }
  }, {
    Header: "Status",
    accessor: "active",
    colClassName: 'col_offer_status',
    className: 'table-align-center',
    sortable: true,
    Cell: data => {
      return <div>{data.original.status === 'Active' ? <span style={{color: "green"}}>A</span> :
        <span style={{color: "red"}}>I</span>}</div>
    }
  }, {
    Header: "Applied To",
    accessor: "available_to_all_ojr_clubs",
    colClassName: 'col_offer_applied_to',
    sortable: true,
    Cell: data => {
      return <div>{data.original.available_to_all_ojr_clubs ? "All Clubs" : "Selected Clubs"}</div>
    }
  }, {
    Header: <Fragment>Valid</Fragment>,
    colClassName: 'col_offer_range_date',
    accessor: "valid_from",
    className: 'table-align-center',
    thClassName: 'table-align-center',
    sortable: true,
    Cell: props =>{
      const value = props.original;
      const from_date = (<>From: {moment(new Date(value.valid_from), 'YYYY/MM/DD HH:mm:ss').format('MM/DD/YYYY')}</>);
      const to_date = (<><br/> To: {moment(new Date(value.valid_to), 'YYYY/MM/DD HH:mm:ss').format('MM/DD/YYYY')}</>); 
      return <span>
        <div>
          {from_date}
          {value.valid_to ? to_date : null}
        </div>
     </span>
    } 
  }
, {
    Header: <Fragment>Times<br/>Used</Fragment>,
    colClassName: 'col_offer_times_used',
    accessor: "times_used",
    className: 'table-align-center',
    thClassName: 'table-align-center',
    sortable: true,
  }, {
    colClassName: 'col_offer_actions',
    sortable: false, Cell: data => {
      return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {props.canEditAssociation && <button className="btn lnk" style={{display: "inline-block"}} onClick={() => {
          set_selectedOffer(data.original)
          set_deleteModalIsOpen(true)
        }}>Delete
        </button>}
        <button className="btn fill green" style={{width: "70px", marginLeft: "10px", display: "inline-block"}}
                onClick={() => {
                  if (expandedIndex === data.index && displayDetails) {
                    set_displayDetails(false)
                  }
                  else {
                    set_selectedDetailsOffer(data.original)
                    set_expandedIndex(data.index)
                    set_displayDetails(true)
                    set_selectedOffer(null)
                  }
                }}
        >Details
        </button>
      </div>
    }
  },
  {
    expander: true,
    show: false
  }]
  const deleteOffer = () => {
    JoinAndRenewServices.deletePromotionalCode(props.associationId, selectedOffer.id).then(() => {
      props.addAlert({type: 'success', message: 'Offer successfully deleted'});
      updateTable(filters);
    }).catch(err => {
      console.log(err);
      if (err.data.errors) {
        const error = err.data.errors
        props.addAlert({type: "error", message: error[Object.keys(error)[0]][0]})
      }
    }).finally(() => {
      set_deleteModalIsOpen(false);
    });
  };

  const exportOffer = (modal) => {
    const id = modal === 'Delete Modal' ? selectedOffer.id : selectedDetailsOffer.id
    JoinAndRenewServices.exportPromotionalCode(props.associationId, id).then((response) => {
      props.addAlert({type: 'success', message: 'Offer successfully exported'});
      window.open(response.url, "_blank")
    }).catch(err => {
      console.log(err);
      if (err.data.errors) {
        const error = err.data.errors
        props.addAlert({type: "error", message: error[Object.keys(error)[0]][0]})
      }
    });
  };

  return (<Fragment>
      <div className="columns">
        <div className="row">
          <div className="panel">
            <div className="panel__head">
              Offers
            </div>
            <div className="panel__body">
              <div className="row">
                <div className="col">
                  <span>Create and manage discounts and promotions. To create a new discount or promotion, click the <strong>Add Offer</strong> button and provide the requested information. To view or edit an existing discount or promotion, click the <strong>Details</strong> button below.</span>
                </div>
              </div>
              <div className='row' style={{marginBottom: "0px"}}>
                <div className='col'>
                  <TableFilter updateFilters={updateFilters}/>
                </div>
              </div>
              <div className="row">
                {props.canEditAssociation && <div className="col is-1-of-6 push-right">
                  <button className="btn fill green"
                          onClick={() => {
                            set_addOfferModalIsOpen(true)
                            set_selectedOffer(null)
                          }}
                  >Add Offer
                  </button>
                </div>}
              </div>
              <div className="row" style={{display: "flex", justifyContent: "end", padding: "0 8px"}}>
                <div className="offer_legend table__legend margin-top-16 blue_ribbon ribbon_text"
                     style={{marginRight: "10px"}}>
                  <p>Offer Type: <strong>D</strong> - Discount, <strong>P</strong> - Promotion</p>
                </div>
                <div className="offer_legend table__legend margin-top-16 blue_ribbon ribbon_text">
                  <i className="material-icons-outlined blue">bookmark</i>
                  <p>Modified Offer</p>
                </div>
              </div>
              <div className="row">
                <div className="col jc-fs">
                  <GhinTable
                    loading={loading}
                    idKey={'offers'}
                    hideSelect={true}
                    data={offers}
                    sortable={true}
                    columns={columns}
                    pages={pages}
                    page={page}
                    total={total}
                    pageSize={perPage}
                    manual
                    onPageChange={(index) => setPage(index)}
                    onPageSizeChange={(size, index) => {
                      setPerPage(size);
                      setPage(index);
                    }}
                    onSortedChange={(newSorted) => {
                      setPage(0)
                      setSorted(newSorted);
                      setSortingChanged(!sortingChanged)
                    }}
                    expandedIndex={expandedIndex}
                    displayMembershipDetails={displayDetails}
                    SubComponent={row => {
                      return <OfferDetails
                        data={row.original}
                        association_id={props.associationId}
                        canEditAssociation={props.canEditAssociation}
                        selected_offer={selectedDetailsOffer}
                        reloadPages={() => {
                          updateTable(filters);
                        }}
                        exportOffer={(modal) => {exportOffer(modal)}}
                      />
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={addOfferModalIsOpen}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
      >
        <AddOrEditOfferModal
          reload_offers={() => {
            updateTable(filters);
          }}
          closeModal={() => {
            set_addOfferModalIsOpen(false)
          }}
          operationType={"Create"}
          association_id={props.associationId}
          selected_offer={selectedOffer}
        />
      </Modal>

      <Modal
        isOpen={offerEditLogModalIsOpen}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
      >
        <OfferEditLogModal
          closeModal={() => {setOfferEditLogModalIsOpen(false)}}
          selected_offer={selectedOffer}
          association_id={props.associationId}
        />
      </Modal>

      <DeleteOfferModal
        openModal={deleteModalIsOpen}
        onConfirmAction={() => {
          deleteOffer()
        }}
        exportOffer={(modal) => {exportOffer(modal)}}
        modalIcon={"help_outline"}
        onCancelAction={() => {
          set_deleteModalIsOpen(false)
        }}
        closeModal={() => {
          set_deleteModalIsOpen(false)
        }}
        offerCode={`${selectedOffer ? selectedOffer.code : ''}`}
        cancelLabel={"No"}
        confirmLabel={"Yes"}/>
    </Fragment>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({addAlert}, dispatch);
}

export default connect(null, mapDispatchToProps)(Offers);
