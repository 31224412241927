/**
 *
 * @param access
 * @returns {string}
 */


export function buildAccess(access, emails) {
	if (access) {
		access.clubs = access.clubs.filter(c => c.user_access && c.user_access.active === "true");
		access.associations = access.associations.filter(a => a.user_access && a.user_access.active === "true");
		access.federations = [];
		if (access.associations.length === 0 && access.clubs.length === 0 && access.federations.length === 0 && access.super_user === "false") {
			return null;
		} else {
			let userAccess = {};
			userAccess.homepage = setHomePage(access);
			userAccess.super_user = access.super_user === 'true';
			userAccess.reporting_token = access.reporting_token;
			userAccess.notification_user = emails.notifications_users.filter(nUser => nUser === access.email).length === 1;
			userAccess.upload_user = emails.upload_users.filter(nUser => nUser === access.email).length === 1;
			userAccess.push_notification_user = emails.push_notification_users.filter(nUser => nUser === access.email).length === 1;
			delete access.email;
			userAccess.canChangeAffiliation = access.associations.length > 1 || access.clubs.length > 1 || access.federations.length > 1 || (access.associations.length + access.clubs.length + access.federations.length) > 1;
			userAccess.association_role = access.associations.length === 1 ? access.associations[0].user_access.role : null;
			userAccess.club_role = access.clubs.length === 1 ? access.clubs[0].user_access.role : null;
			userAccess.default_club_id = 0;
			userAccess.default_association_id = 0;
			userAccess.user_type = access.super_user === 'true' ?
				'super-admin' : (userAccess.association_role ?
						(userAccess.association_role === "admin" ?
								"association-admin" : (userAccess.association_role === "read_only" ? "association-read-only" : userAccess.association_role === "score_maintenance" ? "association-score-maintenance" : 'score-only')
						) : (userAccess.club_role ? (userAccess.club_role === "admin" ? "club-admin" : userAccess.club_role === "read_only" ? "club-read-only" : userAccess.club_role === "score_maintenance" ? "club-score-maintenance" : 'score-only') : 'read-only')
				);

			if ((["club-admin", "club-read-only", "club-score-maintenance"].includes(userAccess.user_type) || userAccess.club_role) && !userAccess.canChangeAffiliation) {
				userAccess.default_association_id = parseInt(access.clubs[0].user_access.association_id);
				userAccess.default_association_name = access.clubs[0].user_access.association_name;
				userAccess.default_club_id = parseInt(access.clubs[0].club.id);
				userAccess.default_club_name = access.clubs[0].user_access.organization_name;
				userAccess.reporting_token = access.clubs[0].user_access.reporting_token;
			}

			if ((["association-admin", "association-read-only", "association-score-maintenance"].includes(userAccess.user_type) || userAccess.association_role) && !userAccess.canChangeAffiliation) {
				userAccess.default_association_id = parseInt(access.associations[0].golf_association.id);
				userAccess.default_association_name = access.associations[0].user_access.organization_name;
				userAccess.reporting_token = access.associations[0].user_access.reporting_token;
			}

			// userAccess.homepage = setHomePage(access, userAccess.user_type);
			return userAccess;
		}
	} else {
		return null;
	}
}

/**
 * Set homepage for known
 * @param access
 * @returns {string}
 */

function setHomePage(access, user_type) {
	if (access.super_user === 'true') {
		return '/manage'
	}

	if (access.associations.length === 1 && access.clubs.length === 0) {
		return `/manage/association/${access.associations[0].golf_association.id}`
	}

	if (access.associations.length === 0 && access.clubs.length === 1) {
		return `/manage/association/${access.clubs[0].user_access.association_id}/club/${access.clubs[0].club.id}`;
	}
}

export function getPermission(userType,permissionFor) {
	switch(permissionFor){
		case 'edit-associations' :
			return (userType === "super-admin");
		case 'edit-association' :
			return (userType === "super-admin" || userType === "association-admin");
		case 'edit-club':
			return (userType === "super-admin" || userType === "association-admin" || userType === "club-admin");
    case 'read-club':
      return (userType === 'super-admin' || userType === 'association-read-only' || userType === 'club-read-only')
		case 'manage-scores':
			return ["super-admin", "association-admin", "club-admin", "association-score-maintenance", "club-score-maintenance"].includes(userType);
		default :
			return false;
	}
}

export function getAllPermissions(access) {
	let permissions = {associations: [], clubs: []}
	if(access.associations.length > 0) {
		access.associations.forEach(association => {
			if(association.user_access.role === "admin") {
				permissions.associations.push(parseInt(association.golf_association.id));
			}
		});
	}
	if(access.clubs.length > 0) {
		access.clubs.forEach(club => {
			if(club.user_access.role === "admin"){
				permissions.clubs.push(parseInt(club.club.id));
			}
		});
	}
	return permissions;
}

export function getAllAccesses(access) {
	let permissions = {associations: [], clubs: []}
	if(access.associations.length > 0) {
		access.associations.forEach(association => {
			permissions.associations.push(parseInt(association.golf_association.id));
		});
	}
	if(access.clubs.length > 0) {
		access.clubs.forEach(club => {
			permissions.clubs.push(parseInt(club.club.id));
		});
	}
	return permissions;
}

export function checkAccess(allPermisions, association_id, club_id) {
	association_id = association_id === null || association_id === undefined ? "" : association_id;
	club_id = club_id === null || club_id === undefined ? "" : club_id;
	return allPermisions.associations.filter(assoc => assoc?.toString() === association_id?.toString()).length > 0 || allPermisions.clubs.filter(club => club?.toString() === club_id?.toString()).length > 0;
}
