import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Field, reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import {InputField} from "../../inputs/InputField";
import {
  capsAlphaNumericOrHyphenOrUnderscore,
  customMsgRequired,
  greaterThanCustomMessage,
  isCustomNumeric,
  isNumber,
  lowerThanCustomMessage,
  maxLength,
  requiredMultiSelect,
} from "../../inputs/validations";
import {SelectInput} from "../../inputs/Select";
import {DatePickerInput} from "../../inputs/DatePicker";
import moment from "moment/moment";
import AssociationService from "../../services/api/association";
import {PickySelect} from "../../inputs/PickySelect";
import JoinAndRenewServices from "../../services/api/joinAndRenew";
import ReactTooltip from "react-tooltip";
import OfferSubtextTooltip from "../../img/offer_subtext_tooltip.png"

function AddOfferFunction(props) {
  const {handleSubmit, pristine, submitting, invalid} = props;
  const [loading, set_loading] = useState(true)
  const [error, set_error] = useState(null)
  const [active, set_active] = useState(true)
  const [auto_apply, set_auto_apply] = useState(false)
  const [is_discount, set_is_discount] = useState(true)
  const [club_discount, set_club_discount] = useState(true)
  const [is_dollar, set_is_dollar] = useState(true)
  const [available_to_all_ojr_clubs, set_available_to_all_ojr_clubs] = useState(true)
  const [clubs, set_clubs] = useState([]);
  const [clubs_options, set_clubs_options] = useState([]);
  const [available_to_all_golfers, set_available_to_all_golfers] = useState(true)
  const [available_to_renewing_golfers, set_available_to_renewing_golfers] = useState(false);
  const [available_to_lapsed_golfers, set_available_to_lapsed_golfers] = useState(false);
  const [available_to_new_golfers, set_available_to_new_golfers] = useState(false);
  const gender_options = [{
    value: "male and female", label: "Male & Female",
  }, {
    value: "male", label: "Male",
  }, {
    value: "female", label: "Female",
  },];
  const [maximum_age, set_maximum_age] = useState(100);
  const [minimum_age, set_minimum_age] = useState(0);
  const [valid_from, set_valid_from] = useState('');
  const [valid_to, set_valid_to] = useState('');

  const getOJRClubs = () => {
    let params = {
      page: 1,
      per_page: 100,
      sorting_criteria: 'club_name',
      order: 'asc',
      status: 'Active',
      join_and_renew_enabled: true
    };

    AssociationService.getClubs(props.association_id, params)
      .then((data) => {
        let clubs_options = data.clubs.map((club) => {
          return {
            label: club.club_name, value: club.id
          }
        })
        set_clubs_options(clubs_options)
        set_loading(false)
      })
      .catch(err => {
        console.error(err);
        set_loading(false)
      });
  }

  useEffect(() => {
    set_loading(true)
    const selected_offer = props.selected_offer
    const initialize = props.initialize
    initialize({
      ...selected_offer, gender_eligibility: {
        value: "male and female", label: "Male & Female",
      }
    })
    getOJRClubs()
  }, [props.association_id, props.selected_offer])

  const constructParams = (values) => {
    const form_keys = ["code", "description", "subtext", "usage_limit", "golfer_usage_limit", "minimum_age", "maximum_age"]
    let params = form_keys.reduce((acc, key) => {
      if (values.hasOwnProperty(key)) {
        acc[key] = values[key];
      }
      return acc;
    }, {});
    params['gender_eligibility'] = values.gender_eligibility.value
    params['offer_type'] = is_discount ? 'discount' : 'promotion'
    if (is_discount) {
      params['club_discount'] = club_discount
      params['discount_type'] = is_dollar ? 'dollar' : 'percentage'
      params['discount_value'] = values.discount_value
    }
    params['auto_apply'] = auto_apply
    params['active'] = active
    params['available_to_all_ojr_clubs'] = available_to_all_ojr_clubs
    if (available_to_all_ojr_clubs === false) params['club_ids'] = clubs.map(club => club.value)
    params['available_to_all_golfers'] = available_to_all_golfers
    if (available_to_all_golfers === false) {
      params['available_to_new_golfers'] = available_to_new_golfers
      params['available_to_lapsed_golfers'] = available_to_lapsed_golfers
      params['available_to_renewing_golfers'] = available_to_renewing_golfers
    }
    const auxValidFrom = new Date(values.valid_from);
    params['valid_from'] = new Date(Date.UTC(auxValidFrom.getFullYear(), auxValidFrom.getMonth(), auxValidFrom.getDate(), 0, 0, 0)).toJSON().split('T')[0];
    if (values.valid_to) {
      const auxValidTo = new Date(values.valid_to);
      params['valid_to'] = new Date(Date.UTC(auxValidTo.getFullYear(), auxValidTo.getMonth(), auxValidTo.getDate(), 0, 0, 0)).toJSON().split('T')[0];
    }
    return params;
  }

  const onSubmit = (values, dispatch, props) => {
    const params = constructParams(values);
    return JoinAndRenewServices.createPromotionalCode(props.association_id, params).then(res => {
      props.reload_offers()
      props.addAlert({type: 'success', message: 'Offer successfully created'})
      props.closeModal()
      set_error(null)
    }).catch((e) => {
      let error = ""
      try {
        let key = Object.keys(e.data.errors)[0]
        error = e.data.errors[key][0]
      } catch {
        error = "An error has occurred"
      }
      set_error(error)
    });
  }

  const invalid_members = () => {
    return !available_to_all_golfers && !available_to_renewing_golfers && !available_to_lapsed_golfers && !available_to_new_golfers;
  }

  if (loading) return (<div style={{padding: "10px"}}>
    <div className="row centered-items">
      <div className="col auto">
        <div className={'loader'}>
          <span className="data-table__loader"></span>
        </div>
      </div>
    </div>
  </div>)

  return (<form onSubmit={handleSubmit(onSubmit)}>
    <div className="row">
      <div className="col">
        <h5 className="checkboxes_title">Code Setup</h5>
      </div>
    </div>

    <div className="row">
      <div className="col">
        <label htmlFor="code">Offer Code <span>*</span></label>
        <Field component={InputField}
               validate={[capsAlphaNumericOrHyphenOrUnderscore, customMsgRequired('Please provide an Offer Code'), maxLength([15, 'Offer Code cannot exceed 15 characters'])]}
               type="text" name="code" id="code" normalize={(value) => value.toUpperCase()}/>
      </div>
    </div>

    <div className="row">
      <div className="col is-1-of-7">
        <Field
          component={'input'}
          type="checkbox"
          name="active"
          id="active"
          value={true}
          checked={active}
          onClick={() => set_active(!active)}
        />
        <label style={{color: 'black'}} htmlFor="active">Active</label>
      </div>
      <div className="col">
        <Field
          component={'input'}
          type="checkbox"
          name="auto_apply"
          id="auto_apply"
          value={true}
          checked={auto_apply}
          onClick={() => set_auto_apply(!auto_apply)}
        />
        <label style={{color: 'black', marginBottom: 0}} htmlFor="auto_apply">Automatically Apply</label>
      </div>
    </div>

    <div className="row">
      <div className="col">
        <label htmlFor="description">Offer Description <span>*</span></label>
        <Field component={InputField}
               validate={[customMsgRequired('Please provide an Offer Description'), maxLength([30, 'Offer Description cannot exceed 30 characters'])]}
               type="text" name="description" id="description"/>
      </div>
    </div>

    <div className="row" style={{marginBottom: "8px"}}>
      <div className="col icon_label">
        <label htmlFor="subtext" style={{marginBottom: 0}}>Subtext <span>*</span></label>
      </div>
      <div className="col" style={{flex: "initial", paddingLeft: 0}}>
        <div>
          <a href="#donothing" onClick={(e) => {
            e.preventDefault()
          }}
             data-tip data-for={`subtext`}>
            <i className="material-icons v-mid" style={{color: "#0089CE", fontSize: "18px"}}>info</i>
          </a>
          <ReactTooltip id={`subtext`} className="table-head__tooltip" place='bottom' effect={'float'}>
            <div style={{padding: '10px 0px'}}>
              <p>Subtext will display under the Offer Code on the registration page when the code is applied by the
                golfer.</p>
              <br></br>
              <img alt=''
                   src={OfferSubtextTooltip}
              />
            </div>
          </ReactTooltip>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <Field component={InputField}
               className="italic-placeholder"
               validate={[customMsgRequired('Please provide an Subtext'), maxLength([30, 'Subtext cannot exceed 30 characters'])]}
               type="text" name="subtext" id="subtext" placeholder={"Example: $10 Discount Applied"}/>
      </div>
    </div>

    <div className="row" style={{marginBottom: "8px"}}>
      <div className="col icon_label">
        <label htmlFor="offer_type" style={{marginBottom: 0}}>Offer Type <span>*</span></label>
      </div>
      <div className="col" style={{flex: "initial", paddingLeft: 0}}>
        <div>
          <a href="#donothing" onClick={(e) => {
            e.preventDefault()
          }}
             data-tip data-for={`offer_type`}>
            <i className="material-icons v-mid" style={{color: "#0089CE", fontSize: "18px"}}>info</i>
          </a>
          <ReactTooltip id={`offer_type`} className="table-head__tooltip" place='bottom' effect={'float'}>
            <div style={{padding: '10px 0px'}}>
              <p>Discount = Dollar Amount or Percentage that will be taken off the membership fee.</p>
              <br></br>
              <p>Promotion = Entry into sweepstakes or raffle, etc.</p>
            </div>
          </ReactTooltip>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col is-full">
        <div className="radio__container">
          <Field
            id="is_discount"
            value={is_discount}
            onClick={() => {
              set_is_discount(true);
            }}
            name={'is_discount'}
            component={'input'}
            checked={is_discount}
            className="simple__radio"
            type="radio"
          />
          <label htmlFor="is_discount">Discount</label>
          <Field
            id="is_promotion"
            value={is_discount}
            onClick={() => {
              set_is_discount(false);
            }}
            name={'is_promotion'}
            component={'input'}
            checked={!is_discount}
            className="simple__radio"
            type="radio"
          />
          <label htmlFor="is_promotion">Promotion</label>
        </div>
      </div>
    </div>

    {is_discount && <>
      <div className="row" style={{marginBottom: "8px"}}>
        <div className="col">
          <label htmlFor="club_discount" style={{marginBottom: 0}}>Club Discount? <span>*</span></label>
        </div>
      </div>
      <div className="row">
        <div className="col is-full">
          <div className="radio__container">
            <Field
              id="club_discount"
              value={club_discount}
              onClick={() => {
                set_club_discount(true);
              }}
              name={'club_discount'}
              component={'input'}
              checked={club_discount}
              className="simple__radio"
              type="radio"
            />
            <label htmlFor="club_discount">Yes</label>
            <Field
              id="association_discount"
              value={club_discount}
              onClick={() => {
                set_club_discount(false);
              }}
              name={'association_discount'}
              component={'input'}
              checked={!club_discount}
              className="simple__radio"
              type="radio"
            />
            <label htmlFor="association_discount">No</label>
          </div>
        </div>
      </div>
    </>}

    {is_discount && <>
      <div className="row">
        <div className="col is-full">
          <span className="label">Dollar or Percentage <span>*</span></span>
          <div className="radio__container">
            <Field
              id="is_dollar"
              value={is_dollar}
              onClick={() => {
                set_is_dollar(true);
              }}
              name={'is_dollar'}
              component={'input'}
              checked={is_dollar}
              className="simple__radio"
              type="radio"
            />
            <label htmlFor="is_dollar">Dollar Amount</label>
            <Field
              id="is_percentage"
              value={is_dollar}
              onClick={() => {
                set_is_dollar(false);
              }}
              name={'is_percentage'}
              component={'input'}
              checked={!is_dollar}
              className="simple__radio"
              type="radio"
            />
            <label htmlFor="is_percentage">Percentage</label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col is-1-of-2">
          <label htmlFor="discount_value">Dollar Amount or Percentage <span>*</span></label>
          <Field component={InputField}
                 validate={[customMsgRequired('Please provide a numerical Dollar Amount or Percentage'), isCustomNumeric(8)]}
                 type="text" name="discount_value" id="discount_value"/>
        </div>
      </div>
    </>
    }

    <hr
      style={{
        border: "0.5px solid #cccccc", marginTop: "25px", marginBottom: "25px",
      }}>
    </hr>

    <div className="row">
      <div className="col">
        <h5 className="checkboxes_title">Criteria</h5>
      </div>
    </div>

    <div className="row">
      <div className="col is-1-of-2">
        <label htmlFor="usage_limit">Total Number Available</label>
        <Field component={InputField}
               validate={[isNumber, isCustomNumeric(8)]}
               type="text" name="usage_limit" id="usage_limit"/>
        <span className="under_note">Leave blank if there is not limit.</span>
      </div>
    </div>

    <div className="row">
      <div className="col is-1-of-2">
        <label htmlFor="golfer_usage_limit">Number of Uses Per Golfer</label>
        <Field component={InputField}
               validate={[isNumber, isCustomNumeric(8)]}
               type="text" name="golfer_usage_limit" id="golfer_usage_limit"/>
        <span className="under_note">Leave blank if there is not limit.</span>
      </div>
    </div>

    <div className="row" style={{marginBottom: "6px"}}>
      <div className="col is-1-of-2">
        <label htmlFor="valid_from">
          Valid From Date <span>*</span>
        </label>
        <Field
          component={DatePickerInput}
          maxDate={valid_to ? new Date(valid_to) : null}
          minDate={new Date()}
          className="date"
          showMonthDropdown
          showYearDropdown
          validate={[customMsgRequired('Please provide a Valid From Date')]}
          name="valid_from"
          id="valid_from"
          placeholder="Select..."
          onChange={(value) => {
            if (value) {
              const date = new Date(value)
              const dateString = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
              set_valid_from(dateString);
            } else set_valid_from('');
          }}
        />
      </div>
      <div className="col is-1-of-2">
        <label htmlFor="valid_to">
          Valid To Date
        </label>
        <Field
          component={DatePickerInput}
          minDate={valid_from ? new Date(valid_from) : new Date()}
          maxDate={null}
          className="date"
          showMonthDropdown
          showYearDropdown
          name="valid_to"
          id="valid_to"
          placeholder="Select..."
          onChange={(value) => {
            if (value) {
              const date = new Date(value)
              const dateString = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
              set_valid_to(dateString);
            } else set_valid_to('');
          }}
        />
        <span className="under_note">Leave blank if there is not limit.</span>
      </div>
    </div>

    <div className="row">
      <div className="col is-1-of-2">
        <Field
          component={'input'}
          type="checkbox"
          name="available_to_all_ojr_clubs"
          id="available_to_all_ojr_clubs"
          value={true}
          checked={available_to_all_ojr_clubs}
          onClick={() => set_available_to_all_ojr_clubs(!available_to_all_ojr_clubs)}
        />
        <label style={{color: 'black'}} htmlFor="available_to_all_ojr_clubs">Available To All OJR Clubs</label>
      </div>
    </div>

    {!available_to_all_ojr_clubs && <div className="row">
      <div className="col is-full">
        <label htmlFor="clubs">
          Select Clubs <span>*</span>
        </label>
        <Field
          component={PickySelect}
          multiple={true}
          validate={[requiredMultiSelect]}
          name="clubs"
          id="clubs"
          className="react-select-container"
          classNamePrefix="react-select"
          options={clubs_options}
          disabled={clubs_options.length === 0}
          selected={clubs}
          onChange={(e) => {
            set_clubs(e)
          }}
        />
      </div>
    </div>}

    <div className="row">
      <div className="col is-1-of-2">
        <Field
          component={'input'}
          type="checkbox"
          name="available_to_all_golfers"
          id="available_to_all_golfers"
          value={true}
          checked={available_to_all_golfers}
          onClick={() => set_available_to_all_golfers(!available_to_all_golfers)}
        />
        <label style={{color: 'black'}} htmlFor="available_to_all_golfers">Available To All Golfers
          in Selected Clubs</label>
      </div>
    </div>

    {!available_to_all_golfers && <div className="row" style={{marginBottom: "23px"}}>
      <div className="col auto">
        <ul className="table__legend__checkboxes offer_join_type">
          <li>
            <input
              type="checkbox"
              defaultChecked={available_to_renewing_golfers}
              name="available_to_renewing_golfers"
              id="available_to_renewing_golfers"
              onClick={e => set_available_to_renewing_golfers(e.target.checked)}
            />
            <label htmlFor="available_to_renewing_golfers">Renewing</label>
          </li>
          <li>
            <input
              type="checkbox"
              defaultChecked={available_to_lapsed_golfers}
              name="available_to_lapsed_golfers"
              id="available_to_lapsed_golfers"
              onClick={e => set_available_to_lapsed_golfers(e.target.checked)}
            />
            <label htmlFor="available_to_lapsed_golfers">Lapsed</label>
          </li>
          <li>
            <input
              type="checkbox"
              defaultChecked={available_to_new_golfers}
              name="available_to_new_golfers"
              id="available_to_new_golfers"
              onClick={e => set_available_to_new_golfers(e.target.checked)}
            />
            <label htmlFor="available_to_new_golfers">New</label>
          </li>
        </ul>
        {!(available_to_renewing_golfers || available_to_lapsed_golfers || available_to_new_golfers) &&
          <span className="under_note red" style={{marginTop: "-7px"}}>At least one option must be selected.</span>}
      </div>
    </div>}

    <div className="row">
      <div className="col is-1-of-10" style={{textAlign: "left", marginTop: "11px"}}>
        <label htmlFor="gender_eligibility">Gender</label>
      </div>
      <div className="col is-5-of-5">
        <div className="col is-1-of-2">
          <Field
            component={SelectInput}
            id="gender_eligibility"
            name="gender_eligibility"
            className="react-select-container"
            classNamePrefix="react-select"
            options={gender_options}
          />
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col is-1-of-16" style={{textAlign: "left", marginTop: "35px"}}>
        <label htmlFor="age">Age</label>
      </div>
      <div className="col is-4-of-5">
        <div style={{flexDirection: "row", display: "flex"}}>
          <div className="col is-2-of-5">
            <label htmlFor="minimum_age">Minimum Age</label>
            <Field
              component={InputField}
              validate={[isNumber, lowerThanCustomMessage([maximum_age || "", "Minimum Age cannot be higher than Maximum Age",]), maxLength([3, "Value too long (max 3 digits)"])]}
              onChange={(e) => {
                set_minimum_age(e.target.value);
              }}
              type="text"
              name="minimum_age"
              id="minimum_age"
            />
          </div>
          <div className="col is-2-of-5">
            <label htmlFor="maximum_age">Maximum Age</label>
            <Field
              component={InputField}
              validate={[isNumber, greaterThanCustomMessage([minimum_age || "", "Maximum Age cannot be lower than Minimum Age",]), maxLength([3, "Value too long (max 3 digits)"])]}
              onChange={(e) => {
                set_maximum_age(e.target.value);
              }}
              type="text"
              name="maximum_age"
              id="maximum_age"
            />
          </div>
        </div>
      </div>
    </div>

    {error && <span className={'validation__message is-error'}>{error}</span>}

    <div className="row">
      <div className="col is-1-of-2">
        <button type={'button'} className="btn fill gray" onClick={() => props.closeModal()}>Cancel</button>
      </div>
      <div className="col is-1-of-2">
        <button type={'submit'} disabled={pristine || submitting || invalid || invalid_members()}
                className="btn fill blue">Save
        </button>
      </div>
    </div>

  </form>)
}

const AddOfferForm = reduxForm({
  form: 'AddOfferForm', destroyOnUnmount: true
})(AddOfferFunction)

function mapStateToProps() {
  return {
    enableReinitialize: true, keepDirtyOnReinitialize: true, noOverwriteOnInitialize: true
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({addAlert}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(AddOfferForm))
