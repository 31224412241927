import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect, change } from "react-redux";
import { Field, reduxForm } from "redux-form";
import SharedService from '../../services/api/shared';
import {
	required,
	maxLength,
	handicapIndex,
	golfer_age,
	number_of_scores,
	checkSignIsSelected,
	isNumeric,
	isUrl,
} from "../../inputs/validations";
import { InputField } from "../../inputs/InputField";
import { addAlert } from "../shared/actions";
import { SelectInput } from "../../inputs/Select";
import { withKeyPress } from "../shared/highOrderComponent/withKeyPress";
import { DatePickerInput } from "../../inputs/DatePicker";
import { Textarea } from "../../inputs/Textarea";
import GhinFilterSelect from "../shared/GhinFilterSelect";
import { PickySelect } from "../../inputs/PickySelect";
import notificationCategories from "../shared/pushNotificationCategories.json";
import UploadPushNotificationImage from "../../inputs/UploadPushNotificationImage";
import { FederationAccount } from "../../services/api/federationAccount";
import { GolfAssociationGroup } from "../../services/api/federations";
import moment from "moment";
import {
	genderOptions,
	statusOptions,
	membershipTypes,
	time_array,
	notification_category,
} from "../../services/shared/pushNotificationForm";

class EditPushNotificationForFederationForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			scheduled: false,
			now: true,
			date: null,
			image: null,
			image_url: null,
			is_deep_link: false,
			mobile_deep_links: null,
			selected_link: null,
			handicap_index: "",
			golfer_age: "",
			number_of_scores: "",
			time: "",
			day_time: "",
			delete_image: false,
			btn1_blue: false,
			btn2_blue: false,
			btn3_blue: false,
			btn4_blue: false,
			btn5_blue: false,
			btn6_blue: false,
			btn7_blue: false,
			btn8_blue: false,
			btn9_blue: false,
			pristine: true,
		};
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		let push_notification_setting = this.props.push_notification_setting;

		let selectedAssoc = push_notification_setting.audience.associations
			? push_notification_setting.audience.associations.map(
					(association) => {
						return {
							label: association.name,
							value: association.id,
						};
					}
			  )
			: [];

		let selectedAssocGroups = push_notification_setting.audience
			.association_groups
			? push_notification_setting.audience.association_groups.map(
					(association_group) => {
						return {
							label: association_group.name,
							value: association_group.id,
						};
					}
			  )
			: [];

		let membership_types = push_notification_setting.audience
			.membership_types
			? push_notification_setting.audience.membership_types.map(
					(membership_type) => {
						return {
							label: membership_type,
							value: membership_type,
						};
					}
			  )
			: [];

		let gender = push_notification_setting.audience.gender
			? push_notification_setting.audience.gender.map((g) => {
					if (g === "M") {
						return { value: "M", label: "Male" };
					} else {
						return { value: "F", label: "Female" };
					}
			  })
			: [];

		let golfer_status = push_notification_setting.audience.golfer_status
			? push_notification_setting.audience.golfer_status.map((gs) => {
					if (gs === "Active") {
						return { value: "Active", label: "Active" };
					} else {
						return { value: "Inactive", label: "Inactive" };
					}
				})
			: [];

		// handicap_index button logic
		let handicap_index = push_notification_setting.audience.handicap_index;
		let button1 =
			handicap_index.sign === "<" || handicap_index.sign === "<="
				? true
				: false;
		let button2 =
			handicap_index.sign === "=" ||
			handicap_index.sign === ">=" ||
			handicap_index.sign === "<="
				? true
				: false;
		let button3 =
			handicap_index.sign === ">" || handicap_index.sign === ">="
				? true
				: false;

		// golfer_age button logic
		let golfer_age = push_notification_setting.audience.golfer_age;
		let button4 =
			golfer_age.sign === "<" || golfer_age.sign === "<=" ? true : false;
		let button5 =
			golfer_age.sign === "=" ||
			golfer_age.sign === ">=" ||
			golfer_age.sign === "<="
				? true
				: false;
		let button6 =
			golfer_age.sign === ">" || golfer_age.sign === ">=" ? true : false;

		// number_of_scores button logic
		let number_of_scores =
			push_notification_setting.audience.number_of_scores;
		let button7 =
			number_of_scores.sign === "<" || number_of_scores.sign === "<="
				? true
				: false;
		let button8 =
			number_of_scores.sign === "=" ||
			number_of_scores.sign === ">=" ||
			number_of_scores.sign === "<="
				? true
				: false;
		let button9 =
			number_of_scores.sign === ">" || number_of_scores.sign === ">="
				? true
				: false;

		let scheduled =
			push_notification_setting.status === "scheduled" ? true : false;
		let later = push_notification_setting.schedule.later;
		let date = later
			? new Date(push_notification_setting.schedule.scheduled_at)
			: null;
		let hour = date ? date.getHours() : null;
		let day_time = parseInt(hour) >= 12 ? "PM" : "AM";
		//case 00:00 AM
		if (hour === 0) {
			hour = 12;
			day_time = "AM";
		}
		day_time = later ? day_time : null;
		let new_time =
			day_time && day_time === "PM" && parseInt(hour) !== 12
				? parseInt(hour) - 12
				: hour;

		let time = new_time ? new_time + ":00" : "0:00";

		let image_url = push_notification_setting.message.image.url;
		let external_link = push_notification_setting.message.external_link
			? push_notification_setting.message.external_link
			: "";

		this.props.initialize({
			...{
				notification_category: {
					label:
						notificationCategories[
							push_notification_setting.message
								.notification_category
						],
					value:
						push_notification_setting.message.notification_category,
				},
				notification_text: push_notification_setting.message.body,
				handicap_index: handicap_index.value,
				golfer_age: golfer_age.value,
				number_of_scores: number_of_scores.value,
				date: date,
				time:
					time !== "0:00"
						? { label: time, value: time }
						: { label: "1:00", value: "1:00" },
				day_time: day_time
					? { label: day_time, value: day_time }
					: { label: "AM", value: "AM" },
				external_link: external_link,
				image: push_notification_setting.message.image.url,
				gender: gender,
				golfer_status: golfer_status,
				membership_types: membership_types,
				associations: selectedAssoc,
				association_groups: selectedAssocGroups,
				image_url: image_url,
			},
		});

		const initial_values = {
			handicap_index: handicap_index,
			golfer_age: golfer_age,
			number_of_scores: number_of_scores,
			image: push_notification_setting.message.image.url,
			now: !push_notification_setting.schedule.later,
			date: date,
			time:
				time !== "0:00"
					? { value: time, label: time }
					: { value: "1:00", label: "1:00" },
			day_time: day_time
				? { label: day_time, value: day_time }
				: { label: "AM", value: "AM" },
			scheduled: scheduled,
		};

		this.setState({
			count: push_notification_setting.message.body.length,
			btn1_blue: button1,
			btn2_blue: button2,
			btn3_blue: button3,
			btn4_blue: button4,
			btn5_blue: button5,
			btn6_blue: button6,
			btn7_blue: button7,
			btn8_blue: button8,
			btn9_blue: button9,
			now: !push_notification_setting.schedule.later,
			time:
				time !== "0:00"
					? { label: time, value: time }
					: { label: "1:00", value: "1:00" },
			day_time: day_time
				? { label: day_time, value: day_time }
				: { label: "AM", value: "AM" },
			scheduled: scheduled,
			image: push_notification_setting.message.image.url,
			image_url: image_url,
			initial_values: initial_values,
			curret_values: initial_values,
			is_deep_link: push_notification_setting.message.is_deep_link
		});

		this.props.onRef(this);

		this.loadAssociations();
		this.loadAssociationGroups();
		this.loadMobileDeepLinks();
	}

	loadMobileDeepLinks() {
		SharedService.getMobileDeepLinks()
		.then(res=>{
				this.setState({
						mobile_deep_links: res['mobile_deep_links']
				})
				if (this.props.push_notification_setting.message.is_deep_link) {
					const url = this.props.push_notification_setting.message.external_link;
					const selected_link = this.state.mobile_deep_links.find(function(obj) {
							return obj.value == url;
					})
					this.props.change('deep_link', selected_link);
					this.props.change('external_link', "");
				}
		})
		.catch(err => {
				console.error(err);
		})
	}

	get_sign(type) {
		let sign = "=";
		if (type === "handicap_index") {
			if (this.state.btn1_blue) {
				sign = "<";
				if (this.state.btn2_blue) sign = "<=";
			}
			if (this.state.btn3_blue) {
				sign = ">";
				if (this.state.btn2_blue) sign = ">=";
			}
			return sign;
		}

		if (type === "golfer_age") {
			if (this.state.btn4_blue) {
				sign = "<";
				if (this.state.btn5_blue) sign = "<=";
			}
			if (this.state.btn6_blue) {
				sign = ">";
				if (this.state.btn5_blue) sign = ">=";
			}
			return sign;
		}

		if (type === "number_of_scores") {
			if (this.state.btn7_blue) {
				sign = "<";
				if (this.state.btn8_blue) sign = "<=";
			}
			if (this.state.btn9_blue) {
				sign = ">";
				if (this.state.btn8_blue) sign = ">=";
			}
			return sign;
		}
	}

	get_sign_by_value(lower, equal, greater) {
		let sign = "=";
		if (lower) {
			sign = "<";
			if (equal) sign = "<=";
		}
		if (greater) {
			sign = ">";
			if (equal) sign = ">=";
		}
		return sign;
	}

	onSubmit(values, dispatch, props) {
		let data = JSON.parse(JSON.stringify(values));

		data.membership_types = data.membership_types.map((membership_type) => {
			return membership_type.value;
		});

		data.gender = data.gender.map((gender) => {
			return gender.value;
		});

		data.golfer_status = data.golfer_status.map((golfer_status) => {
			return golfer_status.value;
		});

		data.associations = data.associations.map((association) => {
			return association.value;
		});

		data.association_groups = data.association_groups.map(
			(association_group) => {
				return association_group.value;
			}
		);

		if (data.date) {
			let time =
				this.state.day_time.value === "PM"
					? parseInt(this.state.time.value) + 12 + ":00"
					: this.state.time.value;
			data.scheduled_at =
				moment(data.date).format("YYYY-MM-DD") + " " + time;
		}

		if (data.golfer_age) {
			data.age = {
				sign: this.get_sign("golfer_age"),
				value: data.golfer_age,
			};
		}

		if (data.handicap_index) {
			data.handicap_index = {
				sign: this.get_sign("handicap_index"),
				value: data.handicap_index,
			};
		}

		if (data.number_of_scores) {
			data.number_of_scores = {
				sign: this.get_sign("number_of_scores"),
				value: data.number_of_scores,
			};
		}
		
		data.is_deep_link = this.state.is_deep_link

		data.notification_category = data.notification_category.value;
		data.later = !this.state.now;
		if (this.state.scheduled === true) data.status = "scheduled";
		else data.status = "draft";

		if (this.state.delete_image === true && !this.state.image)
			data.delete_image = true;
		if (this.state.image && data.image !== this.state.image)
			data.image = this.state.image;
		else delete data.image;

		data.notes = "";

		if (data.handicap_index === "") delete data.handicap_index;
		if (data.golfer_age === "") delete data.golfer_age;
		if (data.number_of_scores === "") delete data.number_of_scores;

		return FederationAccount.editPushNotification(
			this.props.associationId,
			this.props.push_notification_setting.id,
			data
		)
			.then((res) => {
				if (res.push_notification_settings) {
					this.props.close();
					this.props.setTableRefresh(true);
					this.props.addAlert({
						type: "success",
						message:
							"Push Notification has been successfully updated !",
					});
					console.log("PushNotificationSetting edited.");
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}

	changeColor() {
		this.setState({ blue: !this.state.blue });
	}

	loadAssociations() {
		FederationAccount.getAssociations()
			.then((res) => {
				let associations = res.associations;
				associations = associations.filter(
					(association) => association.status === "Active"
				);
				associations.map((association) => {
					association.value = association.id;
					association.label = association.name;
					return association;
				});
				this.setState({
					all_associations: associations.sort((a, b) =>
						a["label"]
							?.toString()
							.localeCompare(b["label"]?.toString())
					),
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}

	loadAssociationGroups() {
		GolfAssociationGroup.list()
			.then((response) => {
				let assocGroups = response.golf_association_groups
					.map((assoc) => {
						assoc.value = assoc.id;
						assoc.label = assoc.name;
						return assoc;
					})
					.sort((a, b) =>
						a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
					);
				this.setState({
					assocGroups,
				});
			})
			.catch((err) => {});
	}

	componentDidUpdate() {
		if (
			JSON.stringify(this.state.curret_values) !==
			JSON.stringify(this.state.initial_values)
		) {
			if (this.state.pristine === true)
				this.setState({ pristine: false });
		} else if (this.state.pristine === false)
			this.setState({ pristine: true });
	}

	render() {
		const { handleSubmit, submitting, pristine, invalid } = this.props;
		const noteMaxLength = 100;

		const btn1_class = this.state.btn1_blue
			? "btn fill round blue pd-l-13px"
			: "btn fill round gray pd-l-13px";
		const btn2_class = this.state.btn2_blue
			? "btn fill round blue"
			: "btn fill round gray";
		const btn3_class = this.state.btn3_blue
			? "btn fill round blue pd-r-13px"
			: "btn fill round gray pd-r-13px";
		const btn4_class = this.state.btn4_blue
			? "btn fill round blue pd-l-13px"
			: "btn fill round gray pd-l-13px";
		const btn5_class = this.state.btn5_blue
			? "btn fill round blue "
			: "btn fill round gray";
		const btn6_class = this.state.btn6_blue
			? "btn fill round blue pd-r-13px"
			: "btn fill round gray pd-r-13px";
		const btn7_class = this.state.btn7_blue
			? "btn fill round blue pd-l-13px"
			: "btn fill round gray pd-l-13px";
		const btn8_class = this.state.btn8_blue
			? "btn fill round blue"
			: "btn fill round gray";
		const btn9_class = this.state.btn9_blue
			? "btn fill round blue pd-r-13px"
			: "btn fill round gray pd-r-13px";

		return (
			<Fragment>
				<form
					autoComplete="off"
					onSubmit={handleSubmit(this.onSubmit)}
					ref={"form"}
				>
					<div className="row">
						<div className="col">
							<p className="pn_blue pn_small pn_bold">
								Who are you sending this to?
							</p>
						</div>
					</div>
					{/* First row */}
					<div className="row">
						{/* First Column */}
						<div className="col is-1-of-3">
							<Field
								name="membership_types"
								id="membership_types"
								validate={[required]}
								component={PickySelect}
								options={membershipTypes}
								numberDisplayed={2}
								multiple={true}
								includeSelectAll={true}
								label={"Membership Type"}
							/>
						</div>
						{/* Second Column */}
						<div className="col is-1-of-3">
							<Field
								name="gender"
								id="gender"
								validate={[required]}
								component={PickySelect}
								options={genderOptions}
								numberDisplayed={2}
								multiple={true}
								includeSelectAll={true}
								label={"Gender"}
							/>
						</div>
						{/* Third Column */}
						<div className="col is-1-of-3">
							<Field
								name="golfer_status"
								id="golfer_status"
								validate={[required]}
								component={PickySelect}
								options={statusOptions}
								numberDisplayed={2}
								multiple={true}
								includeSelectAll={true}
								label={"Golfer Status"}
							/>
						</div>
					</div>

					{/* Second Row */}
					<div className="row">
						{/* First Column */}
						<div className="col is-1-of-3">
							<Field
								name="associations"
								id="associations"
								validate={[required]}
								component={PickySelect}
								options={this.state.all_associations}
								numberDisplayed={2}
								multiple={true}
								includeSelectAll={true}
								label={"Associations"}
							/>
						</div>

						{/* Second Column */}
						<div className="col is-1-of-3">
							<Field
								name="association_groups"
								id="association_groups"
								validate={[required]}
								component={PickySelect}
								options={this.state.assocGroups}
								numberDisplayed={2}
								multiple={true}
								includeSelectAll={true}
								label={"Select Association Group(s)"}
							/>
						</div>
					</div>

					{/* First Category */}
					<div className="row centered-items">
						{/* Name */}
						<div className="row align-right height-28-width-160">
							<div className="col is-4-of-4 text-right">
								Handicap Index
							</div>
						</div>
						{/* Icons column */}
						<div className="col is-1-of-4">
							<div className="row jc-sa">
								<button
									className={btn1_class}
									id={"btn1_blue"}
									type={"button"}
									onClick={() => {
										if (!this.state.btn3_blue)
											this.setState({
												btn1_blue: !this.state
													.btn1_blue,
												curret_values: {
													...this.state.curret_values,
													handicap_index: {
														sign: this.get_sign_by_value(
															!this.state
																.btn1_blue,
															this.state
																.btn2_blue,
															this.state.btn3_blue
														),
														value: this.state
															.initial_values
															.handicap_index
															.value,
													},
												},
											});
									}}
								>
									{"<"}
								</button>
								<button
									className={btn2_class}
									type={"button"}
									onClick={() => {
										this.setState({
											btn2_blue: !this.state.btn2_blue,
											curret_values: {
												...this.state.curret_values,
												handicap_index: {
													sign: this.get_sign_by_value(
														this.state.btn1_blue,
														!this.state.btn2_blue,
														this.state.btn3_blue
													),
													value: this.state
														.initial_values
														.handicap_index.value,
												},
											},
										});
									}}
								>
									{"="}
								</button>
								<button
									className={btn3_class}
									type={"button"}
									onClick={() => {
										if (!this.state.btn1_blue)
											this.setState({
												btn3_blue: !this.state
													.btn3_blue,
												curret_values: {
													...this.state.curret_values,
													handicap_index: {
														sign: this.get_sign_by_value(
															this.state
																.btn1_blue,
															this.state
																.btn2_blue,
															!this.state
																.btn3_blue
														),
														value: this.state
															.initial_values
															.handicap_index
															.value,
													},
												},
											});
									}}
								>
									{">"}
								</button>
							</div>
						</div>
						{/* Input */}
						<div className="col is-1-of-4">
							<Field
								name="handicap_index"
								id="handicap_index"
								component={InputField}
								type="text"
								onChange={(values) => {
									const value = values.currentTarget.value;
									this.setState({
										curret_values: {
											...this.state.curret_values,
											handicap_index: {
												sign: this.get_sign(
													"handicap_index"
												),
												value: value,
											},
										},
									});
								}}
								validate={[
									handicapIndex,
									isNumeric,
									checkSignIsSelected([
										this.state.btn1_blue ||
											this.state.btn2_blue ||
											this.state.btn3_blue,
									]),
								]}
							/>
						</div>
					</div>

					{/* Second Category */}
					<div className="row centered-items">
						{/* Name column */}
						<div className="row align-right height-28-width-160">
							<div className="col is-4-of-4 text-right">
								Golfer Age
							</div>
						</div>
						{/* Icons column */}
						<div className="col is-1-of-4">
							<div className="row jc-sa">
								<button
									className={btn4_class}
									type={"button"}
									onClick={() => {
										if (!this.state.btn6_blue)
											this.setState({
												btn4_blue: !this.state
													.btn4_blue,
												curret_values: {
													...this.state.curret_values,
													golfer_age: {
														sign: this.get_sign_by_value(
															!this.state
																.btn4_blue,
															this.state
																.btn5_blue,
															this.state.btn6_blue
														),
														value: this.state
															.initial_values
															.golfer_age.value,
													},
												},
											});
									}}
								>
									{"<"}
								</button>
								<button
									className={btn5_class}
									type={"button"}
									onClick={() => {
										this.setState({
											btn5_blue: !this.state.btn5_blue,
											curret_values: {
												...this.state.curret_values,
												golfer_age: {
													sign: this.get_sign_by_value(
														this.state.btn4_blue,
														!this.state.btn5_blue,
														this.state.btn6_blue
													),
													value: this.state
														.initial_values
														.golfer_age.value,
												},
											},
										});
									}}
								>
									{"="}
								</button>
								<button
									className={btn6_class}
									type={"button"}
									onClick={() => {
										if (!this.state.btn4_blue)
											this.setState({
												btn6_blue: !this.state
													.btn6_blue,
												curret_values: {
													...this.state.curret_values,
													golfer_age: {
														sign: this.get_sign_by_value(
															this.state
																.btn4_blue,
															this.state
																.btn5_blue,
															!this.state
																.btn6_blue
														),
														value: this.state
															.initial_values
															.golfer_age.value,
													},
												},
											});
									}}
								>
									{">"}
								</button>
							</div>
						</div>
						{/* Input */}
						<div className="col is-1-of-4">
							<Field
								name="golfer_age"
								id="golfer_age"
								component={InputField}
								type="text"
								onChange={(values) => {
									const value = values.currentTarget.value;
									this.setState({
										curret_values: {
											...this.state.curret_values,
											golfer_age: {
												sign: this.get_sign(
													"golfer_age"
												),
												value: value,
											},
										},
									});
								}}
								validate={[
									isNumeric,
									golfer_age,
									checkSignIsSelected([
										this.state.btn4_blue ||
											this.state.btn5_blue ||
											this.state.btn6_blue,
									]),
								]}
							/>
						</div>
					</div>

					{/* Third category */}
					<div className="row centered-items">
						<div
							className="row height-28-width-160"
							style={{ marginTop: "6px" }}
						>
							<div className="col is-4-of-4 text-right">
								Number Of Scores (Current Year)
							</div>
						</div>
						{/* Icons column */}
						<div className="col is-1-of-4">
							<div className="row jc-sa">
								<button
									className={btn7_class}
									type={"button"}
									onClick={() => {
										if (!this.state.btn9_blue)
											this.setState({
												btn7_blue: !this.state
													.btn7_blue,
												curret_values: {
													...this.state.curret_values,
													number_of_scores: {
														sign: this.get_sign_by_value(
															!this.state
																.btn7_blue,
															this.state
																.btn8_blue,
															this.state.btn9_blue
														),
														value: this.state
															.initial_values
															.number_of_scores
															.value,
													},
												},
											});
									}}
								>
									{"<"}
								</button>
								<button
									className={btn8_class}
									type={"button"}
									onClick={() => {
										this.setState({
											btn8_blue: !this.state.btn8_blue,
											curret_values: {
												...this.state.curret_values,
												number_of_scores: {
													sign: this.get_sign_by_value(
														this.state.btn7_blue,
														!this.state.btn8_blue,
														this.state.btn9_blue
													),
													value: this.state
														.initial_values
														.number_of_scores.value,
												},
											},
										});
									}}
								>
									{"="}
								</button>
								<button
									className={btn9_class}
									type={"button"}
									onClick={() => {
										if (!this.state.btn7_blue)
											this.setState({
												btn9_blue: !this.state
													.btn9_blue,
												curret_values: {
													...this.state.curret_values,
													number_of_scores: {
														sign: this.get_sign_by_value(
															this.state
																.btn7_blue,
															this.state
																.btn8_blue,
															!this.state
																.btn9_blue
														),
														value: this.state
															.initial_values
															.number_of_scores
															.value,
													},
												},
											});
									}}
								>
									{">"}
								</button>
							</div>
						</div>
						{/* Input */}
						<div className="col is-1-of-4">
							<Field
								name="number_of_scores"
								id="number_of_scores"
								component={InputField}
								type="text"
								onChange={(values) => {
									const value = values.currentTarget.value;

									this.setState({
										curret_values: {
											...this.state.curret_values,
											number_of_scores: {
												sign: this.get_sign(
													"number_of_scores"
												),
												value: value,
											},
										},
									});
								}}
								validate={[
									isNumeric,
									number_of_scores,
									checkSignIsSelected([
										this.state.btn7_blue ||
											this.state.btn8_blue ||
											this.state.btn9_blue,
									]),
								]}
							/>
						</div>
					</div>

					{/* Third Row */}
					<div className="row">
						<div className="col">
							<p className="pn_blue pn_small pn_bold">
								What is the message?
							</p>
						</div>
					</div>
					{/* Fourth Row */}
					<div className="row">
						{/* First Column */}
						<div className="col is-1-of-2">
							<label htmlFor="notification_category">
								Notification Category <span>*</span>
							</label>
							<Field
								name="notification_category"
								id="notification_category"
								component={SelectInput}
								className="react-select-container"
								classNamePrefix="react-select"
								validate={[required]}
								options={notification_category}
							/>
						</div>
					</div>

					{/* Sixth Row */}
					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="notification_text">
								Notification Text <span>*</span>{" "}
							</label>
						</div>
						<div
							className="col is-2-of-2"
							style={{ textAlign: "right" }}
						>
							<label
								className={
									this.state.count > noteMaxLength
										? "length-error"
										: "blue"
								}
								htmlFor="notification_text"
							>
								{this.state.count} / {noteMaxLength}
							</label>
						</div>
					</div>
					<div className="f-row">
						<Field
							component={Textarea}
							className={"large"}
							validate={[
								required,
								maxLength([
									100,
									"Value too long (max 100 characters)",
								]),
							]}
							name="notification_text"
							id="notification_text"
							type="text"
							onChange={(e) => {
								this.setState({
									count: e.target.value.length,
								});
							}}
						/>
					</div>

					<div className="row">
						{/* First col */}
						<div className="col is-1-of-1">
							<p className="pn_blue pn_small pn_bold">
								Deep link?
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col is-1-of-2">
							<div className="radio__container">
								<Field
									onClick={() => {
										this.setState((prevState) => {
											return {
												is_deep_link: !prevState.is_deep_link,
											};
										});
									}}
									checked={this.state.is_deep_link}
									id="deep_link_yes"
									value={true}
									name={"is_deep_link"}
									component={"input"}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="deep_link_yes">Yes</label>

								<Field
									onClick={() => {
										this.setState((prevState) => {
											return {
												is_deep_link: !prevState.is_deep_link,
											};
										});
									}}
									checked={!this.state.is_deep_link}
									id="deep_link_no"
									value={false}
									name={"is_deep_link"}
									component={"input"}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="deep_link_no">No</label>
							</div>
						</div>
					</div>

					{/* Seventh Row */}
					{!this.state.is_deep_link && <div className="row">
						<div className="col">
							<label htmlFor="external_link">External Link</label>
							<Field
								component={InputField}
								type="text"
								validate={[isUrl]}
								name="external_link"
								id="external_link"
							/>
						</div>
					</div>}

					{this.state.is_deep_link && <div className="row">
						{/* First Column */}
						<div className="col is-1-of-2">
							<label htmlFor="notification_category">
								Deep link destination: <span>*</span>
							</label>
							<Field
								name="deep_link"
								id="deep_link"
								validate={[required]}
								component={SelectInput}
								className="react-select-container"
								classNamePrefix="react-select"
								options={this.state.mobile_deep_links}
							/>
						</div>
					</div>}

					{/* Eigth Row */}
					<div className="row">
						{/* First col */}
						<div className="col is-1-of-1">
							<label htmlFor="upload_image"> Upload Image</label>
							<div
								className="panel__body"
								style={{
									border: "1px solid lightgrey",
									padding: "15px",
								}}
							>
								{!this.state.image ? (
									<UploadPushNotificationImage
										requiredWidth={720}
										requiredHeight={360}
										id={"external_link"}
										errors={this.state.error}
										onSave={(data) => {
											this.setState({
												image: data,
												curret_values: {
													...this.state.curret_values,
													image: data,
												},
											});
										}}
									/>
								) : (
									<div
										className="upload__item"
										style={{ marginRight: "10px" }}
									>
										<Fragment>
											<div className="upload__details pn__details">
												<div className="row">
													<div className="col is-1-of-2">
														<figure
															className="large"
															style={{
																width: "100%",
															}}
														>
															{this.state
																.image_url ? (
																<img
																	src={
																		this
																			.state
																			.image
																	}
																	style={{
																		width:
																			"100%",
																	}}
																	alt=""
																/>
															) : (
																<img
																	src={URL.createObjectURL(
																		this
																			.state
																			.image
																	)}
																	style={{
																		width:
																			"100%",
																	}}
																	alt=""
																/>
															)}
														</figure>
													</div>
													{this.state.image_url && (
														<div className="col is-1-of-2 jc-fs">
															<div className="upload_item">
																<h6 className="upload__label">
																	Image Url:
																</h6>
																<a
																	className="upload__url"
																	href={
																		this
																			.state
																			.image
																	}
																>
																	{
																		this
																			.state
																			.image
																	}
																</a>
															</div>
														</div>
													)}
												</div>
											</div>
											<div className="upload__controls list">
												<button
													className="btn fill gray"
													onClick={() => {
														this.setState({
															delete_image: true,
															image: null,
															image_url: null,
															curret_values: {
																...this.state
																	.curret_values,
																image: null,
															},
														});
													}}
												>
													<i className="material-icons-outlined no-margin">
														clear
													</i>
												</button>
											</div>
										</Fragment>
									</div>
								)}
							</div>
						</div>
					</div>

					{/* Nineth Row */}
					<div className="row">
						{/* First col */}
						<div className="col is-1-of-1">
							<p className="pn_blue pn_small pn_bold">
								When should it be sent?
							</p>
						</div>
					</div>

					{/* Tenth Row */}
					<div className="row">
						<div className="col is-1-of-2">
							<div className="radio__container">
								<Field
									onClick={() => {
										this.setState((prevState) => {
											return {
												now: !prevState.now,
												curret_values: {
													...this.state.curret_values,
													now: !prevState.now,
												},
											};
										});
									}}
									checked={this.state.now}
									id="dac-yes"
									value={true}
									name={"now"}
									component={"input"}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="dac-yes">Now</label>

								<Field
									onClick={() => {
										this.setState((prevState) => {
											return {
												now: !prevState.now,
												curret_values: {
													...this.state.curret_values,
													now: !prevState.now,
												},
											};
										});
									}}
									checked={!this.state.now}
									id="dac-no"
									value={false}
									name={"later"}
									component={"input"}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="dac-no">Later</label>
							</div>
						</div>
					</div>
					{this.state.now ? (
						<div className="row">
							<div className="col is-1-of-1">
								<p className="pn_small pn_red">
									The notification will be sent immediately.
								</p>
							</div>
						</div>
					) : (
						<div className="row">
							<div className="col is-1-of-1">
								<p className="pn_small pn_red">
									The notification will be sent as scheduled.
								</p>
							</div>
						</div>
					)}
					{/* Eleventh Row */}
					{!this.state.now && (
						<div className="row">
							{/* First col */}
							<div className="col is-1-of-2">
								<label htmlFor="date">Date</label>
								<Field
									minDate={new Date()}
									className={"date"}
									showMonthDropdown
									showYearDropdown
									name={"date"}
									id={"date"}
									validate={[required]}
									component={DatePickerInput}
									placeholder="Select ... "
									onChange={(value) => {
										this.setState({
											curret_values: {
												...this.state.curret_values,
												date: value,
											},
										});
									}}
								/>
							</div>
							{/* Second col */}
							<div className="col is-1-of-4">
								<GhinFilterSelect
									singleChoice={true}
									hideSelectAllOption={true}
									validate={[required]}
									component={SelectInput}
									className="react-select-container"
									classNamePrefix="react-select"
									options={time_array}
									label={"Time"}
									name={"time"}
									id={"time"}
									onChange={(values) => {
										this.setState({
											time: values,
											curret_values: {
												...this.state.curret_values,
												time: values,
											},
										});
									}}
									value={this.state.time}
								/>
							</div>
							{/* Third col */}
							<div className="col is-1-of-4">
								<div className="row"></div>
								<GhinFilterSelect
									singleChoice={true}
									hideSelectAllOption={true}
									name="day_time"
									id="day_time"
									validate={[required]}
									component={SelectInput}
									className="react-select-container"
									classNamePrefix="react-select"
									options={["AM", "PM"]}
									onChange={(values) => {
										this.setState({
											day_time: values,
											curret_values: {
												...this.state.curret_values,
												day_time: values,
											},
										});
									}}
									value={this.state.day_time}
								/>
							</div>
						</div>
					)}
					{/* Twelfth Row */}
					<div className="row">
						{/* First col */}
						<div className="col is-1-of-1">
							<p className="pn_blue pn_small pn_bold">
								Would you like to save it as a Draft?
							</p>
						</div>
					</div>
					{/* Thirteenth Row */}
					<div className="row">
						<div className="col is-1-of-2">
							<div className="radio__container">
								<Field
									onClick={() => {
										this.setState((prevState) => {
											return {
												scheduled: !prevState.scheduled,
											};
										});
									}}
									checked={!this.state.scheduled}
									id="scheduled_yes"
									value={true}
									name={"scheduled"}
									component={"input"}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="scheduled_yes">Yes</label>

								<Field
									onClick={() => {
										this.setState((prevState) => {
											return {
												scheduled: !prevState.scheduled,
											};
										});
									}}
									checked={this.state.scheduled}
									id="scheduled_no"
									value={false}
									name={"scheduled"}
									component={"input"}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="scheduled_no">No</label>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<button
								type={"button"}
								className="btn fill gray"
								onClick={() => this.props.close()}
							>
								Cancel
							</button>
						</div>
						<div className="col is-1-of-2">
							<button
								type={"submit"}
								ref={"submit"}
								disabled={
									submitting ||
									(pristine && this.state.pristine) ||
									invalid
								}
								className="btn fill blue"
							>
								{this.state.scheduled
									? "Save"
									: "Save as Draft"}
							</button>
						</div>
					</div>
				</form>
			</Fragment>
		);
	}
}

EditPushNotificationForFederationForm = reduxForm({
	form: "editPushNotificationForm",
	destroyOnUnmount: true,
})(EditPushNotificationForFederationForm);

function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
		formValues: state.form.editPushNotificationForm,
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ addAlert }, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withKeyPress(EditPushNotificationForFederationForm));
