import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';

import AddUserModal from '../../../Modals/AddUserModal';
import GhinTable from "../../../shared/GhinTable";
import UsersService from "../../../../services/api/users";
import {withRouter} from "react-router-dom";
import EditUserModal from "../../../Modals/EditUserModal";
import moment from "moment";
import SuccessModal from "../../../Modals/SuccessModal";
import DeleteUserAccessModal from '../../../Modals/DeleteUserAccessModal';
import { store } from '../../../../store';
import UserCreatePasswordLinkModal from '../../../Modals/UserCreatePasswordLinkModal';
import {formatPhoneNumber} from "../../../utils";

class ManageUsersClub extends Component {

    constructor(props) {

		super(props);

    let user_id = store.getState().user.user_id
		this.state = {
			addUserModalIsOpen: false,
      GenerateCreatePasswordModalIsOpen: false,
			reinvitedSuccess: false,
			loading:true,
			users: [],
      user_id: user_id
		};

		this.openAddUserModal = this.openAddUserModal.bind(this);

	}

	getUsers() {
		if(this.props.match.params.id) {
			UsersService.get(this.props.match.params.id, null, null)
				.then(data => {
					this.setState({
						loading: false,
						users: data.users.map((i) => {
							return {
								...{user:i},
								Id: i.user.id,
								Name: (
									i.user.last_name +
									(i.user.suffix ? ' ' + i.user.suffix : '') +
									(i.user.prefix ?  ', ' + i.user.prefix : '') +
									', ' + i.user.first_name +
									(i.user.middle_name ? ', ' + i.user.middle_name : '' )
								),
								Role: (i.user_access.role === "admin" ? "Administrator" : '') + (i.user_access.role === "read_only" ? "Admin-Read-Only" : '') + (i.user_access.role === "score_only" ? "Admin-Rapid-Score-Entry-Only" : '') + (i.user_access.role === "score_maintenance" ? "Admin-Score Posting & Maintenance" : ''),
								Email: i.user.email,
								Phone: formatPhoneNumber(i.user.phone),
								Status: i.user_access.active === 'true' ? 'Active' : 'Inactive',
								lastLoginDate: i.user.last_sign_in_at,
								userAccessId: i.user_access.id
							}
						})

					});
				})
				.catch(err => {
					console.error(err)
				});
		}
	}

	componentDidMount() {
		this.getUsers()
	}

    openAddUserModal() {
		this.setState({addUserModalIsOpen: true});
	}

	closeModal() {
		this.setState({addUserModalIsOpen: false});
	}

	inviteUser(user) {
		UsersService.reinvite(user.userAccessId, window.location.origin + '/auth/set/')
			.then(res => {
				if (res.user === "Sent") {
					this.setState({reinvitedSuccess: user.Name})
				}
			})
			.catch(err => {
				console.log(err);
			})
	}

  generateCreatePasswordLink(user_id) {
    UsersService.generateCreatePasswordLink(user_id)
      .then((response) => {
        const generated_url = response.user.generated_url;
        this.setState({
          generatedUrl: generated_url,
          GenerateCreatePasswordModalIsOpen: true
        });
      })
      .catch((error) => {
        const errors = error.data.errors
        console.error(errors[Object.keys(errors)[0]][0]);
        this.props.addAlert({ type: 'error', message: errors[Object.keys(errors)[0]][0] })
      });
  }

	render() {
    var user_id = this.state.user_id
		let columns = [
			{
				Header: 'User Name',
				accessor: 'Name',
				colClassName: 'col_golfer-name-short'
			},
			{
				Header: 'Role',
				accessor: 'Role',
				colClassName: 'col_golfer-name-short'
			},
			{
				Header: 'Email Address',
				accessor: 'Email',
			//	colClassName: 'col_email-address'
			},
			{
				Header: 'Phone Number',
				accessor: 'Phone',
				colClassName: 'col_city'
			},
			{
				Header: 'Status',
				accessor: 'Status',
				colClassName: 'col_status'
			},
			{
				Header: 'Last Login Date',
				accessor: 'lastLoginDate',
				colClassName: 'col_date',
				Cell: props => {
					if( props.original.lastLoginDate ) {
						return moment(props.original.lastLoginDate).format('MM/DD/YYYY')
					} else {
						return(<button className="btn small fill green" onClick={() => this.inviteUser(props.original)}>Resend Invite</button>)
					}
				},
			},
			{
				Header: '',
				accessor: 'Edit',
				colClassName: 'col_edit',
				show: this.props.canEditClub,
				Cell: props => <button className="btn lnk" onClick={() => this.setState({editUserModalIsOpen: true, selectedUser: props.row._original.user})}>Edit</button>,
			},
      {
        Header: '',
        accessor: 'Delete',
        colClassName: 'col_remove',
        show: this.props.canEditAssociation,
        Cell: props => user_id !== props.original.user.user.id && <button className="btn lnk" onClick={() => this.setState({ deleteUserAccessModalIsOpen: true, selectedUser: props.row._original.user })}>Delete</button>,
      },
      {
        Header: '',
        accessor: 'Generate_Link',
        colClassName: 'col_date',
        show: this.props.canEditClub,
        Cell: props => user_id !== props.original.user.user.id && <button className="btn lnk" onClick={() => this.generateCreatePasswordLink(props.row._original.user.user_access.id)}>Generate Link</button>,
      },

		];

		return (
            <Fragment>

                <div className="columns">

                    <div className="row">

                        <div className="col">

                            <div className="panel">

                                <div className="panel__head">Manage Users</div>
                                <div className="panel__body">
                                   {this.props.canEditClub && <div className="row">
										                   <div className="col is-1-of-5">
                                            <button onClick={this.openAddUserModal} disabled={this.props.club.status !== "Active"} className="btn fill green">Add User</button>
                                        </div>
                                    </div>}

									<GhinTable
										idKey={'Id'}
										columns={columns}
										loading={this.state.loading}
										hideSelect={true}
										data={this.state.users}
										ref={(r) => this.table = r}
										defaultSorted={[{
											id: 'Name',
											desc: false
										}]}
										sortable={true}
									/>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <Modal
					isOpen={this.state.addUserModalIsOpen}
					onRequestClose={() => {
						this.closeModal()
					}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<AddUserModal clubId={this.props.match.params.id} associationId={this.props.match.params.association_id} usersList={this.state.users} closeModal={() => {this.getUsers(); this.closeModal()}}/>
				</Modal>

				<Modal
					isOpen={this.state.editUserModalIsOpen}
					onRequestClose={() => {
						this.getUsers();
						this.closeModal()
					}
					}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditUserModal user={this.state.selectedUser} closeModal={() => {this.getUsers(); this.setState({editUserModalIsOpen: false})}}/>
				</Modal>

        <Modal
            isOpen={this.state.deleteUserAccessModalIsOpen}
            onRequestClose={() => {
              this.getUsers();
              this.closeModal()
            }
            }
            contentLabel="Modal"
            portalClassName="modal"
            overlayClassName="modal__overlay"
            className="modal__content"
            bodyOpenClassName="modal--is-open"
            htmlOpenClassName="prevent-scroll"
            shouldCloseOnOverlayClick={true}
            shouldFocusAfterRender={false}
            >
            <DeleteUserAccessModal
            user={this.state.selectedUser}
            closeModal={() => { this.getUsers(); this.setState({ deleteUserAccessModalIsOpen: false }) }}
            deleteUserAccessModalIsOpen = {this.state.deleteUserAccessModalIsOpen}
             />
          </Modal>

				<SuccessModal
					openModal={this.state.reinvitedSuccess}
					onMainAction={() => {
						this.setState({reinvitedSuccess: false})
					}}
					closeModal={() => {
						this.setState({reinvitedSuccess: false})
					}}
					message={`${this.state.reinvitedSuccess} was successfully reinvited`}
					mainLabel={"Ok"}
				/>

        <Modal
          isOpen={this.state.GenerateCreatePasswordModalIsOpen}
          onRequestClose={() => { this.setState({ GenerateCreatePasswordModalIsOpen: false }) }}
          contentLabel="Modal"
          portalClassName="modal"
          overlayClassName="modal__overlay"
          className="modal__content"
          bodyOpenClassName="modal--is-open"
          htmlOpenClassName="prevent-scroll"
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={false}
        >
          <UserCreatePasswordLinkModal generatedUrl={this.state.generatedUrl} closeModal={() => { this.setState({ GenerateCreatePasswordModalIsOpen: false }) }} />
        </Modal>

            </Fragment>
		);
	}
}

export default withRouter(ManageUsersClub)
