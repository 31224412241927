import React, {Component, Fragment} from 'react';
import TableFilter from "./TableFilter";
import GhinTable from "../../shared/GhinTable";
import debounce from 'debounce-promise';
import UsersService from "../../../services/api/users";
import TableService from "../../../services/tableService";
import moment from 'moment';
import {formatPhoneNumber} from "../../utils";

const generateUserName = (row) => {
	return row.last_name + (row.suffix ? ' ' + row.suffix + ', ' : ', ') + (row.prefix ?  row.prefix + ' ' : '') + row.first_name + ' ' + (row.middle_name ? row.middle_name : '');
};

export default class AdminUserSearch extends  Component {

    constructor(props) {
        super(props);
				const query = decodeURI(this.props.location.search.substring(3))
        this.state = {
            tableFilterIsHidden: false,
            associations: [],
            filtered: [],
            sorted: [{id: "full_name", desc:false}],
            searchText: query || '',
            users: [],
            page: 0,
            per_page: 25
        };
    }

    componentDidMount() {
        this.loadUsersList();
    }

    getRequestParams() {

        let params = {
            page: (this.state.page) + 1,
            per_page: this.state.per_page,
            global_search: true
        };

        if (this.state.sorted.length > 0) {
            params['sorting_criteria'] = this.state.sorted[0].id;
            params['order'] = this.state.sorted[0].desc ? 'desc' : 'asc';
        }

        params = {
            ...params,
            ...TableService.filtersArrayToParams(this.state.filtered)
        }

        if (!params['search']) {
            params['search'] = this.state.searchText;
        }

        return params;
    }

    updateFilter(filtered) {
        this.setState({
            filtered,
            page: 0
        }, () => {
            this.loadUsersList();
        })

    }

    loadUsersList = debounce(() => {
			this.setState({
				loading: true,
				users: []
			});
			const params = this.getRequestParams();

			UsersService.admin_user_search(params).then((data) => {
				this.setState({
					loading: false,
					global_search: true,
					users: data.users.map((i) => {
						return {
							...i,
							...{full_name: generateUserName(i)},
							...{last_login_date: i.last_sign_in_at? moment(i.last_sign_in_at).format("MM/DD/YYYY") : ''},
							...{role: (i.role === "admin" ? "Administrator" : '') + (i.role === "read_only" ? "Admin-Read-Only" : '') + (i.role === "score_only" ? "Admin-Rapid-Score-Entry-Only" : '') + (i.role === "score_maintenance" ? "Admin-Score Posting & Maintenance" : '')},
							...{phone: formatPhoneNumber(i.phone)},
						}
					}),
					total: parseInt(data['total-count'], 10),
					per_page: parseInt(data['per-page'], 10),
					pages: parseInt(data['total-pages'], 10)
				})
			});
		}, 1000);

    render() {
        const columns = [
            {
							 Header: 'User Name',
						   accessor: 'full_name',
							 filterMethod: (filter, row) =>
							  row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1,
						   Cell: props =>
							 <Fragment>
									<p className="link">{props.row.full_name}</p>
							 </Fragment>
							,
            },
            {
                Header: 'Association Name',
                accessor: 'association_name',
                colClassName: 'col_club-name'
            },
            {
                Header: 'Club Name',
                accessor: 'club_name',
				        colClassName: 'col_club-name'
            },
            {
                Header: 'Role',
                accessor: 'role',
								sortable: false,
                colClassName: 'col_golfer-name-short'
            },
            {
                Header: 'Email Address',
                accessor: 'email',
								sortable: false,
								colClassName: 'col_email'
				   },
					 {
							 Header: <Fragment>Phone Number</Fragment>,
							 accessor: 'phone',
							 sortable: false,
							 colClassName: 'col_city'
					 },
	         {
	             Header: 'Status',
	             accessor: 'active',
	             colClassName: 'col_status',
							 sortable: false,
							Cell: props =>
								props.value === true ?
									<span>Active</span>
									:
									<span>Inactive</span>
	         },
	         {
              Header: 'Last Login Date',
              accessor: 'last_login_date',
							sortable: false,
							colClassName: 'col_phone-number'
          },
        ];

        return (
            <section className="default">

                <TableFilter updateFiltered={this.updateFilter.bind(this)} searchText={this.state.searchText}/>

                <h2 className="section__title large">Admin User Results ({this.state.total ? this.state.total : 0})</h2>

                <GhinTable
                    loading={this.state.loading}
                    columns={columns}
                    pages={this.state.pages}
										page={this.state.page}
										total={this.state.total}
                    manual
                    pageSize={this.state.per_page}
                    data={this.state.users}
                    ref={(r) => this.table = r}
                    onPageChange={(pageIndex) => this.setState({page: pageIndex}, () =>  this.loadUsersList())}
                    onPageSizeChange={(pageSize, pageIndex) => this.setState({
                        per_page: pageSize,
                        page: pageIndex
                    }, () => this.loadUsersList())}
                    sorted={this.state.sorted}
                    onSortedChange={(newSorted, column, shiftKey) => {
                        this.setState({
                            sorted: newSorted,
														page: 0
                        }, () => {
                            this.loadUsersList()
                        })
                    }}
                    hideSelect={true}
                />

            </section>
        )
    }
}
