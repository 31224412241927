import request2 from '../shared/request2';
import request from '../shared/request';

export default class GolferService {
	static getAllGolfers(clubId, params) {
		return request({
			url: `/clubs/${clubId}/golfers/get_all.json`,
			method: 'GET',
			params
		})
	}

	static getHandicapHistoryLastYear(ghinNumber) {
        return request({
            url: `/golfers/${ghinNumber}/handicap_history_last_year.json`,
        })
	}

	static getHandicapHistoryByDate(ghinNumber, startDate, endDate) {
        return request({
            url: `/golfers/${ghinNumber}/handicap_history_by_date.json`,
			params: {
				start_date: startDate,
				end_date: endDate
			}
        })
	}

	static findGolferByGHIN(ghinNumber) {
		return request2({
			url: `/golfermethods.asmx/FindGolfer.json?ghinNumber=${ghinNumber}&association=0&club=0`,
		})
	}

	static getGolferByGHIN(ghinNumber) {
		return request( {
				url: `/search_golfer.json?golfer_id=${ghinNumber}`
			}
		)
	}

	static getGolfer(type, value) {
		return request( {
				url: `/search_golfer.json?${type}=${value}`
			}
		)
	}

	static addGolfer(data, clubId, forceAge = 'false'){
		return request({
			url: `/clubs/${clubId}/golfers.json`,
			method: 'POST',
			data: {
				...data,
				force_age: forceAge
			}
		})
	}

	static addExistingGolfer(data, clubId){
		return request({
			url: `/golfers/add_to_club.json`,
			method: 'POST',
			data: data
		})
	}

	static findGolferByGhinOrEmail(ghinNumber, email) {
		return request({
			url: `/search_golfer.json?${ghinNumber ? 'golfer_id=' + ghinNumber : ''}${email ? 'email=' + email : ''}`,
			method: 'GET'
		})
	}

	static findGolferByNameAndState(params) {
		return request({
			url: `/search_golfer.json?`,
			params,
			method: "GET"
		})
  }

	static getGolfers(params) {
		return request({
			url: `/golfers.json`,
			params
		})
	}

	static getGolferSubscriptions(golferId) {
		return request({
			url: `/golfers/${golferId}/subscriptions.json`,
			method: 'GET'
		})
	}

	static getGolferGpsSubscriptions(golferId) {
		return request({
			url: `/golfers/${golferId}/gps_subscriptions.json`,
			method: 'GET'
		})
	}

	static getGolferNotificationsPreferences(golferId) {
		return request({
			url: `/golfers/${golferId}/notification_preference.json`,
			method: 'GET'
		})
	}

	static resetFreeTrial(golferId) {
		return request({
			url: `/golfers/${golferId}/gps_free_trials.json`,
			method: 'Patch'
		})
	}

	static activateGolfers(clubId, data, date, params) {
		if(date) {
			data.from_date = date;
		}
		return request({
			url: `/clubs/${clubId}/golfers/activate.json`,
			method: "POST",
			params: {...params},
			data
		})
	}


	static inactivateGolfers(clubId, data, date, params) {
		if(date) {
			data.from_date = date;
		}

		return request({
			url: `/clubs/${clubId}/golfers/inactivate.json`,
			method: "POST",
			params: {...params},
	      	data
    	})
	}

  static updateDeceased(clubId, params) {
    return request({
      url: `/clubs/${clubId}/golfers/update_deceased.json`,
      method: "POST",
      params: params
    })
  }

	static createGolferGroup(clubId, data) {
		return request({
			url: `/clubs/${clubId}/golfer_groups.json`,
			method: 'POST',
			data
		})
	}

	static  deleteGolferGroup(clubId, groupId) {
		return request({
			url: `/clubs/${clubId}/golfer_groups/${groupId}.json`,
			method: 'DELETE'
		})
	}

	static updateGolferGroup(clubId, groupId, data) {
		return request({
			url: `/clubs/${clubId}/golfer_groups/${groupId}.json`,
			method: 'PATCH',
			data
		})
	}

	static getGroups(clubId, params = {}) {
		return request({
			url: `/clubs/${clubId}/golfer_groups.json`,
			method: 'GET',
			params: params
		})
	}


	static getScores(golfer_id) {
		return request({
			url: `/golfers/${golfer_id}/revision_scores.json`,
		})
	}

    static showGolfer(clubId, golfer_id) {
        return request({
            url: `/clubs/${clubId}/golfers/${golfer_id}.json`,
            method: 'GET'
        })
    }

	static getGolferMerges(ghin) {
		return request({
			url: `/golfers/${ghin}/merge.json`
		})
	}

	static unmergeGolfers(data) {
		return request({
			url: '/golfers/merge.json',
			method: "DELETE",
			data
		})
	}

	static mergeGolfers(data) {
		return request({
			url: '/golfers/merge.json',
			method: "POST",
			data
    })
	}

	static updateGolfer(clubId, golfer_id, data) {
		return request({
			url: `/clubs/${clubId}/golfers/${golfer_id}.json`,
			method: 'PATCH',
			data
		})
	}

	static deleteGolferAddress(clubId, golfer_id, type) {
		return request({
			url: `/clubs/${clubId}/golfers/${golfer_id}.json?delete=${type}`,
			method: 'PATCH'
		})
	}

	static getAffiliations(golfer_id) {
		return request({
			url: `/golfers/${golfer_id}/club_affiliations.json`,
			method: 'GET',
		})
	}

	static updateAffiliation(golfer_id, affiliation_id, data) {
		return request({
			url: `/golfers/${golfer_id}/club_affiliations/${affiliation_id}.json`,
			method: 'PATCH',
			data: {club_affiliation: data}
		})
	}

  static deleteAffiliation(golfer_id, affiliation_id) {
    return request({
      url: `/golfers/${golfer_id}/club_affiliations/${affiliation_id}.json`,
      method: 'DELETE'
    })
  }

	static specialUpdate(clubId, golferIds, select_all, params) {

		let data = {};
		if (select_all) {
			data.unchecked_items = golferIds;
			data.golfer_ids = [];
		} else {
			data.golfer_ids = golferIds;
		}

		return request({
			url: `/clubs/${clubId}/special_update.json`,
			method: 'POST',
			data,
			params
		});
	}
	static setMembershipType(clubId, membershipId, data, params) {
		return request({
			url: `/clubs/${clubId}/golfers/membership_types/${membershipId}.json`,
			method: 'POST',
			params: {...params},
			data
		})
	}

	static handicapModification(golferId, data) {
		return request({
			url: `/golfers/${golferId}/handicap_modifications.json`,
			method: 'POST',
			data: data
		});
	}

	static adjustScore(golferId, data) {
		return request({
			url: `/golfers/${golferId}/adjust_score_differentials.json`,
			method: 'PATCH',
			data: data
		});
	}

	static removeScoreDifferentials(golferId, score_id) {
		return request({
			url: `/golfers/${golferId}/adjust_score_differentials.json`,
			method: 'DELETE',
			params: {
				score_id
			}
		});
	}

	static removeHandicapModification(golferId, type) {
		return request({
			url: `/golfers/${golferId}/handicap_modifications.json`,
			method: 'DELETE',
			data: {type: type}
		});
	}

	static getLogs(golferId, params) {
		return request({
			url: `/golfers/${golferId}/logs.json`,
			method: 'GET',
			params
		});
	}

  static archiveGolfer(golferId, data) {
		return request({
			url: `/golfers/${golferId}/archive.json`,
      		method: 'POST',
      		data
		});
	}

	static getGuardians(golferId) {
		return request({
			url: `/golfers/${golferId}/guardians.json`,
			method: 'GET'
		});
	}

	static createGuardian(golferId, guardian) {
		///api/v1/golfers/{golfer_id}/guardians.
		return request({
			url: `/golfers/${golferId}/guardians.json`,
			method: 'POST',
			data: {
				guardian
			}
		});
	}

	static deleteGuardian(guardian_id,golfer_id) {
		return request({
			url: `/guardians/${guardian_id}/remove_minor.json`,
			method: 'DELETE',
			data: {
				golfer_id
			}
		});
	}

	static updateGuardian(golferId, guardianId, guardian) {
		return request({
			url: `/golfers/${golferId}/guardians/${guardianId}.json`,
			method: 'PATCH',
			data: {
				guardian
			}
		});
	}

	static getDigitalProfile(golferId) {
		return request({
			url: `/golfers/${golferId}/get_digital_profile_information.json`,
			method: 'GET'
		})
	}

	static sendInvitation(golferId, associationId) {
		return request({
			url: `/golfers/${golferId}/send_invitation_to_create_digital_profile.json?association_id=${associationId}`,
			method: 'POST',
			data: {
				source: 'admin'
			}
		})
	}

	static removeDigitalProfile(golferId) {
		return request({
			url: `/golfers/${golferId}/remove_digital_profile.json`,
			method: 'DELETE'
		})
	}

	static sendResetPassword(golferId, associationId) {
		return request({
			url: `forgot_login_information.json?email_or_ghin=${golferId}${associationId ? '&association_id=' + associationId : ''}`,
			method: 'POST',
			data: {
				source: "admin"
			}
		})
	}

	static generateInvitationLink(golferId) {
		return request({
			url: `/golfers/${golferId}/generate_set_password_url.json`,
			method: 'GET',
			params: {
				url_type: "profile_creation"
			}
		})
	}

	static generateResetPasswordLink(golferId) {
		return request({
			url: `/golfers/${golferId}/generate_set_password_url.json`,
			method: 'GET',
			params: {
				url_type: "reset_password"
			}
		})
	}

	static getDigitalProfileEmails(page, per_page, email) {
    return request({
      url: `/digital_profile_emails.json?page=${page}&per_page=${per_page}&email=${email}`,
			method: 'GET',
    })
  }

  static getContactSettings(golferId) {
    return request({
      url: `/golfers/${golferId}/decline_contact_settings.json`,
      method: 'GET'
    })
  }

  static postContactSettings(golferId, data) {
    return request({
      url: `/golfers/${golferId}/decline_contact_settings.json`,
      method: 'POST',
      data
    })
  }

  static deleteContactSettings(golferId, data) {
    return request({
      url: `/golfers/${golferId}/decline_contact_settings.json`,
      method: 'DELETE',
      data
    })
  }

  static getAllHandicapReviews(golferId, params)
  {
    return request({
      url: `/golfers/${golferId}/handicap_reviews.json`,
      method: 'GET',
      params
    })
  }

  static postNewHandicapReviews(golferId)
  {
    return request({
      url: `/golfers/${golferId}/handicap_reviews.json`,
      method: 'POST'
    })
  }

  static applyHandicapReview(golferId)
  {
    return request({
      url: `/golfers/${golferId}/handicap_review_recommendations.json`,
      method: 'POST'
    })
  }

  static denyHandicapReview(golferId)
  {
    return request({
      url: `/golfers/${golferId}/handicap_review_recommendations.json`,
      method: 'DELETE'
    })
  }
}
