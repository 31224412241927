import React, {Component} from 'react';
import {Field, reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {FederationAccount} from "../../services/api/federationAccount";
import {SelectInput} from "../../inputs/Select";
import {requiredMultiSelect} from "../../inputs/validations";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";

class EditAssociationForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
      assoc: this.props.assoc,
      all_facilities: this.props.assoc.all_facilities,
      facilities:[],
      excluded_facilities:[],
		}
	}

	componentDidMount() {
    this.props.initialize({
			...this.props.assoc,
			...{
				facilities: this.props.assoc.facilities.map(ga => {return {"value": ga[0], "label": ga[1]}}),
        excluded_facilities: this.props.assoc.excluded_facilities.map(ga => {return {"value": ga[0], "label": ga[1]}})
			}});
    this.getAllFacilities(this.props.assoc.crs_id)
		this.props.onRef(this);
	}

  getAllFacilities(crs_id) {
		FederationAccount.getFacilities(crs_id)
			.then(response => {
				let facilities = response.Facilities.map(ga => {return {"value": ga.FacilityId, "label": ga.FacilityName}});
        this.setState({
          facilities: facilities.sort((a, b) => a['label']?.toString().localeCompare(b['label']?.toString())),
          excluded_facilities: facilities.sort((a, b) => a['label']?.toString().localeCompare(b['label']?.toString()))
        })
			})
			.catch(err => {

			})
	}

	onSubmit(values, dispatch, props) {
		let data = JSON.parse(JSON.stringify(values));
		data.facilities = data.facilities.map(ga => {return ga.value});
    data.excluded_facilities = data.excluded_facilities.map(ga => {return ga.value});
		FederationAccount.updateSurveyAssociation(data, props.assoc.survey_association_id)
			.then(res => {
				props.addAlert({
					type:'success',
					message:'Association has been updated successfully'
				});
				props.close(res.survey);
			})
			.catch(err => {
				console.error(err)
			});
	}

	render() {
		const {handleSubmit, submitting} = this.props;
		return (
      <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
          <div className="row">
              <div className="col is-full">
                  <span className="label" htmlFor="all_facilities">All Facilities ? <span>*</span></span>
                  <div className="radio__container">
                      <Field
                          id="all_facilities-yes"
                          value={"true"}
                          onClick={
                              () => {
                                  this.setState((prevState) => {
                                      return {all_facilities: !prevState.all_facilities};
                                  });
                              }
                          }
                          name={'all_facilities'}
                          component={'input'}
                          checked={this.state.all_facilities}
                          className="simple__radio"
                          type="radio"
                      />
                      <label htmlFor="all_facilities-yes">Yes</label>

                      <Field
                          id="all_facilities-no"
                          value={"false"}
                          name={'all_facilities'}
                          onClick={
                              () => {
                                  this.setState((prevState) => {
                                      return {all_facilities: !prevState.all_facilities};
                                  });
                              }
                          }
                          checked={!this.state.all_facilities}
                          className="simple__radio"
                          component={'input'}
                          type="radio"
                      />
                      <label htmlFor="all_facilities-no">No</label>
                  </div>
              </div>
          </div>
          <div className={"row"}>
            <div className="col">
              {this.state.all_facilities === false ?
                <div className="row">
                  <div className="col">
                    <label htmlFor="facilities">Facilities <span>*</span></label>
                    <Field
                      validate={[requiredMultiSelect]}
                      name={'facilities'}
                      component={SelectInput}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={this.state.facilities}
                      isSearchable={false}
                      isMulti={true}
                    />
                  </div>
                </div>
                :
                <div className="row">
                  <div className="col">
                    <label htmlFor="excluded_facilities">Exclude </label>
                    <Field
                      name={'excluded_facilities'}
                      component={SelectInput}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={this.state.excluded_facilities}
                      isSearchable={false}
                      isMulti={true}
                    />
                  </div>
                </div>}
            </div>
          </div>

          <div className="row">
              <div className="col is-1-of-2">
                  <button type={'button'} className="btn fill gray" onClick={()=>{this.props.close()}}>Cancel</button>
              </div>
              <div className="col is-1-of-2">
                  <button className="btn fill blue" disabled={submitting} type={'submit'} ref={'submit'}>Save</button>
              </div>
          </div>
      </form>
);
	}
}

EditAssociationForm = reduxForm({
	form: 'EditAssociationForm',
	destroyOnUnmount: true
})(EditAssociationForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditAssociationForm));
