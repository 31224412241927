import React, {Component, Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import moment from 'moment';
import TableFilter from './TableFilter';
import ClubService from "../../../../services/api/club";
import GhinTable from "../../../shared/GhinTable";
import ExportDataModal from "../../../Modals/ExportDataModal";
import {GolfAssociationGroup} from "../../../../services/api/federations";
import {formatPhoneNumber} from "../../../utils";

export default class Table extends Component {

    constructor() {
        super();

        this.state = {
            tableFilterIsHidden: false,
            associations: [],
			loading: true,
			per_page: 25,
            filtered: {
				status: [{label:'Active',value:'Active'}],
              is_aga: { label: "Select All", value: undefined },
              join_renew_enabled: { label: "Select All", value: undefined },
				FrontEndProvider: [{label: "GHIN", value: "GHIN"}],
				is_test: {value: false, label: "No"}
			},
			page: 0,
            isAllSelected: false,
            isExportDataOpened: false,
            availableAssociationGroups: [],
            tableInternalData: [] //used to get filtered array from the table
        };

        this.handleDownload = this.handleDownload.bind(this);

    }

    toggleTableFilter() {
        this.setState({
            tableFilterIsHidden: !this.state.tableFilterIsHidden
        })
    }

    generateCountSummary(data) {
    	if (data.length > 0) {
			return {
				total: data.length,
				inactive: data.filter((item) => item.status === 'Inactive').length,
				active: data.filter((item) => item.status === 'Active').length,
			}
		} else {
    		return {
    			total: 0,
				inactive: 0,
				active: 0
			}
		}
    }

    loadAssociations() {
        return ClubService.getGolfAssociations().then((data) => {
            return data.associations;
        })
    }



    loadAssociationsGroups() {
        return GolfAssociationGroup.list().then((data) => {
            return data.golf_association_groups;
        })
    }

    getAssociationsAndClubs() {
		this.setState({
			loading: true,
			associations: []
		});
		Promise.all([this.loadAssociations(), this.loadAssociationsGroups()]).then((values) => {
			this.setState({
				loading: false,
				associations: values[0].map((association) => {
					const group = values[1].find((item) => item.golf_associations.find((assoc) => assoc.association_id === association.id));
					if (group) {
						return {
							...association,
							selected: false,
							association_group_id: group.id,
							telephone: formatPhoneNumber(association.telephone)
						}
					}
					return {
						...association,
						selected: false,
						association_group_id: null,
						telephone: formatPhoneNumber(association.telephone)
					};

				}),
				availableAssociationGroups: values[1].map((item) => {
					return {
						value: item.id,
						label: item.name
					}
				}).sort((a, b) => a['label']?.toString().localeCompare(b['label']?.toString()))
			}, ()=>{
				this.setState(this.generateCountSummary(this.table.getFilteredData()))
			});
		}).catch(() => {
			this.setState({
				loading: false
			});
		});
	}
    componentDidMount() {
        this.getAssociationsAndClubs();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    	if (prevProps.refreshList !== this.props.refreshList) {
    		if (this.props.refreshList) {
    			this.getAssociationsAndClubs()
    		}
		}
	}


	showExportModal() {
        const filteredData = this.table.getFilteredData();
        this.setState({
            tableInternalData: filteredData,
            isExportDataOpened: true
        })
    };


    render() {

        const columns = [
            {
                Header: '#',
                accessor: 'id',
                colClassName: 'col_id'
            },
            {
                Header: 'aga',
                accessor: 'is_aga',
                show: false
            },
            {
                Header: 'is_test',
                accessor: 'is_test',
                show: false
            },
			{
				Header: 'frontend_provider',
				accessor: 'frontend_provider',
				show: false
			},
            {
                Header: 'association_group_id',
                accessor: 'association_group_id',
                show: false
            },
            {
                Header: 'is_trial',
                accessor: 'is_trial',
                show: false
            },
            {
                Header: 'Association Name',
                accessor: 'name',
                filterMethod: (filter, row) =>
                    row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1,
                Cell: props => <Fragment>
                    <NavLink to={`/manage/association/${props.row.id}/list`}>{props.value}</NavLink>
                </Fragment>,
                colClassName: 'col_assoc-name'
            },
            {
                Header: 'Status',
                accessor: 'status',
                colClassName: 'col_status'
            },
            {
              Header: 'OJR',
              accessor: 'join_renew_enabled',
              className: 'without_tooltip',
              show: false,
            colClassName: 'col_ojr',
            Cell: props => <Fragment> {
              props.original.join_renew_enabled ?
                <span className="material-icons green v-mid club-icons-legend club-icons-legend-icon">
                  check_circle
                </span>
                :
                <span className="material-icons red v-mid club-icons-legend club-icons-legend-icon">
                  do_not_disturb_on
                </span>
            }</Fragment>
          },
          {
                Header: 'City',
                accessor: 'city',
                colClassName: 'col_city'
            },
            {
                Header: 'State/Prov',
                accessor: 'state',
                colClassName: 'col_state'
            },
            {
                Header: 'Country',
                accessor: 'country',
                colClassName: 'col_country'
            },
            {
                Header: 'Phone Number',
                accessor: 'telephone',
                colClassName: 'col_country',
				sortable: false
            },
            {
                Header: 'Email Address',
                accessor: 'email',
                colClassName: 'col_email',
				sortable: false,
				Cell: row => <Fragment>
					 <a href={`mailto: ${row.value}`}>{row.value}</a>
				</Fragment>
            },
            {
                Header: 'Primary Contact Name',
                accessor: 'primary_contact_name',
                colClassName: 'col_liaison',
				sortable: false
            }];
        return (
            <Fragment>

                <TableFilter availableAssociationGroups={this.state.availableAssociationGroups} gotData={this.state.associations.length > 0} hideFilters={this.state.tableFilterIsHidden} updateFiltered={(filtered) => {
                	this.setState({
                        filtered, page: 0, loading: true
                    }, () => {
						let self = this;
						setTimeout(function(){
                            if (self.table) {
                                const data = self.table.getFilteredData();
                                self.setState({
                                    ...self.generateCountSummary(data),
                                    loading: false
                                });
                            }
						},100);

                    })
                }}/>


				<div className="pre__table">
                    <div className="row">

                        <div className="col auto push-left">
                            <ul className="action__list push-left">
                                <li><button className="btn fill green"  onClick={(event) => {event.stopPropagation();this.showExportModal();}}>Export to Excel</button></li>
                            </ul>
                        </div>
                        <div className="col auto push-right">
                            <div className="row">
                                <div className="col">
                                    <ul className="association__status">
                                        <li>Active = <strong>{this.state.active}</strong></li>
                                        <li>Inactive = <strong>{this.state.inactive}</strong></li>
                                        <li>Total = <strong>{this.state.total}</strong></li>
                                    </ul>
                                </div>
                                <div className="col auto">
                                    {!this.state.tableFilterIsHidden &&
                                    <button onClick={this.toggleTableFilter.bind(this)} className="btn fill gray">Hide
                                        Filters <i className="material-icons-outlined">remove</i></button>}
                                    {this.state.tableFilterIsHidden &&
                                    <button onClick={this.toggleTableFilter.bind(this)} className="btn fill gray">Show
                                        Filters <i className="material-icons-outlined">add</i></button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

				<div className={'row'}><div className={'col'}><span className={"bold"}> <span className={"red"}>Important:</span>  When using the "Select All" checkbox, any action being performed will be taken on all records on all pages, not just the current page.</span></div></div>

				<GhinTable
                    columns={columns}
                    isAllSelected={this.state.isAllSelected}
                    data={this.state.associations}
                    filtered={this.state.filtered}
                    ref={(r) => this.table = r}
					pageSize={this.state.per_page}
					page={this.state.page}
					onPageChange={(page) => {
						this.setState({
							page
						})}}
          onPageSizeChange={(pageSize) => {
            this.setState({
              per_page: pageSize,
              page: 0
            })
          }}
					onSelectPage={() => {
						let associations = [...this.state.associations];
						const associationsToSelect = this.table.getFilteredData().slice(((this.state.page) *25) ,((this.state.page) *25) + 25);
						const associationsIdsToSelect = associationsToSelect.map((i) => i.id);
						let selected = false;
						//if they are any associations unselected, then select all
						//else unselect all
						if (associationsToSelect.filter((assoc) => assoc.selected).length !== associationsToSelect.length) {
							selected = true
						}

						associations = associations.map((assoc) => {
							if (associationsIdsToSelect.includes(assoc.id)) {
								return {
									...assoc,
									selected
								}
							}
							return {
								...assoc
							}
						});

						this.setState({
							associations
						})

					}}
                    onSelectAll={() => {
						let associations = [...this.state.associations];
						const isAllSelected = !this.state.isAllSelected;
						associations = associations.map((assoc) => {
							return {
								...assoc,
								selected: isAllSelected,
							}
						});
						this.setState({
							associations,
							isAllSelected
						});
                    }}
					onRowSelect={(row) => {
						const associations = [...this.state.associations].map((i) => {
							if (i.id === row.id) {
								return {
									...i,
									selected: !row.selected
								}
							} else {
								return i;
							}
						});
						this.setState({
							associations
						})

                    }}
                    defaultSorted={[{
                        id: 'name',
                        desc: false
                    }]}
                    sortable={true}
                    loading={this.state.loading}
                />
                <ExportDataModal
                    filename={"AssociationList_" + moment().format('MM/DD/YYYY') + ".csv"}
                    columns={columns}
                    isOpen={this.state.isExportDataOpened}
                    onRequestClose={() => this.setState({
                        isExportDataOpened: false
                    })}
					isAllSelected={this.state.isAllSelected}
					disableSelected={!(this.state.associations.filter(a=>a.selected).length > 0 || this.state.isAllSelected)}
                    data={this.state.tableInternalData}
					type={"link"}
					onDownloadClick={ this.handleDownload }
                />
            </Fragment>
        );
    }

	handleDownload() {
		this.setState({
			isExportDataOpened: false
		});
	}
}
