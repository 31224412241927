import React, {Component, Fragment} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import {withKeyPress} from "../../shared/highOrderComponent/withKeyPress";
import moment from "moment";
import ClubService from "../../../services/api/club";
import GolferService from "../../../services/api/golfer";
import {bindActionCreators} from "redux";
import {Field, formValueSelector, reduxForm, stopAsyncValidation} from "redux-form";
import {connect} from "react-redux";
import {SelectInput} from "../../../inputs/Select";
import {DatePickerInput} from "../../../inputs/DatePicker"
import {
	required,
	requiredSelect,
	localNumber,
	isEmailAddress,
  isPhoneNumber,
	maxLength,
} from "../../../inputs/validations";
import AssociationService from "../../../services/api/association";
import {InputField} from "../../../inputs/InputField";
import ConfirmationModalTypeB from "../../Modals/ConfirmationModalTypeB";
import {processErrorMessage} from "../../../services/shared/errorHelper";
import GhinGuardianFormComponent2 from "../../shared/GhinGuardianFormComponent2";

const genderOptions = [
	{value: 'M', label: 'Male'},
	{value: 'F', label: 'Female'},
];

class AddNewGolferForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			associations: [],
			clubs: [],
			membershipTypes: [],
			startDate: new Date(),
			isEmailDisabled: false,
			confirmation: false,
			confirmationError: null,
			error: null,
			membershipTypesLoaded: false,
      agaClub: false,
      selectedGuardian: false,
			areLockedMemberships: false,
			forceAge: false
		};

		this.onSubmit = this.onSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(date) {
		this.setState({
			startDate: date
		});
	}

  selectGuardian() {
    this.setState({
      selectedGuardian: !this.state.selectedGuardian
    })
  }

	componentDidMount() {
		if(this.props.access.user_type === "club-admin") {
			this.getClubAssociation();
		} else {
			this.getAssociations();
		}
		if (this.props.club) {
			this.getMembershipTypes(this.props.club.id ? this.props.club.id : this.props.club)
		}
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	getMembershipTypes(clubId){
		this.props.change('membership_code', null);
		this.setState({
			membershipTypesLoaded: false
		})
		ClubService.getMembershipTypes(clubId, {include_used_membership: 'false'})
			.then(res => {
				let lockedMemberships = false
				this.setState({
					membershipTypesLoaded: true,
					membershipTypes: res.membership_types ? res.membership_types.map(membershipType => {
						if (membershipType.locked)
							lockedMemberships = true;
						return {
							label:
								<Fragment>
									<div style={{display: "inline-flex", alignItems: "end"}}>
										<span style={{display: "flex", alignItems: "center"}}>
											<span className="membership-description-dropdown" style={{maxWidth: "250px"}}>{membershipType.code + ' - ' + membershipType.description}</span>
											{membershipType.locked && <i className="material-icons-outlined lock-icon-container"/>}
										</span>
									</div>
								</Fragment>,
							value: membershipType.id,
							type: membershipType.type,
							locked: membershipType.locked,
							isDisabled: !this.props.canEditAssociation && membershipType.locked
						};
					}).sort((a, b) => a['label']?.toString().localeCompare(b['label']?.toString())) : []
				});
				this.setState({ areLockedMemberships: lockedMemberships });
			})
			.catch(err => {
				this.setState({
					membershipTypesLoaded: true
				});
				console.error(err)
			})
	}

	getClubAssociation() {
		let associations = [{
			label: this.props.access.default_association_name,
			value: this.props.access.default_association_id,
			id: this.props.access.default_association_id
		}];
		this.setState({
				associations
			},() => {
		this.props.change('association', associations[0]);
		this.onAssociationChange(associations[0]);
		});
	}

	getAssociations() {
		ClubService.getGolfAssociations()
			.then(response => {
				let associations = response.associations.map(assoc => {
					assoc.value = assoc.id;
					assoc.label = assoc.name;
					return assoc
				});

				associations.sort((a, b) => a['label']?.toString().localeCompare(b['label']?.toString()));

				this.setState({
					associations
				}, () => {
					if (this.props.association ) {
							let initialAssoc = associations.filter(a => parseInt(a.id) === parseInt(this.props.association))[0];
							this.props.change('association', initialAssoc);
							this.onAssociationChange(initialAssoc);
					}
				});
			});
	}

	onAssociationChange(data){

		if (this.props.access.user_type === "club-admin") {
			let club = {label: this.props.access.default_club_name, value: parseInt(this.props.access.default_club_id), id: parseInt(this.props.access.default_club_id)};
			this.setState({
				clubs: [club],
        agaClub: data.is_aga
			}, ()=>{
				this.props.change('club', club);
			})
		} else {
			AssociationService.getAllClubs(data.id, {status: "Active"}).then((res) => {
				let clubs = res.clubs.map(club => {
					club.value = club.id;
					club.label =  club.club_name;
					return club
				});

				this.props.change('club', null);

				clubs.sort((a, b) => a['label']?.toString().localeCompare(b['label']?.toString()));

				this.setState({
					clubs,
          agaClub: data.is_aga
				}, ()=>{
					if (this.props.club) {
						this.props.change('club', clubs.filter( c => parseInt(c.id) === parseInt(this.props.club.id))[0]);
					}
				})
			});
		}
	}

	onSubmit(values) {
		let data = JSON.parse(JSON.stringify(values));
		data.golfer.gender = data.golfer.gender.value;
		delete data.asd;
		if(values.golfer.date_of_birth) {
			if (moment().diff(moment(values.golfer.date_of_birth), 'years') < 13) {
				data.golfer.email = '';
			}
			data.golfer.date_of_birth =  moment(values.golfer.date_of_birth).format('YYYY-MM-DD');
		} else {
			delete data.golfer.date_of_birth;
		}
		data.membership_code = {id: data.membership_code.value};
		let clubId = data.club.id;
		delete data.club;
		delete data.association

		if (data.guardian) {
			if (data.guardian.relationship) {
				data.guardian.relationship = data.guardian.relationship.value
			}
		}

		return GolferService.addGolfer(data, clubId)
			.then(res => {
				if(res.golfers){
					this.props.showSuccess(res.golfers);
				}
			})
			.catch(error => {

				let errorMessage = processErrorMessage(error.data.errors);
				if (error.data.show_confirmation) {
					data.force = "true";

					this.setState({forceAge: error.data.age_confirmation ? true : false});

					this.showConfirm({error: errorMessage, golfer:data, clubId});
				} else {
				  this.setState({
					error: errorMessage
				  });
				}
				console.error(error)
			});
	}

	showConfirm(data) {
		this.setState({
			confirmation: true,
			confirmationError: data.error,
			toPost: data.golfer,
			clubId: data.clubId
		});
	}

	onConfirm(){
		GolferService.addGolfer(this.state.toPost, this.state.clubId, this.state.forceAge)
			.then(res => {
				if(res.golfers){
					this.props.showSuccess(res.golfers);
				}
			})
			.catch(error => {
				if(error.data) {
					let errorMessage = processErrorMessage(error.data.errors);
					this.setState({
						error: error.data.errors,
						confirmation: true,
						confirmationError: errorMessage,
						forceAge: error.data.age_confirmation ? true : false
					})
				}
				console.error(error)
			});
	}


	render() {
		const {handleSubmit, pristine, valid, submitting} = this.props;
		const golferAge = this.props.date_of_birth ? moment().diff(moment(this.props.date_of_birth), 'years') : 30;
		const golferIsMinor =  golferAge < 13;
		const golferIsJunior =  golferAge < 19 && golferAge >= 13;
		const membershipForJunior = this.props.membership_code && this.props.membership_code.type === "Junior";
		return (
			<Fragment>
				{!this.state.confirmation && <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
					<div className="row">
						<div className="col">
							<label htmlFor="association">Association <span>*</span></label>
							<Field name={'association'}
								   validate={[required]}
								   component={SelectInput}
								   className="react-select-container"
								   classNamePrefix="react-select"
								   disabled={this.props.access.user_type === "club-admin" || this.props.access.user_type === "association-admin"}
								   onChange={(data) => this.onAssociationChange(data)}
								   options={this.state.associations}/>
						</div>
					</div>

					<div className="row">
						<div className="col">
							<label htmlFor="club">Club Name <span>*</span></label>
							<Field name={'club'}
								   validate={[required]}
								   component={SelectInput}
								   className="react-select-container"
								   classNamePrefix="react-select"
								   disabled={this.props.access.user_type === "club-admin" || this.state.clubs.length === 0}
								   options={this.state.clubs}
								   onChange={(data) => {this.getMembershipTypes(data.id)}}
							/>
						</div>
					</div>

					{!this.props.canEditAssociation && <div className="row" style={{marginBottom: 0}}>
						{this.state.areLockedMemberships && <div className="locked-info-box-add-golfer">
							<div><i className="material-icons-outlined lock-icon-container"/><span style={{fontWeight: '500'}}> Membership Code is Locked </span></div>
							<div style={{marginTop: '10px'}}> Locked Membership codes cannot be manually removed or assigned.</div>
							<div style={{marginTop: '10px'}}> Please contact your association for assistance.</div>
						</div>}
					</div>}
					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="membership_code">Membership Code <span>*</span></label>
							<Field name={'membership_code'}
								   validate={[requiredSelect]}
								   component={SelectInput}
								   disabled={!this.props.clubSelector || this.state.membershipTypes.length === 0 }
								   className="react-select-container memberships-select-container"
								   classNamePrefix="react-select"
								   options={this.state.membershipTypes}/>
							{this.props.clubSelector && this.state.membershipTypes.length === 0 && this.state.membershipTypesLoaded && <p className="validation__message is-error">Membership Types need to be added to the club prior to adding golfers.</p> }
						</div>
						{this.props.canEditAssociation && this.state.areLockedMemberships && <div className="col is-1-of-2">
							<div className="locked-membership-info-box" style={{position: "relative", top: "24px", right: '-10px', width: 'calc(100% - 10px)', padding: '10px 10px 9px 10px'}}>
								<div><i className="material-icons-outlined lock-icon-container lock-icon-container-relative"/><span style={{fontWeight: '500'}}> Membership Code is Locked </span></div>
							</div>
						</div>}
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="golfer.prefix">Prefix</label>
							<Field component={InputField}
								   type="text"
								   validate={[maxLength([4, "Value too long (max 4 characters)"])]}
								   name="golfer.prefix"
								   id="golfer.prefix" />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="golfer.first_name">First Name <span>*</span></label>
							<Field component={InputField}
								   validate={[required]}
								   type="text"
								   name="golfer.first_name"
								   id="golfer.first_name" />
						</div>
						<div className="col is-1-of-2">
							<label htmlFor="golfer.middle_name">Middle Name</label>
							<Field component={InputField}
								   type="text"
								   name="golfer.middle_name"
								   id="golfer.middle_name" />
						</div>
					</div>


					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="golfer.last_name">Last Name <span>*</span></label>
							<Field component={InputField}
								   type="text"
								   validate={[required]}
								   name="golfer.last_name"
								   id="golfer.last_name" />
						</div>
						<div className="col is-1-of-2">
							<label htmlFor="golfer.suffix">Suffix</label>
							<Field component={InputField}
								   type="text"
								   validate={[maxLength([4, "Value too long (max 4 characters)"])]}
								   name="golfer.suffix"
								   id="golfer.suffix" />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="golfer.gender">Gender <span>*</span></label>
							<Field name={'golfer.gender'}
								   id={'golfer.gender'}
								   validate={[required]}
								   component={SelectInput}
								   className="react-select-container"
								   classNamePrefix="react-select"
								   options={genderOptions}/>
						</div>
						<div className="col is-1-of-2">
							<label htmlFor="golfer.date_of_birth">Birthday {membershipForJunior && <span>*</span>}</label>
							<Field maxDate={new Date()}
								   className={'date'}
								   showMonthDropdown
								   showYearDropdown
								   name={'golfer.date_of_birth'}
								   id={'golfer.date_of_birth'}
								   validate={membershipForJunior ? [required] : []}
								   component={DatePickerInput}
								   placeholder="Select ... " />
						</div>
					</div>

          <div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="email">Email {!golferIsMinor && !this.state.selectedGuardian ? <span>*</span> : null}</label>
							<Field component={InputField}
								   disabled={golferIsMinor}
								   validate={!golferIsMinor && !this.state.selectedGuardian ? [required, isEmailAddress] : [isEmailAddress]}
								   type="text"
								   name={golferIsMinor ? 'placeholderValue' : "golfer.email"}
								   id={golferIsMinor ? 'placeholderValue' : "golfer.email"}
							/>
						</div>
						<div className="col is-1-of-2">
							<label htmlFor="phone_number">Phone Number</label>
							<Field component={InputField}
                    validate={this.state.agaClub ? [isPhoneNumber] : []}
                    type="text"
                    name="golfer.phone_number"
                    id="golfer.phone_number"
            />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="golfer_club.local_number">Local Number</label>
							<Field component={InputField}
								   validate={[localNumber]}
								   type="text"
								   name="golfer_club.local_number"
								   id="golfer_club.local_number" />
						</div>
					</div>

					{
						golferIsJunior || golferIsMinor ? <GhinGuardianFormComponent2 formGuardian={this.props.guardian} selectGuardian={() =>this.selectGuardian()} change={this.props.change} untouch={this.props.untouch} junior={golferIsJunior} minor={golferIsMinor} /> : null
					}

					{this.state.error && <span className={'validation__message is-error'}>{this.state.error}</span>}


					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} onClick={(e) => {
								e.preventDefault();
								this.props.resetToSelect()
							}} className="btn fill gray">Back
							</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={"submit"} disabled={pristine || !valid || submitting} className="btn fill blue">Add Golfer
							</button>
						</div>
					</div>
				</form>}
				{this.state.confirmation &&
				<ConfirmationModalTypeB openModal={this.state.confirmation}
										wideClass={"narrow"}
										onConfirmAction={() => this.onConfirm()}
										modalIcon={"help_outline"}
										onCancelAction={() => {
											this.setState({confirmation: false})
										}}
										closeModal={() => {
											this.setState({confirmation: false})
										}}
										infoText={this.state.confirmationError.split('\n')}
										cancelLabel={"Back"}
										confirmLabel={"Save"}/>
				}
			</Fragment>
		);
	}
}

AddNewGolferForm = reduxForm({
	form: 'addGolferForm',
	destroyOnUnmount: true
})(AddNewGolferForm);

function mapStateToProps(state) {
	const selector = formValueSelector('addGolferForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		access: state.user.access,
		assignGuardian: selector(state, 'assignGuardian'),
		clubSelector: selector(state, 'club'),
		membership_code: selector(state, 'membership_code'),
		date_of_birth: selector(state, 'golfer.date_of_birth'),
		guardian: selector(state, 'guardian')
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ stopAsyncValidation}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(AddNewGolferForm));
