import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import SharedService from '../../services/api/shared';
import {bindActionCreators} from "redux";
import {
	maxLength,
	isEmailAddress,
	required,
	requiredSelect,
	isPhoneNumber
} from "../../inputs/validations";
import {SelectInput} from "../../inputs/Select";
import {InputField} from "../../inputs/InputField";
import AssociationService from "../../services/api/association";
import ClubService from "../../services/api/club";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import {formatPhoneNumber} from "../utils";


class EditAddressForm extends Component {


	constructor(props){
		super(props);
		this.state = {
			countries: [],
			states: [],
			addressAsPrimary: false,
			contactAsPrimary: false,
		}
	}

	componentDidMount() {

		SharedService.getCountriesAndStates()
			.then((response) => {
				this.setState({
					countries: response.countries.map((item) => {
						return {
							...item,
							value: item.code,
							label: item.name,
							states: item.states.map((state) => {
								return {
									...state,
									value: state.code,
									label: state.name.includes('Armed ') ? state.code : state.name
								}
							})
						}
					})
				}, ()=>{this.addressInitialize();})
			})

		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onSubmit(values, dispatch, props) {

		let addressData = values.address.as_primary ? {as_primary: 'true'} : JSON.parse(JSON.stringify(values.address));
		let contactData = values.contact.as_primary ? {as_primary: 'true'} : JSON.parse(JSON.stringify(values.contact));
		delete addressData.state_name;
		delete addressData.country_name;

		if(addressData.country) addressData.country = addressData.country.value;
		if(addressData.state) addressData.state = addressData.state.value;
		addressData.as_primary = addressData.as_primary ? 'true' : 'false';
		contactData.as_primary = contactData.as_primary ? 'true' : 'false';
		if(props.for === "association"){
			return AssociationService.editAddress(props.assoc.profile.id, {[props.type + '_address']:addressData,[props.type + '_contact_address']: contactData})
				.then(res => {
					props.addAlert({
						type:'success',
						message: props.type.charAt(0).toUpperCase() + props.type.slice(1) + ' address has been successfully edited'
					});
					props.close(res);
				})
				.catch(err => {
					console.error(err);
				})
		} else {
			return ClubService.editAddress(props.assoc.club.golf_association_id, props.assoc.club.id, {[props.type + '_address']:addressData,[props.type + '_contact_address']: contactData})
				.then(res => {
					props.addAlert({
						type:'success',
						message: props.type.charAt(0).toUpperCase() + props.type.slice(1) + ' address has been successfully edited'
					});
					props.close(res);
				})
				.catch(err => {
					console.error(err);
				})
		}


	}

	onCountryChange(country) {
		this.props.change('address.state',{label: 'Select State', value: null});
		this.setState({
			states: country.states
		})
	}

	addressInitialize() {

		let currentCountry = this.state.countries.filter(country => {
			if(this.props.assoc[this.props.type + '_address'].country_name) {
				return (country.value === this.props.assoc[this.props.type + '_address'].country)

			} else {
				return (country.value === "USA")
			}
		})[0];

		this.props.initialize({
			address: {
				...this.props.assoc[this.props.type + '_address'],
				...{
					country: currentCountry,
					state: {
						label:  this.props.assoc[this.props.type + '_address'].state_name?.includes('Armed ') ? this.props.assoc[this.props.type + '_address'].state : this.props.assoc[this.props.type + '_address'].state_name,
						value: this.props.assoc[this.props.type + '_address'].state
					},
					as_primary: this.props.assoc[this.props.type + '_address'].as_primary === "false" ? false : true
				}
			},
			contact: {
				...this.props.assoc[this.props.type + '_contact_address'],
				...{
					phone: formatPhoneNumber(this.props.assoc[this.props.type + '_contact_address'].phone),
					as_primary: this.props.assoc[this.props.type + '_contact_address'].as_primary === "false" ? false : true}
			}
		});
		this.setState({
			states:  currentCountry.states,
			addressAsPrimary: this.props.type === "primary" ? false : (this.props.assoc[this.props.type + '_address'].as_primary === "false" ? false : true),
			contactAsPrimary: this.props.assoc[this.props.type + '_contact_address'].as_primary === "false" ? false : true
		});
	}


	setAddressAsPrimary() {
		this.setState((prevState) => {
			return {addressAsPrimary: !prevState.addressAsPrimary}
		}, () => {
			if(this.state.addressAsPrimary) {
				this.props.change('address', {
					...this.props.assoc.primary_address,
					...{
						state: {label: this.props.assoc.primary_address.state_name, value: this.props.assoc.primary_address.state},
						country: {label: this.props.assoc.primary_address.country_name, value: this.props.assoc.primary_address.country},
						as_primary: true
					}
				});
			} else {
				this.props.change('address',{
					...this.props.assoc[this.props.type + '_address'],
					...{
						country: {label: this.props.assoc[this.props.type + '_address'].country_name, value: this.props.assoc[this.props.type + '_address'].country},
						state: {label: this.props.assoc[this.props.type + '_address'].state_name, value: this.props.assoc[this.props.type + '_address'].state},
						as_primary: false
					}
				});
			}
		});
	}

	setContactAsPrimary() {

		this.setState((prevState) => {
			return {contactAsPrimary: !prevState.contactAsPrimary};
		},() => {
			if(this.state.contactAsPrimary) {
				this.props.change('contact', {...this.props.assoc.primary_contact_address, ...{as_primary: true}});
			} else {
				this.props.change('contact', {...this.props.assoc[this.props.type + '_contact_address'], ...{as_primary: false}});
			}
		});
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
                <div className="row">
					<div className="col is-1-of-3 jc-c">
						<h3>Address Information</h3>
					</div>
					{this.props.type !== 'primary' && <div className="col is-1-of-3">
						<Field
							onClick={() => this.setAddressAsPrimary()}
							checked={this.state.addressAsPrimary}
							id="address.as_primary"
							value={true}
							name={'address.as_primary'}
							component={'input'}
							type="checkbox"
						/>
						<label htmlFor="address.as_primary">Same as primary</label>
					</div>}

                </div>

                <div className="row">
                    <div className="col is-full">
                        <label  htmlFor={'address.country'}>Country <span>*</span></label>
						<Field name={'address.country'}
							   id={'address.country'}
							   validate={!this.state.addressAsPrimary ? [requiredSelect] : undefined}
							   component={SelectInput}
							   disabled={this.state.addressAsPrimary}
							   onChange={this.onCountryChange.bind(this)}
							   className="react-select-container"
							   classNamePrefix="react-select"
							   options={this.state.countries}/>
					</div>
                </div>

                <div className="row">
                    <div className="col is-full">
                        <label htmlFor={'address.street_1'}>Address 1 <span>*</span></label>
						<Field component={InputField}
									 disabled={this.state.addressAsPrimary}
									 type="text" name={'address.street_1'}
									 id={'address.street_1'}
									validate={!this.state.addressAsPrimary ? [required, maxLength([46, "Value too long (max 46 characters)"])] : undefined}
						/>
					</div>
                </div>

                <div className="row">
                    <div className="col is-full">
                        <label htmlFor={'address.street_2'}>Address 2</label>
						<Field
							component={InputField}
							disabled={this.state.addressAsPrimary}
							type="text" name={'address.street_2'}
							id={'address.street_2'}
							validate={[maxLength([46, "Value too long (max 46 characters)"])]}
						/>
					</div>
                </div>

                <div className="row">
                    <div className="col is-3-of-7">
                        <label htmlFor={'address.city'}>City <span>*</span></label>
						<Field component={InputField}
							   disabled={this.state.addressAsPrimary}
							   validate={!this.state.addressAsPrimary ? [required] : undefined}
							   type="text"
							   name={'address.city'}
							   id={'address.city'} />

					</div>
                    <div className="col is-2-of-7">
                        <label htmlFor="address.state">State <span>*</span></label>
						<Field name={'address.state'}
							   id={'address.state'}
							   disabled={this.state.addressAsPrimary}
							   validate={!this.state.addressAsPrimary ? [requiredSelect] : undefined}
							   component={SelectInput}
							   className="react-select-container"
							   classNamePrefix="react-select"
							   options={this.state.states}/>
                    </div>
                    <div className="col is-2-of-7">
                        <label htmlFor={'zip'}>Postal Code <span>*</span></label>
						<Field
							component={InputField}
							disabled={this.state.addressAsPrimary}
							validate={!this.state.addressAsPrimary ? [required] : undefined}
							type="text" name={'address.zip'}
							id={'address.zip'}
						/>
					</div>
                </div>
				<div className="row">
					<div className="col is-1-of-3 jc-c"></div>
				</div>
                <div className="row">
                    <div className="col is-1-of-3 jc-c">
						<h3>Address Contact</h3>
                    </div>

					{this.props.type !== 'primary' && <div className="col is-1-of-3">
						<Field
							onClick={() => {this.setContactAsPrimary()}}
							checked={this.state.contactAsPrimary}
							id="contact.as_primary"
							value={true}
							name={'contact.as_primary'}
							component={'input'}
							type="checkbox"
						/>
						<label htmlFor="contact.as_primary">Same as primary</label>
					</div>}
                </div>

				<div className="row">
					<div className="col is-1-of-2">
						<label htmlFor={'contact.prefix'}>Prefix</label>
						<Field component={InputField} disabled={this.state.contactAsPrimary} type="text" name={'contact.prefix'} id={'contact.prefix'} />
					</div>
				</div>

				<div className="row">
					<div className="col is-1-of-2">
						<label htmlFor={'contact.first_name'}>First Name <span>*</span></label>
						<Field
							component={InputField}
							disabled={this.state.contactAsPrimary}
							validate={!this.state.contactAsPrimary ? [required] : undefined}
							type="text" name={'contact.first_name'}
							id={'contact.first_name'}
						/>
					</div>
					<div className="col is-1-of-2">
						<label htmlFor={'contact.middle_name'}>Middle Name</label>
						<Field component={InputField} disabled={this.state.contactAsPrimary} type="text" name={'contact.middle_name'} id={'contact.middle_name'} />
					</div>
				</div>

				<div className="row">
					<div className="col is-1-of-2">
						<label htmlFor={'contact.last_name'}>Last name <span>*</span></label>
						<Field
							component={InputField}
							disabled={this.state.contactAsPrimary}
							validate={!this.state.contactAsPrimary ? [required] : undefined}
							type="text" name={'contact.last_name'}
							id={'contact.last_name'}
						/>
					</div>
					<div className="col is-1-of-2">
						<label htmlFor={'contact.suffix'}>Suffix </label>
						<Field component={InputField} disabled={this.state.contactAsPrimary} type="text" name={'contact.suffix'} id={'contact.suffix'} />
					</div>
				</div>

				<div className="row">
					<div className="col is-1-of-2">
						<label htmlFor={'contact.email'}>Email <span>*</span></label>
						<Field
							component={InputField}
							disabled={this.state.contactAsPrimary}
							validate={!this.state.contactAsPrimary ? [required, isEmailAddress] : undefined}
							type="text"
							name={'contact.email'}
							id={'contact.email'}
						/>
					</div>
					<div className="col is-1-of-2">
						<label htmlFor={'contact.phone'}>Phone Number <span>*</span></label>
						<Field
							component={InputField}
							disabled={this.state.contactAsPrimary}
							validate={!this.state.contactAsPrimary ? [required, isPhoneNumber] : undefined}
							type="text"
							name={'contact.phone'}
							id={'contact.phone'}
						/>
					</div>
				</div>

                <div className="row">
                    <div className="col is-1-of-2">
                        <button type={'button'} className="btn fill gray" onClick={() => this.props.close(null)}>Cancel</button>
                    </div>
                    <div className="col is-1-of-2">
						<button disabled={pristine || submitting} type={'submit'} ref={'submit'} className="btn fill blue">Save</button>
                    </div>
                </div>

				</form>
			</Fragment>
		);
	}
}


EditAddressForm = reduxForm({
	form: 'editAddressForm',
	destroyOnUnmount: true
})(EditAddressForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditAddressForm));
