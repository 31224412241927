import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { addAlert } from "../shared/actions";
import { withKeyPress } from "../shared/highOrderComponent/withKeyPress";
import JoinAndRenewServices from "../../services/api/joinAndRenew";
import { isNumber } from "../../inputs/validations";
import { InputField } from "../../inputs/InputField";
import ConfirmationModalTypeB from "../Modals/ConfirmationModalTypeB";

import { 
  CALENDAR_YEAR_END_DATE, CALENDAR_YEAR_END_DATE_TEXT,
  REMAINING_DAYS_1, REMAINING_DAYS_2, REMAINING_DAYS_3
} from "../../helpers/stagingEnvConstants";

function EditJoinClubSettings(props) {
  const calendar_year_end_date = CALENDAR_YEAR_END_DATE;
  const [membership_cycle, set_membership_cycle] = useState(null);
  const [remaining_days, set_remaining_days] = useState(null);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [error, setError] = useState("");

  const onSubmit = (values, _, props) => {
    values.membership_cycle = membership_cycle;
    values.remaining_days = membership_cycle ? remaining_days : null;

    if (props.joinClubSettings.enable_auto_renewal && !values["enable_auto_renewal"]) {
      setConfirmationModalIsOpen(true);
      setFormData({"values": values, "_": _, "props": props});
      return;
    }

    if (props.joinClubSettings.membership_cycle !== membership_cycle) {
      props.setChangeMembershipCycleModalIsOpen(true);
      setFormData({"values": values, "_": _, "props": props});
      return;
    }

    updateSettings(values, _, props).then(() => {});
  }

  const updateSettings = (values, _, props) => {
    return JoinAndRenewServices.updateJoinClubSettings(values.club_id, { ...values })
      .then((res) => {
        props.closeModal();
        props.updateSettings();
        if (props.joinClubSettings.approval_needed === false && res.join_club_settings.approval_needed === true)
          props.reloadGolfersCount();
        props.addAlert({ type: "success", message: "Join and Renew settings sucessfully updated."})
      })
      .catch(err => {
        try {
          console.log(err);
          const errors = err.data.errors;
          if (typeof (errors[Object.keys(errors)[0]][0]) === 'string') {
            setError(errors[Object.keys(errors)[0]][0])
          }
        }
        catch (e) {
          setError('An error has occured.')
        }
      })
  }

  useEffect(() => {
    const initialValues = {
      ...props.joinClubSettings,
    };
    set_membership_cycle(props.joinClubSettings.membership_cycle);
    set_remaining_days(props.joinClubSettings.remaining_days);

    if (props.joinClubSettings.enable_online_join === false && props.joinClubSettings.enable_online_renewal === false) {
      initialValues.enable_online_join = true;
      initialValues.enable_online_renewal = true;
      initialValues.enable_auto_renewal = true;
      initialValues.membership_cycle = calendar_year_end_date;
      initialValues.remaining_days = null;
      set_membership_cycle(calendar_year_end_date);
      set_remaining_days(null);
    }

    props.initialize(initialValues);
  }, [props.joinClubSettings]);

  const { handleSubmit, submitting, valid } = props;

  const handleModalConfirm = () => {
    setConfirmationModalIsOpen(false);
    if (formData) {
      updateSettings(formData["values"], formData["_"], formData["props"]).then(() => {});
    }
  }

  const calculateDate = (endDate, daysBefore) => {
    const [month, day] = endDate.split('/');
    const year = new Date().getFullYear();
    const date = new Date(year, month - 1, day)
    date.setDate(date.getDate() - daysBefore);
    return `${(date.getMonth() + 1)?.toString().padStart(2, '0')}/${date.getDate()?.toString().padStart(2, '0')}`;
  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <label htmlFor="enable_join_renew"><strong>Enable Join & Renew</strong></label>
            <p>To enable this club to utilize the join & renew functionality, please check the box below.</p>
          </div>
        </div>
        <div className="row">
          <div className="col is-1-of-2">
            <Field
              component={'input'}
              type="checkbox"
              name="enable_online_join"
              id="enable_online_join"
            />
            <label htmlFor="enable_online_join">Enable Online Join</label>
          </div>
          <div className="col is-1-of-2">
            <Field
              component={'input'}
              type="checkbox"
              name="enable_online_renewal"
              id="enable_online_renewal"
            />
            <label htmlFor="enable_online_renewal">Enable Online Renewals</label>
          </div>
        </div>

        <hr style={{border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px'}}></hr>

        <div className="row">
          <div className="col">
            <label htmlFor="enable_auto_renewal"><strong>Auto-Renewals</strong></label>
            <p>To enable the auto-renew functionality, please check the box below.</p>
            <Field
              component={'input'}
              type="checkbox"
              name="enable_auto_renewal"
              id="enable_auto_renewal"
            />
            <label htmlFor="enable_auto_renewal">Enable Auto-Renew</label>
          </div>
        </div>

        <hr style={{border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px'}}></hr>

        <div className="row">
          <div className="col">
            <label htmlFor="approval"><strong>Membership Cycle</strong></label>
            <p>Please select a membership cycle for this club. With an anniversary cycle, a member’s 12-month membership
              will be up for renewal on the anniversary of their original join date. With the Calendar Year cycle, the
              membership will be up for renewal on {CALENDAR_YEAR_END_DATE_TEXT} of the current calendar year.</p>
            <p style={{marginTop: "16px"}}><span className="red-note"><strong>Important: </strong></span>Changing the
              membership cycle will turn off auto-renewals for subscribed golfers.</p>
            <label style={{marginTop: "16px"}} htmlFor="select-membership-cycle"><strong>Select Membership Cycle</strong></label>
            <div className="radio__container">
              <Field
                id="membership-cycle-yes"
                value={calendar_year_end_date}
                onClick={() => {
                  set_membership_cycle(calendar_year_end_date);
                }}
                name={'membership_cycle'}
                component={'input'}
                checked={membership_cycle}
                className="simple__radio"
                type="radio"
              />
              <label htmlFor="membership-cycle-yes" style={{marginRight: "65px"}}>Calendar Year</label>
              <Field
                id="membership-cycle-no"
                value={null}
                onClick={() => {
                  set_membership_cycle(null);
                }}
                name={'membership_cycle'}
                component={'input'}
                checked={!membership_cycle}
                className="simple__radio"
                type="radio"
              />
              <label htmlFor="membership-cycle-no">Anniversary (365)</label>
            </div>
          </div>
        </div>
        {membership_cycle && <div className="row">
          <div className="col">
            <label htmlFor="approval"><strong>Include Remainder:</strong></label>
            <p>If a golfer joins on or after the Include Remainder date, the remainder of the current calendar year will
              be added to membership of the upcoming year.</p>
            <div style={{marginTop: "16px"}} className="radio__container">
              <Field
                id="remaining-days-null"
                value={null}
                onClick={() => {
                  set_remaining_days(null);
                }}
                name={'remaining_days'}
                component={'input'}
                checked={!remaining_days}
                className="simple__radio"
                type="radio"
              />
              <label htmlFor="remaining-days-null">Do not include the remainder of the current year</label>
            </div>
            <div style={{marginTop: "16px"}} className="radio__container">
              <Field
                id="remaining-days-30"
                value={REMAINING_DAYS_1}
                onClick={() => {
                  set_remaining_days(REMAINING_DAYS_1);
                }}
                name={'remaining_days'}
                component={'input'}
                checked={remaining_days === REMAINING_DAYS_1}
                className="simple__radio"
                type="radio"
              />
              <label htmlFor="remaining-days-30"> {REMAINING_DAYS_1} days prior to Calendar Year End Date
                [ {calculateDate(calendar_year_end_date, REMAINING_DAYS_1)} ]</label>
            </div>
            <div style={{marginTop: "16px"}} className="radio__container">
              <Field
                id="remaining-days-60"
                value={REMAINING_DAYS_2}
                onClick={() => {
                  set_remaining_days(REMAINING_DAYS_2);
                }}
                name={'remaining_days'}
                component={'input'}
                checked={remaining_days === REMAINING_DAYS_2}
                className="simple__radio"
                type="radio"
              />
              <label htmlFor="remaining-days-60">{REMAINING_DAYS_2} days prior to Calendar Year End Date
                [ {calculateDate(calendar_year_end_date, REMAINING_DAYS_2)} ]</label>
            </div>
            <div style={{marginTop: "16px"}} className="radio__container">
              <Field
                id="remaining-days-90"
                value={REMAINING_DAYS_3}
                onClick={() => {
                  set_remaining_days(REMAINING_DAYS_3);
                }}
                name={'remaining_days'}
                component={'input'}
                checked={remaining_days === REMAINING_DAYS_3}
                className="simple__radio"
                type="radio"
              />
              <label htmlFor="remaining-days-90"> {REMAINING_DAYS_3} days prior to Calendar Year End Date
                [ {calculateDate(calendar_year_end_date, REMAINING_DAYS_3)} ]</label>
            </div>
          </div>
        </div>
        }

        <hr style={{border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px'}}></hr>

        <div className="row">
          <div className="col">
            <label htmlFor="approval"><strong>Approval Needed?</strong></label>
            <p>If approval is needed prior to adding golfers to this club when they join online, please check the box
              below.</p>
            <Field
              component={'input'}
              type="checkbox"
              name="approval_needed"
              id="approval_needed"
            />
            <label htmlFor="approval_needed">Approval Needed</label>

          </div>
        </div>

        <hr style={{border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px'}}></hr>

        <div className="row">
          <div className="col">
            <label htmlFor="search_zip_code"><strong>Join & Renew Search Zip Code</strong></label>
          </div>
        </div>
        <div className="row">
          <div style={{marginTop: '11px'}} className="col is-1-of-4">
            <label htmlFor="zip_code">Search Zip Code</label>
          </div>
          <div style={{marginLeft: '-40px'}} className="col is-1-of-4">
            <Field
              component={InputField}
              type="text"
              validate={[isNumber]}
              name="zip_code"
              id="zip_code"
            />
          </div>
          <div className="col is-1-of-2">
            If the search radius zip code is different than the club's primary address zip code, please enter it here.
            If it is the same, leave blank.
          </div>
        </div>

        <hr style={{border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px'}}></hr>

        <div className="row">
          <div className="col">
            <label htmlFor="enable_reminders_manual_golfers"><strong>Membership Reminder Emails for Manually Added Golfers</strong></label>
            <p>To enable membership reminders for manually added golfers, please check the box below.</p>
            <Field
              component={'input'}
              type="checkbox"
              name="enable_reminders_manual_golfers"
              id="enable_reminders_manual_golfers"
            />
            <label htmlFor="enable_reminders_manual_golfers">Send reminders to manually added golfers</label>
          </div>
        </div>

        {error && <span className="validation__message is-error">{error}</span>}
        <div className="row">
          <div className="col is-1-of-2">
            <button type="button" className="btn fill gray" onClick={() => props.closeModal()}>Cancel</button>
          </div>
          <div className="col is-1-of-2">
            <button type="submit" disabled={submitting || !valid} className="btn fill blue">Save</button>
          </div>
        </div>
      </form>
      <ConfirmationModalTypeB
        question={`Disable Auto-Renew?`}
        openModal={confirmationModalIsOpen}
        wideClass={"narrow"}
        colorClass={"brick_red"}
        onConfirmAction={() => {
          handleModalConfirm()
        }}
        modalIcon={"help_outline"}
        onCancelAction={() => {
          setConfirmationModalIsOpen(false)
        }}
        closeModal={() => {
          setConfirmationModalIsOpen(false)
        }}
        infoText={[`Are you sure you want to disable auto-renew for the ${props.joinClubSettings.club_name}?`,
          '',
          `Doing so will generate an email notification to all golfers in the club that have enrolled in the service
        letting them know auto-renew has been turned off.`]}
        cancelLabel={"Cancel"}
        confirmLabel={"Turn Off Auto-Renew"}/>
      <ConfirmationModalTypeB
        openModal={props.changeMembershipCycleModalIsOpen}
        onConfirmAction={() => {
          handleModalConfirm()
        }}
        modalIcon={"help_outline"}
        onCancelAction={() => {
          props.setChangeMembershipCycleModalIsOpen(false)
        }}
        closeModal={() => {
          props.setChangeMembershipCycleModalIsOpen(false)
        }}
        infoText={[`Changing the membership cycle will turn off auto-renewals for subscribed golfers.`,
          `Press “Save” if you would like to proceed.`,
          `Press “Back” to undo the membership cycle change before submitting the form.`]}
        cancelLabel={"Back"}
        confirmLabel={"Save"} />
    </Fragment>
  );
}

const EditJoinClubSettingsForm = reduxForm({
  form: 'EditJoinClubSettings',
  destroyOnUnmount: true
})(EditJoinClubSettings);

function mapStateToProps() {
  return {
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    noOverwriteOnInitialize: true
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAlert }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditJoinClubSettingsForm));
