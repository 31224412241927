import React, {Fragment, useCallback, useEffect, useState} from "react";
import GhinTable from "../../../../shared/GhinTable";
import JoinAndRenewServices from "../../../../../services/api/joinAndRenew";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {addAlert} from "../../../../shared/actions";
import moment from "moment";
import Modal from "react-modal";
import AddOrEditOfferModal from "../../../../Modals/AddOrEditOfferModal";

function OfferDetails(props) {
  const offer = props.data
  const [clubs, set_clubs] = useState([])
  const [clubs_loading, set_clubs_loading] = useState(false)
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [pages, setPages] = useState(null);
  const [total, setTotal] = useState(null);
  const [editOfferModalIsOpen, set_editOfferModalIsOpen] = useState(false)

  const columns = [{
    Header: "Club #", accessor: "id", colClassName: 'col_offer_id'
  }, {
    Header: "Club Name", accessor: "club_name"
  }, {
    expander: true, show: false
  }]

  const load_clubs = useCallback(() => {
    set_clubs_loading(true);
    const params = {
      page: page + 1, per_page: perPage
    }
    JoinAndRenewServices.getPromotionalCodeAppliedClubs(props.association_id, props.selected_offer.id, params).then((res) => {
      setTotal(parseInt(res["total-count"], 10));
      setPerPage(parseInt(res["per-page"], 10));
      setPages(parseInt(res["total-pages"], 10));
      set_clubs(res.clubs);
      set_clubs_loading(false);
    }).finally(() => {
      set_clubs_loading(false);
    });

  }, [props.association_id, props.selected_offer.id, page, perPage])

  useEffect(() => {
    load_clubs();
  }, [load_clubs])

  const formatGender = (gender) => {
    if (gender === "male and female") return "Male & Female";
    else if (gender === "male") return "Male";
    else return "Female";
  };

  const formatAvailableToGolfers = (offer) => {
    if (offer.available_to_all_golfers) return "All";
    else {
      var str = "";
      if (offer.available_to_renewing_golfers)
        str = str + "Renewing";
      if (offer.available_to_lapsed_golfers)
        str = str === "" ? "Lapsed" : str + ", Lapsed"
      if (offer.available_to_new_golfers)
        str = str === "" ? "New" : str + ", New"
      return str;
    }
  };

  return (<Fragment>
    <td colSpan={12} style={{padding: "10px"}}>
      <div className="columns">
        <div className="row">
          <div className="col">
            <div className="panel">
              <div className="panel__body">
                <div className="row centered-items hr-margin-24-top hr-margin-24-bottom">
                  <div className="col auto">
                    <h2 className="black-text no-wrap">
                      {offer.code.toUpperCase()} DETAILS
                    </h2>
                  </div>
                </div>

                <div className="row">
                  <div className="col is-12-of-24 data-set__container">
                    <div className="col data-set">
                      <span><strong>Offer Code:</strong></span>
                      <span>{offer.code}</span>
                    </div>
                    <div className="col data-set">
                      <span><strong>Automatically Apply:</strong></span>
                      <span>{offer.auto_apply ? 'Yes' : 'No'}</span>
                    </div>
                    <div className="col data-set">
                      <span><strong>Offer Description:</strong></span>
                      <span>{offer.description}</span>
                    </div>
                    <div className="col data-set">
                      <span><strong>Message when Applied:</strong></span>
                      <span>{offer.subtext}</span>
                    </div>
                    <div className="col data-set">
                      <span><strong>Offer Type:</strong></span>
                      <span>{offer.offer_type.charAt(0).toUpperCase() + offer.offer_type.slice(1)}</span>
                    </div>
                    <div className="col data-set">
                      <span><strong>Dollar Amount or Percentage?:</strong></span>
                      <span>{offer.offer_type === 'promotion' ? '-' : offer.discount_type === 'dollar' ? 'Dollar Amount' : 'Percentage'}</span>
                    </div>
                    <div className="col data-set">
                      <span><strong>Discount Amount:</strong></span>
                      <span>{offer.offer_type === 'promotion' ? '-' : offer.discount_type === 'dollar' ? `$${offer.discount_value.toFixed(2)}` : `${offer.discount_value}%`}</span>
                    </div>
                    <div className="col data-set">
                      <span><strong>Club Discount?:</strong></span>
                      <span>{offer.offer_type === 'discount' ? (offer.club_discount ? 'Yes' : 'No') : "-"}</span>
                    </div>
                    <div className="col data-set">
                      <span><strong>Status:</strong></span>
                      <span>{offer.status}</span>
                    </div>
                    <div className="col data-set">
                      <span><strong>Date Created:</strong></span>
                      <span>{moment(new Date(offer.created_at), 'YYYY/MM/DD HH:mm:ss').format('MM/DD/YYYY')}</span>
                    </div>
                  </div>

                  <div className="col is-8-of-24 data-set__container">
                    <div className="col data-set">
                      <span><strong>Valid From:</strong></span>
                      <span>{moment(new Date(offer.valid_from), 'YYYY/MM/DD HH:mm:ss').format('MM/DD/YYYY')}</span>
                    </div>
                    <div className="col data-set">
                      <span><strong>Valid To:</strong></span>
                      <span>{offer.valid_to ? moment(new Date(offer.valid_to), 'YYYY/MM/DD HH:mm:ss').format('MM/DD/YYYY') : '-'}</span>
                    </div>
                    <div className="col data-set">
                      <span><strong>Clubs Applied To:</strong></span>
                      <span>{offer.available_to_all_ojr_clubs ? "All Clubs" : "Selected Clubs"}</span>
                    </div>
                    <div className="col data-set">
                      <span><strong>Number of Uses Per Golfer:</strong></span>
                      <span>{props.selected_offer.golfer_usage_limit ? props.selected_offer.golfer_usage_limit : '-'}</span>
                    </div>
                    <div className="col data-set">
                      <span><strong>Number Available:</strong></span>
                      <span>{props.selected_offer.usage_limit ? props.selected_offer.usage_limit : '-'}</span>
                    </div>
                    <div className="col data-set">
                      <span><strong>Number of Times Used:</strong></span>
                      <span>{offer.times_used}</span>
                    </div>
                    <div className="col data-set">
                      <span><strong>Golfers in Selected Clubs Applied To:</strong></span>
                      <span>{formatAvailableToGolfers(offer)}</span>
                    </div>
                    <div className="col data-set">
                      <span><strong>Gender:</strong></span>
                      <span>{formatGender(offer.gender_eligibility)}</span>
                    </div>
                    <div className="col data-set">
                      <span><strong>Minimum Age:</strong></span>
                      <span>{offer.minimum_age ? offer.minimum_age : '-'}</span>
                    </div>
                    <div className="col data-set">
                      <span><strong>Maximum Age:</strong></span>
                      <span>{offer.maximum_age ? offer.maximum_age : '-'}</span>
                    </div>
                  </div>

                  {props.canEditAssociation && <div className="col auto push-right">
                    <div className="row" style={{display: "flex", justifyContent: "end"}}>
                      <div className="col">
                        <button
                          className="btn fill green"
                          onClick={() => {set_editOfferModalIsOpen(true)}}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="row" style={{display: "flex", justifyContent: "end"}}>
                      <div className="col auto">
                        <button
                          className="btn fill green"
                          onClick={() => {props.exportOffer('Details Modal')}}
                        >
                          Export to CSV
                        </button>
                      </div>
                    </div>

                  </div>}
                </div>

                <hr style={{border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px'}}></hr>

                <div className="row">
                  <div className="col">
                    <p><strong>Clubs Applied To</strong></p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p>This code has been applied to the following clubs.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <GhinTable
                      idKey={'id'}
                      hideSelect={true}
                      sortable={false}
                      hidePagination={false}
                      columns={columns}
                      data={clubs}
                      loading={clubs_loading}
                      pages={pages}
                      page={page}
                      total={total}
                      pageSize={perPage}
                      manual
                      onPageChange={(index) => setPage(index)}
                      onPageSizeChange={(size, index) => {
                        setPerPage(size);
                        setPage(index);
                      }}
                      onSortedChange={(newSorted) => {
                        setPage(0)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </td>

    <Modal
      isOpen={editOfferModalIsOpen}
      contentLabel="Modal"
      portalClassName="modal"
      overlayClassName="modal__overlay"
      className="modal__content"
      bodyOpenClassName="modal--is-open"
      htmlOpenClassName="prevent-scroll"
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={false}
    >
      <AddOrEditOfferModal
        reload_offers={props.reloadPages}
        closeModal={() => {
          set_editOfferModalIsOpen(false)
        }}
        operationType={"Edit"}
        association_id={props.association_id}
        selected_offer={props.selected_offer}
      />
    </Modal>
  </Fragment>)
}

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({addAlert}, dispatch);
  return {
    dispatch, ...actions
  };
}

export default connect(null, mapDispatchToProps)(OfferDetails);
