import React, {Component, Fragment} from 'react';
import {Field, reduxForm, getFormValues} from "redux-form";
import moment from "moment";
import {DatePickerInput} from "../../../inputs/DatePicker";
import {addAlert} from "../../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {SelectInput} from "../../../inputs/Select";
import ClubService from "../../../services/api/club";
import {withRouter} from "react-router-dom";
import flatten from 'lodash.flatten'
import CourseRatingSystemService from "../../../services/api/courseRatingSystem";
import ScoreService from "../../../services/api/score";
import {InputField} from "../../../inputs/InputField";
import debounce from "debounce-promise";
import {AsyncSelectInput} from "../../../inputs/AsyncSelectInput";
import {requiredSelect} from "../../../inputs/validations";
import ConfirmationModalTypeB from "../../Modals/ConfirmationModalTypeB";
import {withKeyPress} from "../../shared/highOrderComponent/withKeyPress";
import {orderTees} from "../../../services/shared/teesFilter";
import {checkHoles} from "../../utils";

class HoleByHoleForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
      	no_hi_x_score_modal_is_open: false,
			scoreType: 'home',
			nrHoles: '18',
			method: 'home-course',
			confirmationModal: false,
			scoreId: null,
			courses: [],
      	home_courses: [],
      	auto_score_type: false,
			errors: [],
			agsMax: null,
      	allAgsMax: null,
			golfer: null,
			selectedCourse: null,
			loading: false,
      	outdatedScoreModalOpen: false,
			scoreReviewMessage: '',
			modalReviewQuestion: '',
			modalReviewBodyQuestion: '',
			emptyHoles: {
				holes: {
					hole1: '',
					hole2: '',
					hole3: '',
					hole4: '',
					hole5: '',
					hole6: '',
					hole7: '',
					hole8: '',
					hole9: '',
					hole10: '',
					hole11: '',
					hole12: '',
					hole13: '',
					hole14: '',
					hole15: '',
					hole16: '',
					hole17: '',
					hole18: ''
				},
				ags: {
					hole1: '',
					hole2: '',
					hole3: '',
					hole4: '',
					hole5: '',
					hole6: '',
					hole7: '',
					hole8: '',
					hole9: '',
					hole10: '',
					hole11: '',
					hole12: '',
					hole13: '',
					hole14: '',
					hole15: '',
					hole16: '',
					hole17: '',
					hole18: ''
				}
			}
		};
		this.getCoursesOptions = this.getCoursesOptions.bind(this);
		this.getHomeCourses = this.getHomeCourses.bind(this);
	}

	onSubmit(values) {
   	let has_x_value = false;
		let holes = [];

		for (let i = 1; i < 19; i++) {
			let hole = values.holes['hole' + i];

			if (hole === undefined) {
				holes.push({});
			}
			else if (this.customParseInt(hole) === 'X') {
				if(this.props.golfer.use_scaling){
					this.setState({errors: ['X is not valid score']})
					return
				}
				has_x_value = true;
				holes.push({hole_number: i, x_hole: true, raw_score: 10});
			}
			else if (hole[0] === 'X') {
				if (!this.props.golfer.use_scaling) {
					has_x_value = true;
				}
				holes.push({hole_number: i, most_likely_score: this.customParseInt(hole), raw_score: this.customParseInt(hole)});
			}
			else if (hole === '' && this.props.golfer.use_scaling) {
				has_x_value = true;
				holes.push({hole_number: i, x_hole: true, raw_score: 0});
			}
			else 
			{
				holes.push({hole_number: i, raw_score: parseInt(hole)});
			}
		}

		const empty_holes_count = holes.filter( h => h.x_hole).length
		if(this.state.golfer.handicap_index === "NH" && has_x_value && empty_holes_count < 9) {
			this.setState({ no_hi_x_score_modal_is_open: true });
			return;
		}

		let scoreType = this.state.scoreType === 'home' ? 'H' : (this.state.scoreType === 'away' ? 'A' : 'T');
		let data = {};

		if (this.state.method === 'home-course') {
			if (this.state.nrHoles === "9") {
				if (values.course_name.tee_set_side === "F9") {
					holes = holes.slice(0, 9);
				} else {
					holes = holes.slice(9, 18);
				}
			}

			data = {
				golfer_id: this.props.golfer.id,
				course_id: values.course_name.courseId,
				tee_set_id: values.course_name.teeSetID,
				tee_set_side: parseInt(this.state.nrHoles) === 9 ? values.course_name.tee_set_side : 'All18',
				played_at: moment(values.date).format("YYYY-MM-DD"),
				score_type: scoreType,
				hole_details: holes,
				override_confirmation: false,
				is_manual: false,
				number_of_holes: this.state.nrHoles,
				gender: this.props.golfer.gender
			};
		} else {
			if (this.state.nrHoles === "9") {
				if (values.tee_set_id.tee_set_side === "F9") {
					holes = holes.slice(0, 9);
				} else {
					holes = holes.slice(9, 18);
				}
			}

			data = {
				golfer_id: this.props.golfer.id,
				course_id: values.course_name.value,
				tee_set_id: values.tee_set_id.value,
				tee_set_side: parseInt(this.state.nrHoles) === 9 ? values.tee_set_id.tee_set_side : 'All18',
				played_at: moment(values.date).format("YYYY-MM-DD"),
				score_type: scoreType,
				hole_details: holes,
				override_confirmation: false,
				is_manual: false,
				number_of_holes: this.state.nrHoles,
				gender: this.props.golfer.gender
			};
		}

		return ScoreService.postHoleByHole(data)
			.then(response => {
				if (response.score.status === "UnderReview") {
					let question = '';
					// let bodyQuestion = '';
					if(response.score.validation_message.includes('This is possibly an incomplete and duplicate score.') || 
					   response.score.validation_message.includes('This is possibly an incomplete score.')) {
						question = 'Action Required'
					}
					else if(response.score.validation_message.includes('This is possibly a duplicate score.')) {
						if(this.props.golfer.use_scaling) {
							question = 'Action Required'
						} else {
							question = 'Duplicate Score Found'
						}
					}

					this.setState({
						confirmationModal: true,
						scoreId: response.score.id,
						modalReviewQuestion: question,
						modalReviewBodyQuestion: response.score.validation_message_display,
					})
				} else {
					this.clearFields();
					this.setState({
						errors: []
					})
					this.props.addAlert({
						type: 'success',
						message: 'Hole by hole score has been successfully posted'
					});
				}

				this.setState({
					outdatedScoreModalOpen: !response.score.is_recent && response.score.status !== 'UnderReview',
				})
			})
			.catch(err => {
				let errors = [];

				Object.keys(err.data.errors).forEach(error => {
					if (err.data.errors[error].length > 1) {
						err.data.errors[error].forEach(errorChild => {
							errors.push(error.replace(/_/g, ' ') + ' ' + errorChild);
						})
					} else {
						errors.push(err.data.errors[error][0]);
					}
				});

				this.setState({errors})
			});

	}

	confirmScore() {
		ScoreService.confirmScore(this.state.scoreId, this.props.golfer.id)
			.then(res => {
				this.setState({confirmationModal: false, outdatedScoreModalOpen: !res.score.is_recent,}, () => this.clearFields());
        		this.props.addAlert({
					type: 'success',
					message: 'Hole by hole score has been successfully posted'
        		});
			})
			.catch(err => {
				console.error(err);
			})
	}

	getCoursesOptions(searchTerm, callback) {
		if (searchTerm && searchTerm.length > 2) {
			return CourseRatingSystemService.searchCourses(searchTerm)
				.then(response => {
					let options = response.courses.map(c => {
						return {
							value: c.CourseID,
							label: `${c.FullName} ${c.City && c.State ? `(${c.City}, ${c.State})` : ''}`,
							unparsedLabel: c.FullName
						}
					});
					this.setState({
						courses: options
					});
					callback(options)
				})
				.catch(err => {

				})
		}
		return new Promise(resolve => {
			resolve([])
		})

	}

	getHomeCourses() {
		this.setState({
			courses: []
		});
		ClubService.getClubHomeCourses(this.props.match.params.association_id, this.props.match.params.id)
			.then(async response => {
				if (response.facilities.length === 0) {
					/**
					 * Disable home courses if there is no home course defined
					 */
					this.setState({
						method: 'course'
					});
					this.props.change('method', 'course');
				} else {
					let courses = await Promise.all(response.facilities.map(async hc => {
						let courses = await Promise.all(hc.home_courses.map(home_course => {
							let courseName = hc.name === home_course.name ? home_course.name : hc.name + ' ' + home_course.name;
							return {
								req: CourseRatingSystemService.getCourseDetails(home_course.course_id),
								courseName: courseName,
								courseId: home_course.course_id
							};
						}).map(async r => {
							let response = await r.req;
							let filteredTS = response.TeeSets.filter(ts => ts.Gender.charAt(0) === this.state.golfer.gender).map(ts => {
								let total = ts.Ratings.filter(rating => rating.RatingType === "Total")[0];
								let front = ts.Ratings.filter(rating => rating.RatingType === "Front")[0];
								let back = ts.Ratings.filter(rating => rating.RatingType === "Back")[0];
								return {
									label: ts.TeeSetRatingName,
									holes: ts.Holes,
									holesNumber: ts.HolesNumber,
									courseId: r.courseId,
									value: ts.TeeSetRatingId,
									teeSetID: ts.TeeSetRatingId,
									ratingTotal: total && `${parseFloat(total.CourseRating).toFixed(1)} / ${total.SlopeRating}`,
									ratingFront: front &&`${parseFloat(front.CourseRating).toFixed(1)} / ${front.SlopeRating}`,
									ratingBack: back && `${parseFloat(back.CourseRating).toFixed(1)} / ${back.SlopeRating}`,
									ratingTotalValue: total && parseFloat(total.CourseRating).toFixed(1),
								}
							});
							orderTees(filteredTS);
							if (this.state.nrHoles === '18') {
								filteredTS = filteredTS.filter(ts => ts.ratingTotal !== undefined).map(ts => {
									let totalPar = ts.holes.reduce((acc, h, index) => acc + h.Par, 0);
									return {
										label: `${r.courseName} / ${ts.label} (${ts.ratingTotal} / ${totalPar})`,
										value: ts.value,
										holes: ts.holes,
										holesNumber: ts.holesNumber,
										teeSetID: ts.value,
										courseId: ts.courseId
									}
								}).filter(ts => ts.holesNumber !== 9);
							} else {
								filteredTS = filteredTS.reduce((res, current) => {
									let frontPar = current.holes.reduce((acc, h, index) => {
										if (index < 9) return acc + h.Par;
										return acc + 0;
									}, 0);
									let backPar = current.holes.reduce((acc, h, index) => {
										if (index >= 9) return acc + h.Par;
										return acc + 0;
									}, 0);
									let front = current.ratingFront ? {
										label: `${r.courseName} / ` + current.label + ` F9 (${current.ratingFront} / ${frontPar})`,
										unparsedLabel: current.label + ' F9',
										value: current.value,
										courseId: current.courseId,
										tee_set_side: 'F9',
										holes: current.holes,
										holesNumber: current.holesNumber,
										teeSetID: current.teeSetID
									} : null;
									let back = current.ratingBack ? {
										label: `${r.courseName} / ` + current.label + ` B9 (${current.ratingBack} / ${backPar})`,
										unparsedLabel: current.label + ' B9',
										value: current.value,
										courseId: current.courseId,
										tee_set_side: 'B9',
										holesNumber: current.holesNumber,
										holes: current.holes,
										teeSetID: current.teeSetID
									} : null;
									if(front && back) {
										return res.concat([front, back])
									}
									if (front) {
										return res.concat([front]);
									}
									if (back) {
										return res.concat([back]);
									}
									return null;
								}, []);
							}

							return filteredTS;
						}));

						return flatten(courses);

					}));

          if (this.state.home_courses.length === 0)
            this.setState({
              home_courses: flatten(courses).map(el => el.courseId)
            });

					if (this.state.scoreType === 'home' || this.state.scoreType === 'competition') {
						this.setState({
							courses: flatten(courses)
						})
					}
				}
			})
			.catch(err => {
				console.error(err);
			})
	}

	componentDidMount() {

		this.setState({
			golfer: this.props.golfer
		}, () => {
			this.getHomeCourses();
			this.props.initialize({
				date: new Date(),
				holes: this.state.emptyHoles.holes,
				ags: this.state.emptyHoles.ags
			});
		});
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	clearFields() {
		let values = this.props.formValues;
		this.setState({
			emptyHoles: {
				holes: {
					hole1: '',
					hole2: '',
					hole3: '',
					hole4: '',
					hole5: '',
					hole6: '',
					hole7: '',
					hole8: '',
					hole9: '',
					hole10: '',
					hole11: '',
					hole12: '',
					hole13: '',
					hole14: '',
					hole15: '',
					hole16: '',
					hole17: '',
					hole18: ''
				},
				ags: {
					hole1: '',
					hole2: '',
					hole3: '',
					hole4: '',
					hole5: '',
					hole6: '',
					hole7: '',
					hole8: '',
					hole9: '',
					hole10: '',
					hole11: '',
					hole12: '',
					hole13: '',
					hole14: '',
					hole15: '',
					hole16: '',
					hole17: '',
					hole18: ''
				}
			}
		}, () => {

			values.holes = this.state.emptyHoles.holes;
			values.ags = this.state.emptyHoles.ags;
			this.props.initialize(values);
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.scoreType !== prevState.scoreType) {
      if (!this.state.auto_score_type) {
		  if ((this.state.scoreType === 'away' && prevState.scoreType === 'home') ||
			  (this.state.scoreType === 'home' && prevState.scoreType === 'away')) {
			  this.props.change('course_name', null);
			  this.props.untouch('course_name');
			  this.props.change('tee_set_id', null);
			  this.props.untouch('tee_set_id');
		  }
        this.setState({
          errors: []
        })
      }
      else {
        this.setState({
          auto_score_type: false
        });
      }
		}
		if (this.state.method !== prevState.method) {
			this.setState({
				courses: [],
				errors: []
			});
			if (this.state.method === 'home-course') this.getHomeCourses();
		}
		if (this.state.nrHoles !== prevState.nrHoles) {
			this.setState({
				errors: []
			});
			if (this.state.method === 'home-course') this.getHomeCourses();
		}
	}

	returnTotalValueForScaling(array, what, i)
	{
		if(what === 'hole' || what === 'holes.hole') {
			if(array[what + i]) {
				if(isNaN(this.customParseInt(array[what + i]))) {
					return 0
				}
				else {
					return this.customParseInt(array[what + i])
				}
			}
			else {
				return 0
			}
		}
		else {
			return array[i] ? array[i][what] : 0;
		}
	}

	returnTotal(array, what, from, to) {
		let total = 0;

		if (from === 0 || from === 1) {
			for (let i = from; i <= from + 8; i++) {
				let hole_value = 0;

				if(this.state.golfer.use_scaling === true) {
					hole_value = this.returnTotalValueForScaling(array, what, i)
				}
				else {
					hole_value = what === 'hole' || what === 'holes.hole' ? (array[what + i] ? 
						(isNaN(this.customParseInt(array[what + i])) ? (this.state.selectedCourse.tee_set_side === 'B9' ? this.state.allAgsMax[i-1].net_par : this.state.agsMax[i-1].net_par) : this.customParseInt(array[what + i])) : 0) : array[i] ? array[i][what] : 0;
				}

				total += hole_value;
			}
		}
		if (to === 17 || to === 18) {
			for (let i = to - 8; i <= to; i++) {
				let hole_value = 0;

				if(this.state.golfer.use_scaling === true) {
					hole_value = this.returnTotalValueForScaling(array, what, i)
				}
				else {
					hole_value = what === 'hole' || what === 'holes.hole' ? (array[what + i] ? 
						(isNaN(this.customParseInt(array[what + i])) ? (this.state.selectedCourse.tee_set_side === 'B9' ? this.state.agsMax[i-10].net_par : this.state.allAgsMax[i-10].net_par) : this.customParseInt(array[what + i])) : 0) : array[i] ? array[i][what] : 0;
				}

				total += hole_value;
			}
		}

		if (total === 0) {
			return ''
		} else {
			return total;
		}
	}

	generateCells(side, type) {

		let holes = this.state.selectedCourse.holes;

		let a = [];
		if (side === 'front') {
			a = [1, 2, 3, 4, 5, 6, 7, 8, 9];
		} else {
			a = [10, 11, 12, 13, 14, 15, 16, 17, 18];
		}

		return a.map(i => {
			if (type) {
				if (type === 'yards') return <div key={i}
					className="hbh_cell">{holes[i - 1] && holes[i - 1].Length}</div>;
				if (type === 'par') return <div key={i}
					className="hbh_cell">{holes[i - 1] && holes[i - 1].Par}</div>;
				if (type === 'stroke') return <div key={i}
					className="hbh_cell">{holes[i - 1] && holes[i - 1].Allocation}</div>;
				if (type === 'hole') return (
					<div key={i} className="hbh_cell">
						<Field component={InputField}
							   type="text"
							   name={`holes.hole${i}`}
							   disabled={side === 'front' ? (this.state.nrHoles === '9' && this.state.selectedCourse.tee_set_side === "B9") : (this.state.nrHoles === '9' && this.state.selectedCourse.tee_set_side === "F9")}
							   onChange={(e) => {
									if( !/^[xX]?[0-9]*$/.test(e.target.value) || this.customParseInt(e.target.value) === 0) {
										e.preventDefault();
									} else {
										let index = this.state.selectedCourse.tee_set_side === "B9" ? 11 : 2
										this.checkValue(e, i + 1, index)
									}
								}}
                 			normalize={value => value.toUpperCase()}
							   className={`hbh-score-input`}
							   id={`holes.hole${i}`}/>
					</div>);
				if (type === 'ags') return <div key={i}
					className="hbh_cell">{this.props.formValues.ags['hole' + i]}</div>;
				return null;
			} else {
				return <div key={i} className="hbh_cell">{i}</div>;
			}
		});

	}

	getTeeAGS(tee_set_id, date, tee_set_side) {
		let params = {golfer_id: this.state.golfer.id, tee_set_id: tee_set_id, include_net_pars: true, played_at: moment(date).format('YYYY-MM-DD')}
		ClubService.getTeeAGS(params, tee_set_side)
			.then(response => {
				if (response.maximum_scores && response.maximum_scores.length > 0) {
					this.setState({agsMax: response.maximum_scores, errors: []}, () => {
						let ags = {};
						let holes = this.props.formValues.holes;
						response.maximum_scores.forEach(hole => {
							if (holes['hole' + hole.hole_number] !== "") {
                if (holes['hole' + hole.hole_number] === 'X')
                  ags['hole' + hole.hole_number] = hole.net_par;
                else
                  ags['hole' + hole.hole_number] = Math.min(this.customParseInt(holes['hole' + hole.hole_number]), hole.maximum_score);
              }
							}
						);
						this.props.change('ags', ags);
						this.setState({loading: false});
					});
				}
			})
			.catch(err => {
				let errors = [];

				Object.keys(err.data.errors).forEach(error => {
					if (err.data.errors[error].length > 1) {
						err.data.errors[error].forEach(errorChild => {
							errors.push(error.replace(/_/g, ' ') + ' ' + errorChild);
						})
					} else {
						errors.push(err.data.errors[error][0]);
					}
				});

				this.setState({errors, loading: false})
			});

    ClubService.getTeeAGS(params)
      .then(res => {
        this.setState({
          allAgsMax: res.maximum_scores
        })
      })
      .catch(err => {
        console.log(err);
      })
	}

  //function to parseInt considering that the first character could be an X
  customParseInt(value) {
    if (value === 'x' || value === 'X') {
      return 'X';
    }
    if (value[0] === 'x' || value[0] === 'X') {
      value = value.substring(1);
    }
    return parseInt(value);
  }

	checkValue(e, target, index) {
		let value = e.target && e.target.value;
		let name = e.target && e.target.name;
		let reg = /^[xX]?\d*$/;
		let holes = this.props.formValues.holes;
		let ags = this.props.formValues.ags;
		let nextTarget = null;

		// Clear previous shown errors
		this.setState({
			errors: []
		})

		/// Remove holes. form input name
		if (name.indexOf('holes.') !== -1) {
			name = name.slice(6);
		}

		// add values
		if (!isNaN(this.customParseInt(value))) {
			if (this.customParseInt(value) < 30) {
				holes[name] = this.customParseInt(value);
				if (this.customParseInt(value) !== 0) 
          		ags[name] = Math.min(this.customParseInt(value), this.state.agsMax[target - index]['maximum_score']);
				if (!ags[name]) {
					ags[name] = ''
				}
			}
		} 
		else if (this.customParseInt(value) === 'X') {
			holes[name] = 'X';

			if (!this.state.golfer.use_scaling) {
				ags[name] = this.state.agsMax[target - index]['net_par'];
			}
		}
		else {
			holes[name] = '';
			ags[name] = '';
		}

		// check if the target has value.. else focus on the next empty input
		if (holes['hole' + target]) {
			nextTarget = Object.keys(holes).find(hole => {
				return (!holes[hole] && hole.slice(4) > target) ? hole : null
			});
			if (nextTarget) {
				nextTarget = nextTarget.slice(4);
			} else {
				nextTarget = 20;
			}
		} else {
			nextTarget = target;
		}

		/// if input is between 1-19 focus next or if is last field from table blur...
		if ((reg.test(value) || !value) && this.customParseInt(value) !== 0 && (isNaN(this.customParseInt(value)) || this.customParseInt(value) < 30)) {
      if (this.customParseInt(value) !== 1 && !isNaN(this.customParseInt(value)) && target) {
				if (!this.props.formValues.holes['hole' + nextTarget]) {
					switch (nextTarget) {
						case 10:
							if (this.state.nrHoles === '9' && this.state.selectedCourse.tee_set_side === "F9") {
								this.blurInput(9);
							} else {
								this.focusInput(nextTarget);
							}
							break;

						case 19:
							this.blurInput(18);
							break;
						case 20:
							//if nextTarget is 20, nothing happens and the focus remains on the current hole
							break;
						default:
							this.focusInput(nextTarget);
							break;
					}
				} else {
					if (!isNaN(this.customParseInt(value))) {
						this.blurInput(target - 1);
					}
				}
			}
		} else {
			// if !parseInt(value) prevent typing
			e.preventDefault();
		}
		this.props.change({holes: holes});
		this.props.change({ags: ags});
		this.forceUpdate();
	}


	focusInput(target) {
		document.getElementsByName('holes.hole' + target)[0].focus();
	}

	blurInput(target) {
		document.getElementsByName('holes.hole' + target)[0].blur();
	}

	clearHoles() {
		this.setState({agsMax: null, errors: [], emptyHoles: {
				holes: {
					hole1: '',
					hole2: '',
					hole3: '',
					hole4: '',
					hole5: '',
					hole6: '',
					hole7: '',
					hole8: '',
					hole9: '',
					hole10: '',
					hole11: '',
					hole12: '',
					hole13: '',
					hole14: '',
					hole15: '',
					hole16: '',
					hole17: '',
					hole18: ''
				},
				ags: {
					hole1: '',
					hole2: '',
					hole3: '',
					hole4: '',
					hole5: '',
					hole6: '',
					hole7: '',
					hole8: '',
					hole9: '',
					hole10: '',
					hole11: '',
					hole12: '',
					hole13: '',
					hole14: '',
					hole15: '',
					hole16: '',
					hole17: '',
					hole18: ''
				}
			}
		}, () => {
			this.props.change('holes', this.state.emptyHoles.holes);
			this.props.change('ags', this.state.emptyHoles.ags)
		});
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;

		let checked = this.state.selectedCourse ? true : false;
		if (this.state.selectedCourse) {
      if (this.state.selectedCourse.tee_set_side) {
        for (let i = (this.state.selectedCourse.tee_set_side === "F9" ? 1 : 10); i <= (this.state.selectedCourse.tee_set_side === "F9" ? 9 : 18); i++) {
          if (this.props.formValues.holes['hole' + i] === '') {
            checked = false;
          }
        }
      }
      else {
				const hasHandicapIndex = this.props.golfer.handicap_index !== "NH"
				if(this.props.golfer.use_scaling && hasHandicapIndex) {
					checked = checkHoles(this.props.formValues.holes);
				}
				else {
					for (let i = 1; i <= 18; ++i) {
						if (this.props.formValues.holes['hole' + i] === '') {
							checked = false;
						}
					}
				}
      }
    }

		let self = this;


		return (
			<Fragment>
      <ConfirmationModalTypeB
        wideClass={'narrow'}
        openModal={this.state.outdatedScoreModalOpen && !this.state.confirmationModal && !this.props.golfer.use_scaling}
        onConfirmAction={()=>{
          this.setState({
            outdatedScoreModalOpen: false,
          })
        }}
        closeModal={()=>{
          this.setState({
            outdatedScoreModalOpen: false,
          })
        }}
        infoText={["This 9-hole score is older than the twentieth oldest 18-hole score in " +
                   "the golfer's scoring record and will not be combined. The score has been " +
                   "posted successfully, but will not display in the scoring record and will " +
                   "not be considered when calculating the golfer's Handicap Index.",
                   "For historical purposes, the score can be found in the Audit Logs."]}
        confirmLabel={"OK"}
        modalIcon={"check_circle"}
      />
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit.bind(this))} ref={'form'}>
					<div className="row">
						<div className="col">
							<div className="panel">
								<div className="panel__body">
									<div className="row with-padding-vertical ">
										<div className="col  is-1-of-6">
											<label htmlFor="date">Date Played <span>*</span></label>
											<Field maxDate={new Date()}
												   minDate={new Date('1/1/1990')}
												   className={'date'}
												   showMonthDropdown
												   showYearDropdown
												   value={new Date()}
												   name={'date'}
												   id={'date'}
												   component={DatePickerInput}
												   placeholder="Select ..."
													 onChange={(newValue)=>{
														 if(this.state.selectedCourse != null) {
															 this.setState({loading: true})
															 this.getTeeAGS(this.state.selectedCourse.value,newValue, this.state.selectedCourse.tee_set_side)
														 }
													 }} />
										</div>
										<div className="col  is-3-of-6">
											<label htmlFor="date">Score Type</label>
											<div className="custom-radio__container">
												<Field
													onClick={
														() => {
															this.setState({
																scoreType: 'home',
																method: 'home-course'
															});
															this.props.change('scoreType', 'home');
															this.props.change('method', 'home-course')
														}
													}
													checked={this.state.scoreType === "home"}
													id="home"
													name={'home'}
													component={'input'}
													type="radio"
												/>
												<label htmlFor="home">Home</label>

												<Field
													onClick={
														() => {
                              // If we are coming with a 'course' lookup method,
                              // Do not destroy the existing information about course.
                              if (this.state.method === 'course') {
                                this.setState({
                                  scoreType: 'away',
                                });
                                this.props.change('scoreType', 'away');
                              } else {
                                // If we are coming from a 'home-course'
                                // Destroy any potential trash information about teeSets
                                this.setState({
                                  scoreType: 'away',
                                  method: 'course',
                                  course: null,
                                  teeSetsOptions: []
                                });
                                this.props.change('course_name', null);
                                this.props.change('scoreType', 'away');
                                this.props.change('method', 'course')
                                this.props.change('tee_set_id', null);
                                this.props.untouch('tee_set_id');
                                this.props.untouch('course_name');
                              }
														}
													}
													checked={this.state.scoreType === 'away'}
													id='away'
													name={'away'}
													component={'input'}
													type="radio"
												/>
												<label htmlFor="away">Away</label>

												<Field
													onClick={
														() => {
															this.setState({
																scoreType: "competition",
															});
															this.props.change('scoreType', "competition");
														}
													}
													checked={this.state.scoreType === "competition"}
													id="competition"
													name={"competition"}
													component={'input'}
													type="radio"
												/>
												<label htmlFor="competition">Competition</label>

											</div>
										</div>

										<div className="col  is-1-of-6">
											<label htmlFor="date">Holes</label>
											<div className="custom-radio__container">
												<Field
													onClick={
														() => {
															this.setState({
																nrHoles: '18',
																selectedCourse: null,
																agsMax: null,
                                teeSetsOptions: []
															});
															this.props.change('nrHoles', '18');
															this.props.change('course_name', null);
															this.props.change('tee_set_id', null);
                              this.props.untouch('tee_set_id')
                              this.props.untouch('course_name')
														}
													}
													checked={this.state.nrHoles === "18"}
													id="18"
													name={'18'}
													component={'input'}
													type="radio"
												/>
												<label htmlFor="18">18</label>

												<Field
													onClick={
														() => {
															this.setState({
																nrHoles: '9',
																selectedCourse: null,
																agsMax: null,
                                teeSetsOptions: []
															});
															this.props.change('nrHoles', '9');
															this.props.change('course_name', null);
															this.props.change('tee_set_id', null);
                              this.props.untouch('tee_set_id')
                              this.props.untouch('course_name')
														}
													}
													checked={this.state.nrHoles === '9'}
													id="9"
													name={'9'}
													component={'input'}
													type="radio"
												/>
												<label htmlFor="9">9</label>
											</div>
										</div>

									</div>

									<div className="row with-padding-vertical ">
										<div className="col  is-1-of-2">
											<label htmlFor="date">Choose Course/Tee Lookup Method:</label>
											<div className="radio__container">
												<Field
													onClick={
														() => {
															this.setState({
																method: 'home-course',
																selectedCourse: null
															})
															this.props.change('method', 'home-course');
                              this.props.change('course_name', null);
															this.props.untouch('course_name');
														}
													}
													checked={this.state.method === 'home-course'}
													id='home-course'
													name={'home-course'}
													component={'input'}
													type="radio"
													className="simple__radio"
												/>
												<label htmlFor="home-course">Home Courses/Tees</label>

												<Field
													onClick={
														() => {
															this.setState({
																method: 'course',
																selectedCourse: null,
                                teeSetsOptions: []
															})
															this.props.change('method', 'course');
															this.props.change('course_name', null);
															this.props.untouch('course_name');
                              this.props.change('tee_set_id', null);
                              this.props.untouch('tee_set_id');
														}
													}
													checked={this.state.method === 'course'}
													id='course'
													name={'course'}
													component={'input'}
													type="radio"
													className="simple__radio"
												/>
												<label htmlFor="course">Course/Tee Search</label>
											</div>
										</div>


										{this.state.method === "home-course" ?
											<div className="col is-1-of-2">

												<Fragment>
													<label htmlFor={'course_name'}>Course/Tee Played</label>
													<Field name={'course_name'}
														   id={'course_name'}
														   onChange={(e) => {
															   if(e.holesNumber === 9) {this.props.change('nrHoles', '9');}
															   this.setState({
																   selectedCourse: {...e, ...{tee_set_side: e.holesNumber === 9 ? 'F9': e.tee_set_side}},
																   nrHoles: e.holesNumber === 9 ? e.holesNumber?.toString() : this.state.nrHoles,
																	 loading: true
																   });

															   this.getTeeAGS(e.teeSetID, this.props.formValues.date, e.tee_set_side);
														   }}
														   component={SelectInput}
														   className="react-select-container"
														   classNamePrefix="react-select"
														   options={this.state.courses}/>
												</Fragment>
											</div>
											:
											<Fragment>
												<div className="col is-2-of-6">
													<label htmlFor={'tee'}>Course Played <span>*</span></label>
													<Field name={'course_name'}
														   id={'course_name'}
														   validate={[requiredSelect]}
														   defaultOptions={this.state.courses}
														   loadOptions={debounce(self.getCoursesOptions, 1000)}
														   component={AsyncSelectInput}
														   onChange={(event, newValue, prevValue) => {
															   if (newValue) {
                                  if (this.state.home_courses.includes(newValue.value)) {
                                    if (this.state.scoreType === 'away') {
                                      this.setState({
                                        scoreType: 'home',
                                        auto_score_type: true
                                      });
                                      this.props.change('scoreType', 'home');
                                    }
                                  }
                                  else {
                                    if (this.state.scoreType === 'home') {
                                      this.setState({
                                        scoreType: 'away',
                                        auto_score_type: true
                                      });
                                      this.props.change('scoreType', 'away');
                                    }
                                  }
																   this.props.change('course_name.courseId', newValue);
																   CourseRatingSystemService.getCourseDetails(newValue.value)
																	   .then(response => {
																		   let filteredTS = response.TeeSets.filter(ts => ts.Gender.charAt(0) === this.state.golfer.gender).map(ts => {
																			    let total = ts.Ratings.filter(rating => rating.RatingType === "Total")[0];
																				let front = ts.Ratings.filter(rating => rating.RatingType === "Front")[0];
																				let back = ts.Ratings.filter(rating => rating.RatingType === "Back")[0];
																			   return {
																				   label: ts.TeeSetRatingName,
																				   holesNumber: ts.HolesNumber,
																				   holes: ts.Holes,
																				   value: ts.TeeSetRatingId,
																				   ratingTotal: total && `${parseFloat(total.CourseRating).toFixed(1)} / ${total.SlopeRating}`,
																				   ratingFront: front && `${parseFloat(front.CourseRating).toFixed(1)} / ${front.SlopeRating}`,
																				   ratingBack: back && `${parseFloat(back.CourseRating).toFixed(1)} / ${back.SlopeRating}`,
																				   ratingTotalValue: total && parseFloat(total.CourseRating).toFixed(1),
																			   }
																		   });
																		   orderTees(filteredTS);
																		   if (this.state.nrHoles !== "18") {
																			   filteredTS = filteredTS.reduce((res, current) => {
																				   let frontPar = current.holes.reduce((acc, h, index) => {
																					   if (index < 9) return acc + h.Par;
																					   return acc + 0;
																				   }, 0);
																				   let backPar = current.holes.reduce((acc, h, index) => {
																					   if (index >= 9) return acc + h.Par;
																					   return acc + 0;
																				   }, 0);
																				   let front = current.ratingFront ? {
																					   label: current.label + " F9 \n" + `(${current.ratingFront} / ${frontPar})`,
																					   unparsedLabel: current.label + ' F9',
																					   holes: current.holes,
																					   holesNumber: current.holesNumber,
																					   value: current.value,
																					   tee_set_side: 'F9'
																				   } : null;
																				   let back = current.ratingBack ? {
																					   label: current.label + " B9 \n" + `(${current.ratingBack} / ${backPar})`,
																					   unparsedLabel: current.label + ' B9',
																					   value: current.value,
																					   holes: current.holes,
																					   holesNumber: current.holesNumber,
																					   tee_set_side: 'B9'
																				   } : null;
																				   if(front && back) {
																					   return res.concat([front, back])
																				   }
																				   if (front) {
																					   return res.concat([front]);
																				   }
																				   if (back) {
																					   return res.concat([back]);
																				   }
																					return null;
																			   }, []);
																		   } else {
																			   filteredTS = filteredTS.filter(ts => ts.ratingTotal !== undefined).map(ts => {
																				   let totalPar = ts.holes.reduce((acc, h, index) => acc + h.Par, 0);
																				   let label = ts.label + "\n" + `(${ts.ratingTotal} / ${totalPar})`;
																				   return {
																					   label: label,
																					   value: ts.value,
																					   holesNumber: ts.holesNumber,
																					   holes: ts.holes
																				   }
																			   }).filter(ts => ts.holesNumber !== 9);
																		   }
																		   this.props.change('tee_set_id', null);

																		   if (filteredTS.length > 0) {
																			   this.setState({
																				   teeSetsOptions: filteredTS,
																				   agsMax: null
																			   })
																		   } else {
																			   this.setState({
																				   teeSetsOptions: filteredTS,
																				   agsMax: null
																			   })
																		   }
																	   })
															   } else {
																   this.props.change('tee_set_id', null);
																   this.setState({
																	   teeSetsOptions: [],
																   });
                                   if (this.state.scoreType === 'home') {
                                     this.setState({
                                       scoreType: 'away'
                                     });
                                     this.props.change('scoreType', 'away');
                                   }
															   }
															   this.clearHoles();
														   }}
														   className="react-select-container"
														   classNamePrefix="react-select"
															 placeholder="Enter Course Name"
															 grayPlaceholder={true}
													/>
												</div>
												<div className="col is-1-of-6">
													<label htmlFor={'tee'}>Tee (C.R. / Slope /
														Par) <span>*</span></label>
													<Field name={'tee_set_id'}
														   id={'tee_set_id'}
														   validate={[requiredSelect]}
														   onChange={(e) => {
															   if(e.holesNumber === 9) {this.props.change('nrHoles', '9');}
															   this.setState({
																   selectedCourse: {
																	   tee_set_side: e.holesNumber === 9 ? 'F9': e.tee_set_side,
																	   value: e.value,
																	   holes: e.holes,

																   },
																   nrHoles: e.holesNumber === 9 ? e.holesNumber?.toString() : this.state.nrHoles,
																	 loading: true,
																   errors: [],
															   }, () => {
															   	this.props.change('tee_set_id', {...e, ...{tee_set_side: e.holesNumber === 9 ? 'F9': e.tee_set_side}})
																   this.getTeeAGS(e.value, this.props.formValues.date, e.tee_set_side);
															   })
														   }}
														   options={this.state.teeSetsOptions}
														   component={SelectInput}
														   className="react-select-container"
														   classNamePrefix="react-select"
													/>
												</div>
											</Fragment>}

									</div>
									{!this.state.loading ?
									((this.state.selectedCourse && this.state.agsMax && this.state.allAgsMax) &&
                  <>
                    <div className="row">
                      <div className="col">
                        <ul className="table__legend">
                          <li>
                            { !this.state?.golfer?.use_scaling && 'Hole Is Not Played: Enter X |'} Hole Not Completed: Enter X + Most Likely Score (ex: X5)
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className={`row ${this.state.nrHoles === '9'  && this.state.selectedCourse.tee_set_side === "B9" ? "align-right" : " "}`}>
                      <Fragment>
                        <div className={`hbh-container ${(this.state.nrHoles === '9' && this.state.selectedCourse.tee_set_side === "B9") ? 'disabled' : ''}`}>

                          <div className="hbh">

                            <Fragment>
                              <div className="hbh_row hole">
                                <div className="hbh_cell label"></div>
                                {this.generateCells("front")}
                                <div className="hbh_cell">OUT</div>
                              </div>

                              <div className="hbh_row yards">
                                <div className="hbh_cell label">Yards</div>
                                {this.generateCells("front", 'yards')}
                                <div
                                  className="hbh_cell">{this.returnTotal(this.state.selectedCourse.holes, 'Length', "front" === 'front' ? 0 : 9, "front" === 'front' ? 8 : 17)}</div>
                              </div>

                              <div className="hbh_row par">
                                <div className="hbh_cell label">Par</div>
                                {this.generateCells("front", 'par')}
                                <div
                                  className="hbh_cell">{this.returnTotal(this.state.selectedCourse.holes, 'Par', "front" === 'front' ? 0 : 9, "front" === 'front' ? 8 : 17)}</div>
                              </div>

                              <div className="hbh_row">
                                <div className="hbh_cell label">Stroke Index</div>
                                {this.generateCells('front', 'stroke')}
                                <div className="hbh_cell"></div>
                              </div>

                              <div className="hbh_row score">
                                <div className="hbh_cell label">Score</div>
                                {this.generateCells("front", 'hole')}
                                <div
                                  className="hbh_cell gray">{this.returnTotal(this.props.formValues.holes, 'hole', "front" === 'front' ? 1 : 10, "front" === 'front' ? 9 : 18)}</div>
                              </div>

                              <div className="hbh_row esc_score ">
                                <div className="hbh_cell label ">Adj. Score</div>
                                {this.generateCells("front", 'ags')}
                                <div
                                  className="hbh_cell">{this.returnTotal(this.props.formValues.ags, 'hole', 1, 9)}</div>
                              </div>

                            </Fragment>

                          </div>
                        </div>

                          <div className={`hbh-container ${(this.state.nrHoles === '9' && this.state.selectedCourse.tee_set_side === "F9") ? 'disabled' : ''}`}>

                          <div className="hbh">

                            <Fragment>
                              <div className="hbh_row hole">
                                <div className="hbh_cell label"></div>
                                {this.generateCells("back")}
                                <div className="hbh_cell">IN</div>
                                <div className="hbh_cell">TOT</div>
                              </div>

                              <div className="hbh_row yards">
                                <div className="hbh_cell label">Yards</div>
                                {this.generateCells("back", 'yards')}
                                <div
                                  className="hbh_cell">{this.returnTotal(this.state.selectedCourse.holes, 'Length', "back" === 'front' ? 0 : 9, "back" === 'front' ? 8 : 17)}</div>
                                <div
                                  className="hbh_cell">{this.returnTotal(this.state.selectedCourse.holes, 'Length', 0, 17)}</div>
                              </div>

                              <div className="hbh_row par">
                                <div className="hbh_cell label">Par</div>
                                {this.generateCells("back", 'par')}
                                <div
                                  className="hbh_cell">{this.returnTotal(this.state.selectedCourse.holes, 'Par', "back" === 'front' ? 0 : 9, "back" === 'front' ? 8 : 17)}</div>
                                <div
                                  className="hbh_cell">{this.returnTotal(this.state.selectedCourse.holes, 'Par', 0, 17)}</div>
                              </div>
                              <div className="hbh_row">
                                <div className="hbh_cell label">Stroke Index</div>
                                {this.generateCells('back', 'stroke')}
                                <div className="hbh_cell"></div>
                                <div className="hbh_cell"></div>
                              </div>
                              <div className="hbh_row score">
                                <div className="hbh_cell label">Score</div>
                                {this.generateCells("back", 'hole')}
                                <div
                                  className="hbh_cell gray">{this.returnTotal(this.props.formValues.holes, 'hole', "back" === 'front' ? 1 : 10, "back" === 'front' ? 9 : 18)}</div>
                                <div
                                  className="hbh_cell gray">{this.returnTotal(this.props.formValues.holes, 'hole', 1, 18)}</div>
                              </div>

                              <div className="hbh_row esc_score ">
                                <div className="hbh_cell label ">Adj. Score</div>
                                {this.generateCells("back", 'ags')}
                                <div
                                  className="hbh_cell">{this.returnTotal(this.props.formValues.ags, 'hole', 10, 18)}</div>
                                <div
                                  className="hbh_cell">{this.returnTotal(this.props.formValues.ags, 'hole', 1, 18)}</div>
                              </div>

                              </Fragment>

                          </div>
                        </div>
                      </Fragment>
                    </div>
                  </>
									)
									:
									<table style={{width: '100%'}}>
										<tbody >
										<tr>
											<td style={{textAlign: 'center'}}>
												 <span className="data-table__loader"></span>
											</td>
										</tr>
										</tbody>
									</table>}
									{this.state.errors.length > 0 &&
									<p>
										{this.state.errors.map(error => {
											return (<span
												className={'validation__message is-error'}>{error.charAt(0).toUpperCase() + error.substring(1)}</span>);
										})}
									</p>
									}

									<div className="row with-padding-vertical">
										<div className="col is-1-of-6 push-right">
											<button disabled={pristine || submitting || !checked} type={'submit'}
													ref={'submit'} className="btn fill blue">Post Score
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>

				<ConfirmationModalTypeB
					openModal={this.state.confirmationModal}
					onConfirmAction={() => this.confirmScore()}
					modalIcon={'help_outline'}
					onCancelAction={() => {
						this.setState({confirmationModal: false})
					}}
					closeModal={() => {
						this.setState({confirmationModal: false})
					}}

					question = {this.state.modalReviewQuestion}
					bodyQuestion = {this.state.modalReviewBodyQuestion}
					infoText={['Press "Continue" to post the score anyway', 'Press "Cancel" to review the score prior to posting']}
					cancelLabel={"Cancel"}
					confirmLabel={"Continue"}
				/>

				<ConfirmationModalTypeB
					openModal={this.state.no_hi_x_score_modal_is_open}
					onConfirmAction={() => {
						this.setState({ no_hi_x_score_modal_is_open: false });
					}}
					closeModal={() => {
						this.setState({ no_hi_x_score_modal_is_open: false });
					}}
					bodyQuestion={
						this.state.golfer && this.state.golfer.use_scaling ?
						'A valid Handicap Index value is required to compute the scaled differential, the golfer with Handicap Index of NH cannot post an incomplete score.' :
						'A valid Handicap Index value is required to compute Net Par, the golfer with Handicap Index of NH cannot post a hole score of "Hole Not Played".'
					}
					confirmLabel={"Ok"}
					modalIcon={"error_outline"}
				/>
			</Fragment>

		);
	}
}

HoleByHoleForm = reduxForm({
	form: 'holeByHoleForm',
	destroyOnUnmount: true
})(HoleByHoleForm);

function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
		formValues: getFormValues('holeByHoleForm')(state),
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withKeyPress(HoleByHoleForm)));
