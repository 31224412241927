import React, {Component, Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {requiredSelect} from "../../inputs/validations";
import GolferService from "../../services/api/golfer";
import {addAlert} from '../shared/actions'
import {SelectInput} from "../../inputs/Select";
import ClubService from "../../services/api/club";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import InfoBox from '../shared/InfoBox';



class ChangeGolferMembershipForm extends Component {

	constructor(props){
		super(props);
		this.state = {
			membershipTypes: [],
			type: 'filtered',
			areLockedMemberships: false
		}
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		this.setState({type: this.props.isAllSelected ? 'filtered' : 'selected'})
		ClubService.getMembershipTypes(this.props.clubId, {include_used_membership: 'false'})
			.then(res => {
				let lockedMemberships = false
				this.setState({membershipTypes: res.membership_types.map(membershipType => {
						if (membershipType.locked)
							lockedMemberships = true;
						return {
							...membershipType,
							...{
								value: membershipType.id,
								label:
									<Fragment>
										<div style={{display: "inline-flex", alignItems: "end"}}>
											<span style={{display: "flex", alignItems: "center"}}>
												<span className="change-membership-dropdown">{membershipType.code + ' - ' + membershipType.description}</span>
												{membershipType.locked && <i className="material-icons-outlined lock-icon-container"/>}
											</span>
										</div>
									</Fragment>,
								locked: membershipType.locked,
								isDisabled: !this.props.canEditAssociation && membershipType.locked
							}
						};
					}).filter(membershipType => membershipType.active !== "false").sort((a, b) => a['label']?.toString().localeCompare(b['label']?.toString()))
				})
				this.setState({areLockedMemberships: lockedMemberships});
			})
			.catch(err => {
				console.error(err);
			})
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onSubmit(values, dispatch, props) {
		let params = {};
		if(this.state.type === "filtered") {
			params = {...params, ...props.filters};
			delete params['page'];
			delete params['per_page'];
		}

		let data = {};

		if (props.isAllSelected) {
			params['select_all'] = true;
			data.unchecked_items = props.golfers
		} else {
			data.golfer_ids = props.golfers;
		}

		return GolferService.setMembershipType(props.clubId, values.membership_type.value, data, params)
			.then(res => {
				props.onSuccess(res);
			})
			.catch(err => {
				console.error(err);
			})
	}


	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		if (this.state.membershipTypes.length === 0) return null;
		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
				{!this.props.canEditAssociation && <div className="row" style={{width: this.state.areLockedMemberships && !this.props.canEditAssociation ? "398px" : ""}}>
	            <InfoBox className="info-box-yellow"
								title = "PLEASE READ: "
								classTitle="color-red font-weight-600"
								message="Membership codes are created by your association for billing and tracking purposes. Please use caution when updating. If you have any questions, please contact your association."
							/>
	        </div>}

					{!this.props.canEditAssociation && <div className="row">
						{this.state.areLockedMemberships && <div className="locked-membership-info-box" style={{top: "111px"}}>
							<div><i className="material-icons-outlined lock-icon-container"/><span style={{fontWeight: '500'}}> Membership Code is Locked </span></div>
							<div style={{marginTop: '10px'}}> Locked Membership codes cannot be manually removed or assigned.</div>
							<div style={{marginTop: '10px'}}> Please contact your association for assistance.</div>
						</div>}
					</div>}

					<span className="label" style={{marginTop: this.props.canEditAssociation ? "" : "-15px"}}>Please choose a new Membership Code:</span>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="club_type">Membership Code</label>
							<Field name={'membership_type'}
								   id={'membership_type'}
								   component={SelectInput}
								   validate={[requiredSelect]}
								   className="react-select-container memberships-select-container"
								   classNamePrefix="react-select"
								   options={this.state.membershipTypes}/>
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} className="btn fill gray" onClick={()=>this.props.close()}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'}  disabled={pristine || submitting} className="btn fill blue">Submit</button>
						</div>
					</div>
				</form>
			</Fragment>
		);
	}
}

ChangeGolferMembershipForm = reduxForm({
	form: 'changeGolferMembershipForm',
	destroyOnUnmount: true
})(ChangeGolferMembershipForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(ChangeGolferMembershipForm));
