import React, {Component, Fragment} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import {withKeyPress} from "../../shared/highOrderComponent/withKeyPress";
import ClubService from "../../../services/api/club";
import GolferService from "../../../services/api/golfer";
import {bindActionCreators} from "redux";
import { formValueSelector, reduxForm, stopAsyncValidation} from "redux-form";
import {connect} from "react-redux";
import AssociationService from "../../../services/api/association";
import ConfirmationModalTypeB from "../../Modals/ConfirmationModalTypeB";
import GhinGuardianFormComponent from "../../shared/GhinGuardianFormComponent";
import {processErrorMessage} from "../../../services/shared/errorHelper";

class AddNewGuardianForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			associations: [],
			clubs: [],
			membershipTypes: [],
			startDate: new Date(),
			isEmailDisabled: false,
			confirmation: false,
			confirmationError: null,
			error: null,
			membershipTypesLoaded: false
		};

		this.onSubmit = this.onSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(date) {
		this.setState({
			startDate: date
		});
	}

	componentDidMount() {
		if(this.props.access.user_type === "club-admin") {
			this.getClubAssociation();
		} else {
			this.getAssociations();
		}
		if (this.props.club) {
			this.getMembershipTypes(this.props.club.id)
		}
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	getMembershipTypes(clubId){
		this.props.change('membership_code', null);
		this.setState({
			membershipTypesLoaded: false
		})
		ClubService.getMembershipTypes(clubId, {include_used_membership: 'false'})
			.then(res => {
				this.setState({
					membershipTypesLoaded: true,
					membershipTypes: res.membership_types.map(membershipType => {
						return {label: membershipType.code + ' - ' + membershipType.description, value: membershipType.id,  type: membershipType.type};
					}).sort((a, b) => a['label']?.toString().localeCompare(b['label']?.toString()))
				});
			})
			.catch(err => {
				this.setState({
					membershipTypesLoaded: true
				});
				console.error(err)
			})
	}

	getClubAssociation() {
		let associations = [{
			label: this.props.access.default_association_name,
			value: this.props.access.default_association_id,
			id: this.props.access.default_association_id
		}];
		this.setState({
				associations
			},() => {
		this.props.change('association', associations[0]);
		this.onAssociationChange(associations[0]);
		});
	}

	getAssociations() {
		ClubService.getGolfAssociations()
			.then(response => {
				let associations = response.associations.map(assoc => {
					assoc.value = assoc.id;
					assoc.label = assoc.name;
					return assoc
				});

				associations.sort((a, b) => a['label']?.toString().localeCompare(b['label']?.toString()));

				this.setState({
					associations
				}, () => {
					if (this.props.association ) {
							let initialAssoc = associations.filter(a => parseInt(a.id) === parseInt(this.props.association))[0];
							this.props.change('association', initialAssoc);
							this.onAssociationChange(initialAssoc);
					}
				});
			});
	}

	onAssociationChange(data){

		if (this.props.access.user_type === "club-admin") {
			let club = {label: this.props.access.default_club_name, value: parseInt(this.props.access.default_club_id), id: parseInt(this.props.access.default_club_id)};
			this.setState({
				clubs: [club]
			}, ()=>{
				this.props.change('club', club);
			})
		} else {
			AssociationService.getAllClubs(data.id).then((res) => {
				let clubs = res.clubs.map(club => {
					club.value = club.id;
					club.label =  club.club_name;
					return club
				});

				this.props.change('club', null);

				clubs.sort((a, b) => a['label']?.toString().localeCompare(b['label']?.toString()));

				this.setState({
					clubs
				}, ()=>{
					if (this.props.club) {
						this.props.change('club', clubs.filter( c => parseInt(c.id) === parseInt(this.props.club.id))[0]);
					}
				})
			});
		}
	}


	showConfirm(data) {
		this.setState({
			confirmation: true,
			confirmationError: data.error,
			toPost: data.golfer,
			clubId: data.clubId
		});
	}

	onSubmit(values) {

		return GolferService.createGuardian(this.props.golfer.id,{
			...values.guardian,
			relationship: values.guardian.relationship.value,
		}).then(res => {
			this.props.showSuccess({...values.guardian});
		})
			.catch(error => {

				let errorMessage = processErrorMessage(error.data.errors);
				this.setState({
					error: errorMessage
				});
				console.error(error)
			});

	}




	render() {
		const {handleSubmit, pristine, valid, submitting} = this.props;


		return (
			<Fragment>
				{!this.state.confirmation && <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
					<GhinGuardianFormComponent guardian={this.props.guardian} change={this.props.change} hide_search={true}/>

					{this.state.error && <span className={'validation__message is-error'}>{this.state.error}</span>}

					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} onClick={(e) => {
								e.preventDefault();
								this.props.resetToSelect()
							}} className="btn fill gray">Back
							</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={"submit"} disabled={pristine || !valid || submitting} className="btn fill blue">Add Guardian
							</button>
						</div>
					</div>
				</form>}
				{this.state.confirmation &&
				<ConfirmationModalTypeB openModal={this.state.confirmation}
										wideClass={"narrow"}
										onConfirmAction={() => this.onConfirm()}
										modalIcon={"help_outline"}
										onCancelAction={() => {
											this.setState({confirmation: false})
										}}
										closeModal={() => {
											this.setState({confirmation: false})
										}}
										infoText={[this.state.confirmationError]}
										cancelLabel={"Back"}
										confirmLabel={"Save"}/>
				}
			</Fragment>
		);
	}
}

AddNewGuardianForm = reduxForm({
	form: 'addGuardianForm',
	destroyOnUnmount: true
})(AddNewGuardianForm);

function mapStateToProps(state) {
	const selector = formValueSelector('addGuardianForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		access: state.user.access,
		guardian: selector(state, 'guardian')
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ stopAsyncValidation}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(AddNewGuardianForm));
