import { Fragment, useEffect, useState } from "react";
import TransactionFeesServices from "../../../../services/api/transactionFees";
import GhinTable from "../../../shared/GhinTable";
import { numberFormat } from "../../../shared/numberFormat";
import Modal from "react-modal";
import EditTransactionFeeModal from "../../../Modals/EditTransactionFeeModal";
import TransactionFeeEditLogModal from "../../../Modals/TransactionFeeEditLogModal";
import ConfirmationModalTypeB from "../../../Modals/ConfirmationModalTypeB";
import { connect } from "react-redux";
import { addAlert } from "../../../shared/actions";
import SharedService from "../../../../services/api/shared";
import { bindActionCreators } from "redux";
import moment from "moment";
import TransactionFeeDetailsModal from "../../../Modals/TransactionFeeDetailsModal";

function TransactionFees(props) {
  const columns = [
    {
      Header: 'Transaction Fee Name',
      accessor: 'description',
      Cell: (data) => {
        return (
          <div className="blue_ribbon ribbon_hover">
            {data.original.description}
            {data.original.edited &&
              <i className="material-icons-outlined blue" onClick={() => {
                setSelectedFee(data.original);
                setTransactionEditLogModalIsOpen(true)
              }}>bookmark</i>
            }
          </div>
        )
      }
    },
    {
      Header: 'Transaction Fee',
      colClassName: 'col_method-small',
      accessor: 'amount',
      Cell: (data) => (numberFormat(data.original.amount))
    },
    {
      Header: 'Default Fee?',
      colClassName: 'col_gender',
      accessor: 'is_default',
      Cell: (data) => (data.original.is_default === true ? 'Yes' : 'No')
    },
    {
      colClassName: 'col_details',
      thClassName: 'table-align-center',
      Cell: (data) => {
        if (!props.canEditAssociation) {
          return <div></div>
        } else {
          return (
            <span style={{textAlign: "center", margin: "auto", display: "block"}}>
              <button className="btn lnk" onClick={() => {
                setSelectedFee(data.original);
                setTransactionDetailsModalIsOpen(true)
              }}>Details</button>
              <button
                style={{ marginLeft: "20px" }}
                className={"btn lnk"}
                onClick={() => {
                  setOperationType('Edit');
                  setSelectedFee(data.original);
                  setAddTransactionModalIsOpen(true);
                }}
              >
                Edit
              </button>
              {!data.original.is_used && <button
                style={{ marginLeft: "20px" }}
                className={"btn lnk"}
                onClick={() => {
                  setSelectedFee(data.original);
                  setDeleteModalIsOpen(true);
                }}
              >
                Delete
              </button>}
            </span>
          )
        }
      }
    }
  ];

  const [transactions, setTransactions] = useState([])
  const [addTransactionModalIsOpen, setAddTransactionModalIsOpen] = useState(false);
  const [transactionEditLogModalIsOpen, setTransactionEditLogModalIsOpen] = useState(false);
  const [selectedFee, setSelectedFee] = useState(null);
  const [transactionFeesLoading, setTransactionFeesLoading] = useState(false);
  const [operationType, setOperationType] = useState('');
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [joinPagesModalIsOpen, setJoinPagesModalIsOpen] = useState(false);
  const [updateTransactionValues, setUpdateTransactionValues] = useState({});
  const [error, set_error] = useState('');
  const [affectedJoinPages, setAffectedJoinPages] = useState([]);
  const [loadingAffectedJoinPages, setLoadingAffectedJoinPages] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [transactionDetailsModalIsOpen, setTransactionDetailsModalIsOpen,] = useState(false);

  useEffect(() => {
    getTransactionFees();
  }, [props.associationId])

  const closeModal = () => {
    getTransactionFees();
    setAddTransactionModalIsOpen(false);
    setJoinPagesModalIsOpen(false);
  }

  const getTransactionFees = () => {
    setTransactionFeesLoading(true);
    TransactionFeesServices.getTransactions(props.associationId, {is_used: 'true'}).then((res) => {
      setTransactions(res.transaction_fees);
      isTransactionUsed(res.transaction_fees);
      setTransactionFeesLoading(false);
    });
  }

  const isTransactionUsed = (transactions) => {
    for (const transaction of transactions) {
      if (transaction.is_used === true) {
        setShowNote(true);
        break;
      }
    }
  }

  const deleteTransactionFee = () => {
    TransactionFeesServices.deleteTransaction(props.associationId, selectedFee.id).then(() => {
      props.addAlert({ type: "success", message: "Transaction Fee successfully deleted." })
      getTransactionFees();
    }).catch((e) => {
      if (e.data.errors) {
        const data = e.data.errors
        props.addAlert({ type: "error", message: data[Object.keys(data)[0]][0] })
      }
      getTransactionFees();
    }).finally(() => {
      setDeleteModalIsOpen(false)
    })
  }

  const updateTransaction = (values = null) => {
    const updateValues = values ? values : updateTransactionValues;
    return TransactionFeesServices.updateTransaction(props.associationId, selectedFee.id, updateValues).then(() => {
      props.addAlert({ type: 'success', message: "Transaction Fee successfully updated." });
      localStorage.setItem('transactionFeeUpdated', Date.now()?.toString());
      onConfirm();
    }).catch((err) => {
      console.log(err)
      if (err.data.errors) {
        const error = err.data.errors
        set_error(error[Object.keys(error)[0]])
        props.addAlert({ type: 'error', message: error[Object.keys(error)[0]] });
      }
      if (err.data.error) {
        console.log(err.data.error)
        set_error(err.data.error)
        props.addAlert({ type: 'error', message: 'Transaction Fee has not been updated.' });
      }
      closeModal()
    })
  }

  const getAffectedJoinPages = async () => {
    try {
      setLoadingAffectedJoinPages(true);
      const res = await TransactionFeesServices.affectedJoinPages(props.associationId, selectedFee.id);
      setAffectedJoinPages(res.join_pages);
      setLoadingAffectedJoinPages(false);
    } catch (err) {
      setLoadingAffectedJoinPages(false);
      console.log(err);
      if (err.data.errors) {
        const error = err.data.errors;
        set_error(error[Object.keys(error)[0]]);
      }
      if (err.data.error) {
        console.log(err.data.error);
        set_error(err.data.error);
      }
    }
  }

  const onConfirm = () => {
      getAffectedJoinPages();
      setJoinPagesModalIsOpen(true);
  }
  
  const handleDownload = () => {
   return TransactionFeesServices.getAffectedClubMembershipsCSV(props.associationId, selectedFee.id, true).then(response => {
      SharedService.downloadFile(response, "club_membership_fees-" + moment().format('MM/DD/YYYY') + ".csv");
    });
  }
    
  return (
    <Fragment>
      <div className="panel">
        <div className="panel__head">Transaction Fees</div>
        <div className="panel__body">
          <p> If you would like to charge a transactional fee for GHIN Join & Renew credit card transactions, you can add one or many below and assign them to applicable clubs.</p>

          <div className="row margin-top-16">
            {showNote && <div className="col">
              <p className="red-note">
                <strong>Note:</strong> A transaction fee cannot be deleted if it has been assigned to a membership fee. To delete, you must first remove it from all membership fees it has been assigned to. To do this, press the "Club Management" button to the left and remove it from the applicable membership fees. Once that is done the transaction fee can be deleted from this page.
              </p>
            </div>}
          </div>
          <div className="row">
            <div className="col is-1-of-5 push-right">
              {props.canEditAssociation && <button className="btn fill green" onClick={() => {
                setOperationType('Create');
                setSelectedFee(null);
                setAddTransactionModalIsOpen(true);
              }}>Add Transaction Fee</button>}

              <div className="transaction_legend table__legend margin-top-16 blue_ribbon ribbon_text">
                <i className="material-icons-outlined blue">bookmark</i>
                <p>Modified Transaction Fee</p>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col is-full'>
              <GhinTable
              idKey={'id'}
              columns={columns}
              hideSelect={true}
              sortable={true}
              hidePagination={true}
              data = {transactions}
              loading={transactionFeesLoading}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={addTransactionModalIsOpen}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
      >
        <EditTransactionFeeModal
          closeModal={() => closeModal()}
          selectedFee={selectedFee}
          associationId={props.associationId}
          reload_transaction_fees={() => getTransactionFees()}
          operationType={operationType}
          onConfirm={onConfirm}
          setUpdateTransactionValues={setUpdateTransactionValues}
          error={error}
          joinPagesModalIsOpen={joinPagesModalIsOpen}
          setJoinPagesModalIsOpen={setJoinPagesModalIsOpen}
          affectedJoinPages={affectedJoinPages}
          loadingAffectedJoinPages={loadingAffectedJoinPages}
          updateTransaction={updateTransaction}
          handleDownload={handleDownload}
        />
      </Modal>

      <Modal
        isOpen={transactionEditLogModalIsOpen}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
      >
        <TransactionFeeEditLogModal
          closeModal={() => {setTransactionEditLogModalIsOpen(false)}}
          selectedTransaction={selectedFee}
        />
      </Modal>

      <ConfirmationModalTypeB
        openModal={deleteModalIsOpen}
        wideClass={"narrow"}
        onConfirmAction={() => {
          deleteTransactionFee()
        }}
        modalIcon={"help_outline"}
        onCancelAction={() => {
          setDeleteModalIsOpen(false)
        }}
        closeModal={() => {
          setDeleteModalIsOpen(false)
        }}
        infoText={[`Are you sure you want to delete the ${selectedFee ? selectedFee.description : ''} transaction fee?`]}
        note={selectedFee && selectedFee.is_default ? "The transaction fee you are deleting is set as the default.  If you wish to set another fee as the default, you can do so by editing that fee and checking the “Make Default Transaction Fee” checkbox." : null}
        cancelLabel={"No"}
        confirmLabel={"Yes"} />

      <Modal
        isOpen={transactionDetailsModalIsOpen}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
      >
        <TransactionFeeDetailsModal
          closeModal={() => {setTransactionDetailsModalIsOpen(false)}}
          selectedTransaction={selectedFee}
        />
      </Modal>
    </Fragment>
  )
}

function mapStateToProps() {
  return {
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    noOverwriteOnInitialize: true
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAlert }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionFees)
