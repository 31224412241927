import { Fragment } from "react";
import { useState, useEffect } from "react";
import GhinTable from "../../../shared/GhinTable";
import Modal from "react-modal";
import MembershipFeeModal from "../../../Modals/MembershipFeeModal";
import MembershipFeeDetailsModal from "../../../Modals/MembershipFeeDetailsModal";
import ConfirmationModalTypeB from "../../../Modals/ConfirmationModalTypeB";
import AssociationService from "../../../../services/api/association";
import TransactionFeesServices from "../../../../services/api/transactionFees";
import ClubService from "../../../../services/api/club";
import GhinFilterSelect from "../../../shared/GhinFilterSelect";
import ClubMembershipFeeEditLogModalIsOpen from "../../../Modals/ClubMembershipFeeEditLogModalIsOpen";

const emptyFee = {
	amount: null,
	label: `No Transaction Fee`,
	value: '',
	is_default: false
};

export default function ClubManagement(props) {
	const [
		membershipFeeDetailsModalIsOpen,
		setMembershipFeeDetailsModalIsOpen,
	] = useState(false);
	const [
		deleteMembershipFeeModalIsOpen,
		setDeleteMembershipFeeModalIsOpen,
	] = useState(false);
	const [membershipFeeModalIsOpen, setMembershipFeeModalIsOpen] = useState(
		false
	);
	const [membershipFeeModalAction, setMembershipFeeModalAction] = useState(
		""
	);

	const [selectedMembershipFee, setSelectedMembershipFee] = useState(null);
	const [loading, setLoading] = useState(false);
	const [clubs, setClubs] = useState([]);
	const [selectedClub, setSelectedClub] = useState(null);
	const [page, setPage] = useState(0);
	const [perPage, setPerPage] = useState(25);
	const [pages, setPages] = useState(null);
	const [total, setTotal] = useState(null);
	const [sorted, setSorted] = useState([{ id: "club_name", desc: false }]);
  const [sortingChanged, setSortingChanged] = useState(false);
  const [order, setOrder] = useState(false);
  const [clubName, setClubName] = useState([]);
  const [joinAndRenew, setJoinAndRenew] = useState([]);
  const [transactionFees, setTransactionFees] = useState([]);
  const [selectedMembershipTypes, setSelectedMembershipTypes] = useState([]);
  const [membershipCodes, setMembershipCodes] = useState([]);
  const [selectedMembershipCodes, setSelectedMembershipCodes] = useState([]);
	const [loadingJoinAndRenew, setLoadingJoinAndRenew] = useState(null);
	const [membershipFeeEditLogModalIsOpen, setMembershipFeeEditLogModalIsOpen] = useState(false);
	const [disableJRModalIsOpen, setDisableJRModalIsOpen] = useState(false);
	const [disabledJRClub, setDisabledJRClub] = useState(null);

	const getTransactionFees = () => {
		setLoading(true);
		TransactionFeesServices.getTransactions(props.associationId)
			.then((res) => {
				let transactionFees = res.transaction_fees.map((tf) => {
					return {
						amount: tf.amount,
						label: `${tf.description} - $${tf.amount}`,
						value: tf.id,
						is_default: tf.is_default
					};
				})

				transactionFees.unshift(emptyFee)
				setTransactionFees(transactionFees);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getTransactionFees();
    loadMembershipTypes();
	}, [props.associationId]);

	useEffect(() => {
		getClubs();
  }, [
    page,
    perPage,
    sortingChanged,
    order,
    clubName,
    joinAndRenew,
    selectedMembershipTypes,
    selectedMembershipCodes,
  ]);

	const fetchFees = () => {
		getTransactionFees();
		getClubs();
		setPage(0);
		setPerPage(25);
		setPages(null);
	}

	useEffect(() => {
		const handleStorageChange = (e) => {
			if(e.key === 'transactionFeeUpdated') {
				fetchFees();
			}
		};

		window.addEventListener('storage', handleStorageChange);

		return () => {
			window.removeEventListener('storage', handleStorageChange)
		};
	}, []);

	const getClubs = () => {
		setLoading(true);

		let params = {
			page: page + 1,
			per_page: perPage,
		};

    let joinAndRenewValues = joinAndRenew.map((jr) =>
      jr.value === "Enabled" ? true : false
    );
    let membershipCodeValues = selectedMembershipCodes.map((mc) => mc.value);
    let membershipTypeValues = selectedMembershipTypes.map((mt) => mt.value);

    params["club_name"] = clubName;
    params["join_and_renew_enabled"] = joinAndRenewValues;
    params["membership_type"] = membershipTypeValues;
    params["membership_code"] = membershipCodeValues;
		params["sorting_criteria"] = sorted[0].id || "club_name";
		params["order"] = sorted[0].desc ? "desc" : "asc";
		params["hide_used"] = false;
		params["hide_join_page_ids"] = true;
		params["status"] = 'Active';
		params["is_aga"] = 'false';
		params["authorized"] = true;
		params["frontend_provider"] = 'GHIN';

		AssociationService.getAssociationClubMembershipFees(
			props.associationId,
			params
		)
			.then((data) => {
				setLoading(false);
        setTotal(parseInt(data["total-count"], 10));
        setPerPage(parseInt(data["per-page"], 10));
        setPages(parseInt(data["total-pages"], 10));
				setClubs(
          Object.entries(data.club_memberships).map((club) => {
						return club[1];
					})
				);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const enableJoinAndRenew = (club_id) => {
		setLoadingJoinAndRenew(club_id);
		ClubService.enableJoinAndRenew(club_id)
			.then(() => {
				setLoadingJoinAndRenew(null);
				props.addAlert({
					type:'success',
					message:'Join & Renew has been successfully enabled.'
				});
				getClubs();
			})
			.catch((err) => {
				console.error(err);
				setLoadingJoinAndRenew(null);
			});
	};

	const disableJoinAndRenew = (club_id) => {
		setLoadingJoinAndRenew(club_id);
		ClubService.disableJoinAndRenew(club_id)
			.then(() => {
				setLoadingJoinAndRenew(null);
				props.addAlert({
					type:'success',
					message:'Join & Renew has been successfully disabled.'
				});
				getClubs();
			})
			.catch((err) => {
				console.error(err);
				setLoadingJoinAndRenew(null);
			});
	};

	const handleJREnabledChange = (data) => {
		if (data.original.join_and_renew_enabled === true) {
			if (data.original.auto_renew_enabled === true) {
				setDisableJRModalIsOpen(true);
				setDisabledJRClub(data.original);
			} else
				disableJoinAndRenew(data.original.club_id);
		} else
			enableJoinAndRenew(data.original.club_id);
	}

	const closeModal = () => {
		setMembershipFeeModalIsOpen(false);
		setMembershipFeeDetailsModalIsOpen(false);
		setDeleteMembershipFeeModalIsOpen(false);
		setDisableJRModalIsOpen(false)
		setDisabledJRClub(null);
	};

	const deleteMembershipFee = () => {
		ClubService.removeMembershipFee(selectedClub, selectedMembershipFee.id)
			.then((res) => {
				props.addAlert({
					type:'success',
					message:'Membership Fee has been successfully removed.'
				});
				setDeleteMembershipFeeModalIsOpen(false);
				getClubs();
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const calculateTotalAmount = (fee) => {
    let total_amount =
      parseFloat(fee.golf_association_membership_amount || 0.0) +
			parseFloat(fee.golf_association_fee_amount || 0.0) +
      parseFloat(fee.amount || 0.0);
    return total_amount.toFixed(2);
  };

  const loadMembershipTypes = () => {
    AssociationService.getMembershipTypes(props.associationId, {
      include_used_membership: "false",
    })
      .then((res) => {
        setMembershipCodes(
          res.membership_types
            ? res.membership_types
              .map((membershipType) => {
                return {
                  label: membershipType.code,
                  value: membershipType.id,
                };
              })
              .sort((a, b) =>
                a["label"]
                  ?.toString()
                  .localeCompare(b["label"]?.toString())
              )
            : []
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const reset = (e) => {
    e.preventDefault();
    setClubName([]);
    setJoinAndRenew([]);
    setSelectedMembershipTypes([]);
    setSelectedMembershipCodes([]);
	};

	const columns = [
		{
			Header: "Club #",
			colClassName: "col_diff",
			accessor: "club_id",
		},
		{
			Header: "Club Name",
			colClassName: "col_date_posted",
			accessor: "club_name",
			sortable: true,
		},
		{
			Header: "Join & Renew",
			colClassName: "col_gender",
			thClassName: "table-align-center",
      sortable: false,
			Cell: (data) => {
				return (
					<Fragment>
						<input
							type="checkbox"
							disabled={!props.canEditAssociation || data.original.club_id === loadingJoinAndRenew}
							defaultChecked={
								data.original.join_and_renew_enabled
							}
						/>
						<label
							style={{ margin: "auto", height: "40px" }}
							onClick={() => {handleJREnabledChange(data);}}
						/>
					</Fragment>
				);
			},
		},
		{
			Header: "Club Membership Fee Details",
			thClassName: "table-align-center",
      sortable: false,
			Cell: (data) => {
				return data.original.join_and_renew_enabled === false ? null : (
					<Fragment>
						<ul>
							{data.original.club_membership_fees.map((cmf) => {
								return (
									<li key={cmf.id}>
                    {cmf.name} (${calculateTotalAmount(cmf)}) -
										<button
											className="cmd-button"
											onClick={() => {
												setSelectedMembershipFee(cmf);
												setMembershipFeeDetailsModalIsOpen(
													true
												);
												setSelectedClub(
													data.original.club_id
												);
											}}
										>
											Details
										</button>
                    {props.canEditAssociation && <Fragment>{
										<button
											className="cmd-button"
											onClick={() => {
												setSelectedMembershipFee(cmf);
												setSelectedClub(
													data.original.club_id
												);
												setMembershipFeeModalAction(
													"edit"
												);
												setMembershipFeeModalIsOpen(
													true
												);
											}}
										>
											Edit
										</button>
									}
									{cmf?.used === false &&
										<button
											className="cmd-button"
											onClick={() => {
												setSelectedMembershipFee(cmf);
												setSelectedClub(
													data.original.club_id
												);
												setDeleteMembershipFeeModalIsOpen(
													true
												);
											}}
										>
											Delete
										</button>
									}
											<span className="cmd-button">
												<div className="blue_ribbon ribbon_hover"
														 style={{marginTop: "2px", transform: "translateY(5px)"}}>
													{cmf.edited &&
														<i className="material-icons-outlined blue"
															 style={{lineHeight: "1px"}} onClick={() => {
															setSelectedMembershipFee(cmf);
															setSelectedClub(data.original.club_id);
															setMembershipFeeEditLogModalIsOpen(true)
														}}>bookmark</i>
													}
												</div>
											</span>
										</Fragment>
                    }
									</li>
								);
							})}
						</ul>
					</Fragment>
				);
			},
		},
		{
			colClassName: "col_ghin",
      sortable: false,
			Cell: (data) => {
				return data.original.join_and_renew_enabled === true ? (
					<Fragment>
						<button
							className="btn fill green"
							onClick={() => {
								setMembershipFeeModalAction("add");
								setSelectedMembershipFee(null);
								setSelectedClub(data.original.club_id);
								setMembershipFeeModalIsOpen(true);
							}}
						>
							Add Fee
						</button>
					</Fragment>
				) : null;
			},
			show: props.canEditAssociation,
		},
	];

	return (
		<Fragment>
			<div className="columns">
				<div className="row">
					<div className="panel">
						<div className="panel__head">Club Management</div>
						<div className="panel__body">
							{props.canEditAssociation && (
								<>
									<div className="row">
										<div className="col">
											<p>
												Enable clubs for GHIN Join &
												Renew and create and manage
												membership fees for clubs
												enabled for Join & Renew. Only
												active clubs will display in
												this list.
											</p>
										</div>
									</div>
								</>
							)}
              <div className="filters" style={{marginBottom: 0}}>
                <div className="row">
                  <div className="filters__inputs col">
                    <div className="row">
                      <div className={"col"}>
                        <div className="row">
                          <div className="col is-1-of-5">
                            <label htmlFor="club-name">
                              Club Name
                            </label>
                            <input
                              type="text"
                              autoComplete="off"
                              name="club-name"
                              id="club-name"
                              value={clubName}
                              onChange={(e) => {
                                setClubName(
                                  e.target
                                    .value
                                );
                              }}
                            />
                          </div>

                          <div
                            className={
                              "col is-1-of-5"
                            }
                          >
                            <GhinFilterSelect
                              options={[
                                "Enabled",
                                "Disabled",
                              ]}
                              value={joinAndRenew}
                              onChange={(
                                values
                              ) => {
                                setJoinAndRenew(
                                  values
                                );
                              }}
                              label={
                                "Join & Renew"
                              }
                            />
                          </div>
                          <div
                            className={
                              "col is-1-of-5"
                            }
                          >
                            <GhinFilterSelect
                              options={[
                                "Junior",
                                "Standard"
                              ]
                              }
                              value={
                                selectedMembershipTypes
                              }
                              onChange={(
                                values
                              ) => {
                                setSelectedMembershipTypes(
                                  values
                                );
                              }}
                              label={
                                "USGA Membership Type"
                              }
                            />
                          </div>
                          <div
                            className={
                              "col is-1-of-5"
                            }
                          >
                            <GhinFilterSelect
                              options={
                                membershipCodes
                              }
                              value={
                                selectedMembershipCodes
                              }
                              onChange={(
                                values
                              ) => {
                                setSelectedMembershipCodes(
                                  values
                                );
                              }}
                              label={
                                "Membership Code"
                              }
                            />
                          </div>
                          <div
                            className={
                              "col is-1-of-7 push-right"
                            }
                          >
                            <button
                              className="btn fill gray top"
                              onClick={reset}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
							<div className="row">
								<div className="col auto push-right">
									<div className="transaction_legend table__legend margin-top-16 blue_ribbon ribbon_text">
										<i className="material-icons-outlined blue">bookmark</i>
										<p>Modified Membership Fee</p>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col is-full">
									<GhinTable
										idKey={"id"}
										columns={columns}
										loading={loading}
										pages={pages}
										page={page}
										total={total}
										pageSize={perPage}
										data={clubs}
										hideSelect={true}
										manual
										onSortedChange={(newSorted) => {
											setPage(0);
											setPerPage(25);
											setSorted(newSorted);
                      setSortingChanged(!sortingChanged);
											let order =
												newSorted[0]["desc"] === true
													? true
													: false;
											setOrder(order);
										}}
										onPageChange={(index) => setPage(index)}
										onPageSizeChange={(size, index) => {
											setPerPage(size);
											setPage(index);
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal
				isOpen={membershipFeeModalIsOpen}
				contentLabel="Modal"
				portalClassName="modal"
				overlayClassName="modal__overlay"
				className="modal__content"
				bodyOpenClassName="modal--is-open"
				htmlOpenClassName="prevent-scroll"
				shouldCloseOnOverlayClick={false}
				shouldFocusAfterRender={false}
			>
				<MembershipFeeModal
					action={membershipFeeModalAction}
					clubId={selectedClub}
					startMinDate={clubs.find((club) => club.club_id === selectedClub)?.start_min_date}
					currentDate={clubs.find((club) => club.club_id === selectedClub)?.current_date}
					selectedMembershipFee={selectedMembershipFee}
					transactionFees={transactionFees}
					reload_clubs={() => getClubs()}
					closeModal={() => closeModal()}
				/>
			</Modal>

			<Modal
				isOpen={membershipFeeDetailsModalIsOpen}
				contentLabel="Modal"
				portalClassName="modal"
				overlayClassName="modal__overlay"
				className="modal__content"
				bodyOpenClassName="modal--is-open"
				htmlOpenClassName="prevent-scroll"
				shouldCloseOnOverlayClick={false}
				shouldFocusAfterRender={false}
			>
				<MembershipFeeDetailsModal
					closeModal={() => closeModal()}
					clubId={selectedClub}
					data={[selectedMembershipFee]}
					selectedMembershipFee={selectedMembershipFee}
					transactionFees={transactionFees}
				/>
			</Modal>

			<Modal
				isOpen={membershipFeeEditLogModalIsOpen}
				contentLabel="Modal"
				portalClassName="modal"
				overlayClassName="modal__overlay"
				className="modal__content"
				bodyOpenClassName="modal--is-open"
				htmlOpenClassName="prevent-scroll"
				shouldCloseOnOverlayClick={false}
				shouldFocusAfterRender={false}
			>
				<ClubMembershipFeeEditLogModalIsOpen
					closeModal={() => {setMembershipFeeEditLogModalIsOpen(false)}}
					selectedMembershipFee={selectedMembershipFee}
					clubId={selectedClub}
				/>
			</Modal>

			<ConfirmationModalTypeB
				wideClass={"narrow"}
				openModal={deleteMembershipFeeModalIsOpen}
				onConfirmAction={() => deleteMembershipFee()}
				onCancelAction={() => closeModal()}
				closeModal={() => closeModal()}
				question={`Are you sure you want to delete the Standard membership fee?`} // to be replaced by actual string interpolation
				cancelLabel={"No"}
				confirmLabel={"Yes"}
				modalIcon={"help_outline"}
			/>

			<ConfirmationModalTypeB
				question={`Disable Join & Renew?`}
				openModal={disableJRModalIsOpen}
				wideClass={"narrow"}
				colorClass={"brick_red"}
				onConfirmAction={() => {
					disableJoinAndRenew(disabledJRClub.club_id);
					closeModal();
				}}
				modalIcon={"help_outline"}
				onCancelAction={() => closeModal()}
				closeModal={() => closeModal()}
				infoText={[`Are you sure you want to disable Join & Renew for the ${disabledJRClub ? disabledJRClub.club_name : "club"}?`,
					'',
					`Doing so will generate an email notification to all golfers in the club that have enrolled in the service
        letting them know auto-renew has been turned off.`]}
				cancelLabel={"Cancel"}
				confirmLabel={"Disable Join & Renew"}/>
		</Fragment>
	);
}
