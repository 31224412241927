import React, {Component, Fragment} from 'react';

import EditGolferAccountForm from '../Forms/EditGolferAccountForm';
import GolferService from '../../services/api/golfer';
import ConfirmationModalTypeB from "./ConfirmationModalTypeB";
import {bindActionCreators} from "redux";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import moment from "moment/moment";

class EditGolferAccountModal extends Component {

	constructor(){
		super();
		this.state = {
			dataToChange: null,
			confirmationModal: false,
			birthdateModal: false,
		}
	}
	confirmGender(data){
		this.setState({
			dataToChange: data,
			confirmationModal: true
		})
	}

	confirmBirthDate(data){
		this.setState({
			dataToChange: data,
			birthdateModal: true
		})
	}

	onConfirm() {
		GolferService.updateGolfer(this.props.golfer.club_id, this.props.golfer.id, this.state.dataToChange).then(res=>{
			this.props.addAlert({
				type:'success',
				message:'Golfer has been successfully edited'
			});
			this.props.closeModal(res);
    }).catch((e) => {
      this.setState({
        error: e[Object.keys(e)[0]].errors,
        confirmationModal: false,
		  birthdateModal: false,
      })
    });

	}

	render() {
		const birthdate = moment(this.state.dataToChange?.golfer?.date_of_birth).format('MM/DD/YYYY');
		return (
			<Fragment>
				{this.state.confirmationModal &&
				<ConfirmationModalTypeB openModal={this.state.confirmationModal}
										onConfirmAction={() => this.onConfirm()}
										modalIcon={'help_outline'}
										onCancelAction={() => {
											this.setState({confirmationModal: false})
										}}
										closeModal={() => {
											this.setState({confirmationModal: false})
										}}
										infoText={[
											'Changing a golfers gender may impact previously posted scores.',
											'Press "Save" to continue or undo the gender change before submitting the form.',
											'You must use Score Maintenance to update scores posted to the incorrect ratings.'
										]}
										cancelLabel={"Back"}
										confirmLabel={"Save"}/>
				}

				{this.state.birthdateModal && birthdate &&
					<ConfirmationModalTypeB openModal={this.state.birthdateModal}
											onConfirmAction={() => this.onConfirm()}
											modalIcon={'help_outline'}
											onCancelAction={() => {
												this.setState({birthdateModal: false})
											}}
											closeModal={() => {
												this.setState({birthdateModal: false})
											}}
											infoText={[
												'Please verify the birthdate entered.',
												`Birthdate: ${birthdate}`,
												'Press the "Save" button to continue if the birthdate is correct.',
												'To edit the birthdate before submitting the form, press the "Back" button.'
											]}
											cancelLabel={"Back"}
											confirmLabel={"Save"}/>
				}

				<div className={`modal__container narrow ${(this.state.confirmationModal || this.state.birthdateModal)? 'hidden' : ''}`}>

					<div className="modal__head">

						<div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
						<h2 className="modal__title">Edit Golfer Account</h2>
						<button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

					</div>

					<div className="modal__body">

            <EditGolferAccountForm parent_error={this.state.error} confirmGender={(data) => { this.confirmGender(data) }} confirmBirthDate={(data) => { this.confirmBirthDate(data) }} canEditAssociation={this.props.canEditAssociation} golfer={this.props.golfer} club={this.props.club} close={(data) => this.props.closeModal(data)} />

					</div>

				</div>
            </Fragment>


		);
	}
}


function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(null, mapDispatchToProps)(EditGolferAccountModal);
