import { Fragment, useState, useEffect } from 'react';
import GhinFilterSelect from "../../../shared/GhinFilterSelect";
import 'react-picky/dist/picky.css'; // Include CSS
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { checkKeyForDateInput } from '../../../../inputs/helper';

const initial_filters = {
  golfer_id: '',
  email_address: '',
  email_type: '',
  status: '',
  from_date: '',
  to_date: ''
};

export default function TableFilter(props) {
  const [filters, setFilters] = useState(initial_filters);

  useEffect(() => {
    let timer = setTimeout(() => {
      props.updateFilters(
        {
          ...filters,
          status: filters.status.value,
          email_type: filters.email_type.value
        }
      );
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
      <Fragment>
        <div className="filters">
          <div className="row">
            <div className="filters__inputs col">
              <div className="row">
                <div className={'col'}>
                  <div className="row">
                    <div className="col is-1-of-6">
                      <label htmlFor="golfer_id ">GHIN #</label>
                      <input type="text" autoComplete="off" value={filters.golfer_id} name="golfer_id" id="golfer_id" onChange={(e) => {
                        setFilters({...filters, golfer_id: e.target.value});
                      }}/>
                    </div>
                    <div className="col is-1-of-6">
                      <label htmlFor="email_address">Email Address</label>
                      <input type="text" autoComplete="off" value={filters.email_address} name="email_address" id="email_address" onChange={(e) => {
                        setFilters({...filters, email_address: e.target.value});
                      }}/>
                    </div>
                    <div className="col is-1-of-6">
                      <GhinFilterSelect
                        options={['Transaction Receipts', 'Refund Receipt', 'Registration Submitted - Pending', 'Reject Pending', 'Transaction Failed - Pending', 'Auto-Renew Reminders', 'Renewal Reminders', 'Payment Declined', 'Transaction Declined', 'Payment Method Updated', 'Auto-Renew Failed Inactivation', 'Verification']}
                        value={filters.email_type}
                        singleChoice={true}
                        onChange={value => setFilters({...filters, email_type: value})}
                        label={'Email Type'}
                      />
                    </div>
                    <div className="col is-1-of-6">
                      <GhinFilterSelect
                        options={['Delivered', 'Opened', 'Not Delivered']}
                        value={filters.status}
                        hideSelectAllOption={true}
                        singleChoice={true}
                        onChange={value => setFilters({...filters, status: value})}
                        label={'Status'}
                      />
                    </div>
                    <div className="col is-1-of-6">
                      <label>From</label>
                      <DatePicker
                      selected={filters.from_date ? new Date(filters.from_date) : null}
                      maxDate={filters.to_date ? new Date(filters.to_date) : new Date()}
                      className={`date`}
                      dropdownMode="select"
                      dateFormat={'MM/dd/yyyy'}
                      showMonthDropdown
                      showYearDropdown
                      onKeyDown={e => { checkKeyForDateInput(e) }}
                      onChange={(value) => {
                        if (value) {
                          const date = new Date(value)
                          const dateString = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
                          setFilters({ ...filters, from_date: dateString });
                        }
                      }}
                      />
                    </div>
                    <div className="col is-1-of-6">
                      <label>To</label>
                      <DatePicker
                        selected={filters.to_date ? new Date(filters.to_date) : null}
                        maxDate={new Date()}
                      minDate={filters.from_date ? new Date(filters.from_date) : null}
                        className={`date`}
                        dropdownMode="select"
                        dateFormat={'MM/dd/yyyy'}
                        showMonthDropdown
                        showYearDropdown
                        onKeyDown={e => {checkKeyForDateInput(e)}}
                        onChange={(value) => {
                          if (value) {
                            const date = new Date(value)
                            const dateString = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
                            setFilters({ ...filters, to_date: dateString });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="filters__controls col is-1-of-10 jc-fe">
                <button className="btn fill gray" onClick={() => setFilters(initial_filters)}>Reset</button>
            </div>
          </div>
        </div>

      </Fragment>
		);
}
