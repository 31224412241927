import React, {Component, Fragment} from 'react';
import GolferServices from '../../../services/api/golfer';
import AssociationService from "../../../services/api/association";
import GhinTable from "../../shared/GhinTable";
import {addAlert} from '../../shared/actions';
import ConfirmationModalTypeB from "../../Modals/ConfirmationModalTypeB";
import ConfirmationModal from "../../Modals/ConfirmationModal"
import Modal from "react-modal";
import EditClubMembershipModal from "../../Modals/EditClubMembershipModal";
import moment from "moment";
import {checkAccess, getAllPermissions} from "../../../permissionManager";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {updateSelectedMembership, clearSelectedMembership} from "./ClubMembership/actions";
import JoinAndRenewServices from "../../../services/api/joinAndRenew";
import {formatPhoneNumber} from "../../utils";

class ClubMembership extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			selectedClub: this.props.selectedClub.membership,
			affiliations: [],
			oldAffiliations: [],
			inactiveHomeClubModal: false,
			inactiveGolfer: false,
			expandedIndex: -1,
      aga_club: false,
      table_id: 1,
      displayMembershipDetails: this.props.displayMembershipDetails,
      DeleteModalIsOpen: false,
			offersLoading: false,
			offers: [],
			page: 0,
			perPage: 25,
			pages: null,
			total: null
		};
		this.changeHomeClub = this.changeHomeClub.bind(this);
		this.changeStatus = this.changeStatus.bind(this);
    this.getAffiliations = this.getAffiliations.bind(this)
    this.onDelete = this.onDelete.bind(this);
		this.triggerHubspotUpdate = this.triggerHubspotUpdate.bind(this)
	}

	componentDidMount() {
		Modal.setAppElement('body');

		this.getAffiliations();
		if (this.props.selectedClub.displayMembershipDetails)
			this.getOffers(this.props.selectedClub.membership.club_id);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.selectedClub.displayMembershipDetails && (prevState.page !== this.state.page || prevState.perPage !== this.state.perPage))
			this.getOffers(this.props.selectedClub.membership.club_id);
	}

	getOffers(club_id) {
		this.setState({offersLoading: true});
		const params = {
			page: this.state.page + 1, per_page: this.state.perPage
		}
		JoinAndRenewServices.getPromotionalCodesUsedByGolfer(club_id, this.props.publicView ? this.props.golfer.private_id : this.props.golfer.id, params)
			.then( res => {
				this.setState({
					offersLoading: false,
					offers: res.promotional_codes,
					total: parseInt(res["total-count"], 10),
					perPage: parseInt(res["per-page"], 10),
					pages: parseInt(res["total-pages"], 10)
				});
			})
			.catch( err => {
				console.error(err);
				this.setState({
					offersLoading: false,
					offers: [],
				});
			});
	}

  onDelete() {
    var golfer_id = this.state.affiliations[this.state.expandedIndex].golfer_id
    var culb_membership_id = this.state.affiliations[this.state.expandedIndex].id
    return GolferServices.deleteAffiliation(golfer_id, culb_membership_id).then(res => {
      this.props.addAlert({type: 'success', message: "Club affiliation successfully deleted"})
    })
    .catch(err => {
      if(err.status===400){
        this.props.addAlert({type: 'error', message: err.data.errors.club_affiliation[0]})
      }
      if (err.data) {
        console.error(err)
      }
    })
    .finally( () => {
      this.setState({
        DeleteModalIsOpen: false,
        displayMembershipDetails: false,
        table_id: this.state.table_id+1
      })
      this.getAffiliations();
    });
  }

	getAffiliations() {
		this.setState({loading: true});
		GolferServices.getAffiliations(this.props.publicView ? this.props.golfer.private_id : this.props.golfer.id)
			.then( res => {
				let inactiveHomeClubModal = false;
				let inactiveGolfer = true;
				if (res.club_affiliations.length > 0) {
					res.club_affiliations.forEach((affiliation, index) => {
						if (this.props.selectedClub.membership !== null && this.props.selectedClub.membership !== undefined)
							if (this.props.selectedClub.membership.id === affiliation.id){
								this.setState({selectedClub: affiliation, expandedIndex: index});
								this.props.updateSelectedMembership({membership: affiliation, displayMembershipDetails: this.props.displayMembershipDetails});
							}
						if (affiliation.is_home_club && affiliation.status === "Inactive" && !this.props.publicView && res.club_affiliations.length > 1) {
							inactiveHomeClubModal = true;
						}
						if (affiliation.status !== "Inactive"){
							inactiveGolfer = false;
						}
					})
					this.setState({
						loading: false,
						affiliations: res.club_affiliations,
						inactiveHomeClubModal,
						inactiveGolfer
					});
				} else {
					this.setState({
						loading: false,
						affiliations: []
					});
				}
			})
			.catch( err => {
				console.error(err);
			});
	}

	changeHomeClub() {
		this.setState({loading: true, confirmationModal: false, oldAffiliations: this.state.affiliations, affiliations: []});
		GolferServices.updateAffiliation(this.props.golfer.id, this.state.setAsHomeClub.id, {is_home_club: !this.state.setAsHomeClub.is_home_club})
			.then( res => {
				this.setState({
					loading: false
				}, () => {this.props.updateGolfer();})
			})
			.catch( err => {
				if(err) {
          this.props.addAlert({type: 'error', message: err.data.errors});
        }
        this.setState({
					loading: false
				}, () => {this.props.updateGolfer();})
        console.log(err);
			});
	}

	changeStatus(affiliation_id, new_status) {
    if (new_status === true && !this.props.golfer.email && !this.props.golfer.has_active_guardians) {
      const err_msg = this.props.golfer.is_minor ? 'This golfer does not have an email address or an active guardian. Please provide a valid email address or an active guardian for this golfer.'
      : 'This golfer does not have an email address. Please provide a valid email address for this golfer.'
      this.props.addAlert({type: 'error', message: err_msg})
      return;
    }
    
		this.setState({loading: true, oldAffiliations: this.state.affiliations, affiliations: []});
		GolferServices.updateAffiliation(this.props.golfer.id, affiliation_id, {membership_active: new_status})
			.then( res => {
				this.setState({
					loading: false,
				}, () => {this.props.updateGolfer();})
			})
			.catch( err => {
        if(err) {
          this.props.addAlert({type: 'error', message: err.data.errors});
        }
        this.setState({
					loading: false
				}, () => {this.props.updateGolfer();})
        console.log(err);
      })
	}

	triggerHubspotUpdate(affiliation_id) {
		let data = { object_type: 'golfer_club_affiliations', object_id: affiliation_id }
		AssociationService.individualExportToHubspot(this.props.associationId, data)
			.then((response) => {
				this.props.addAlert({
					type: 'success',
					message: 'Membership update has been successfully triggered.'
				})
			})
			.catch((error) => {
				this.props.addAlert({
					type: 'error',
					message: error.data.errors[Object.keys(error.data.errors)[0]][0]
				})
				console.error(error)
			});
	}

	render() {
		let allPermisions = getAllPermissions(this.props.rawAccess);
		let columns = [
			{
				Header: 'Club Name - Association Name',
				accessor: 'club_name',
				colClassName: 'col_club-name',
				Cell: props => <Fragment>
					<p className='blue'>{props.original.club_name} <br />
					<span className='gray italic'>{props.original.association_name}</span></p>
				</Fragment>
			},
			{
				Header: 'Home Club',
				accessor: 'home_club',
				colClassName: 'col_centered_checkbox',
				Cell: props => <Fragment>
					<input type="checkbox"
						   disabled={(props.original.status === "Inactive" && this.state.affiliations.length > 1) || this.props.publicView || this.props.archived || !this.props.canEditClub
							 				|| !((this.props.userType === "super-admin") || checkAccess(allPermisions, props.original.association_number, props.original.club_id))}
						   checked={props.original.is_home_club}
						   onChange={()=> {
							   this.setState({
								   confirmationModal: (props.original.is_home_club ? false : true),
								   setAsHomeClub: props.original
							   });
						   }}
						   name={props.original.id} id={props.original.id}/>
					<label style={{margin:'auto'}} htmlFor={props.original.id}></label>
				</Fragment>
			},
			{
				Header: 'Status',
				accessor: 'status_edit',
				colClassName: 'col_centered_checkbox',
				Cell: props => <Fragment>
					<input type="checkbox"
            disabled={this.props.publicView ||
              this.props.archived ||
              !this.props.canEditClub ||
              props.original.club_membership_fee_id ||
              !((this.props.userType === "super-admin") ||
                checkAccess(allPermisions, props.original.association_number, props.original.club_id)) ||
							(!this.props.canEditAssociation && props.original.locked)
            }
						   checked={props.original.status === "Active"}
						   onChange={() => {
								 this.changeStatus(props.original.id, props.original.status !== "Active");
							 }}
						   name={`${props.original.id}-status`} id={`${props.original.id}-status`}/>
					<label style={{margin:'auto'}} htmlFor={`${props.original.id}-status`}></label>
				</Fragment>
			},
			{
				Header: '',
				accessor: 'status',
				Cell: props => <Fragment>{props.original.status}</Fragment>,
				colClassName: 'col_extended-status',
			},
			{
				Header: 'Club Contact',
				accessor: 'contact',
				Cell: props => props.original.club_contact_address && <Fragment>{props.original.club_contact_address.first_name} {props.original.club_contact_address.last_name}</Fragment>
			},
			{
				Header: 'Club Phone',
				accessor: 'club_phone',
				colClassName: 'col_city',
				Cell: props => <Fragment>{formatPhoneNumber(props.original.club_phone)}</Fragment>,
			},
			{
				Header: 'Club Email',
				accessor: 'club_email',
				Cell: props => <Fragment><a href={`mailto:${props.original.club_email}`}>{props.original.club_email}</a></Fragment>
			},
			{
				Header: 'Club Website',
				accessor: 'club_website',
				Cell: props => <Fragment><a href={`https://${props.original.club_website}`}>{props.original.club_website}</a></Fragment>
			},
			{
				Header: '',
				accessor: 'details',
				colClassName: 'col_centered_checkbox',
				show: !this.props.publicView,
				expander: true,
				Expander: props => {
					let displayDetails = this.props.displayMembershipDetails === true && props.index === this.state.expandedIndex ? false : true
					
					if (allPermisions.associations.filter( assoc => assoc === props.original.association_number).length > 0 || allPermisions.clubs.filter(club => club === props.original.club_id).length > 0 || this.props.userType === "super-admin") {
						return <button className="btn fill green"
													 onClick={() => {
														 this.setState({selectedClub: props.original, expandedIndex: props.index});
														 this.props.updateSelectedMembership({membership: props.original, displayMembershipDetails: displayDetails});
														 if (displayDetails)
															 this.getOffers(props.original.club_id);
													 }}>Details</button>;
					} else {
						return null;
					}
				},
			}
		];

		const calculateAmount = (offer) => {
			return offer.offer_type === 'promotion' ? '-' : offer.discount_type === 'dollar' ? `$${offer.discount_value.toFixed(2)}` : `${offer.discount_value}%`
		};

		const offers_redeemed_columns = [{
			Header: "Date Redeemed", accessor: "transaction_created_at",
			Cell: props => {
				return <span>{moment(new Date(props.original.transaction_created_at), 'YYYY/MM/DD HH:mm:ss').format('MM/DD/YYYY')}</span>;
			}
		}, {
			Header: "Offer Code", accessor: "code"
		}, {
			Header: "Discount Amount", accessor: "discount_value", Cell: data => {
				return <span style={{display: "contents"}}>{calculateAmount(data.original)}</span>
			}
		}, {
			Header: "Club Discount?", accessor: "club_discount", Cell: data => {
				return <div>{data.original.offer_type === 'discount' ? (data.original.club_discount ? 'Yes' : 'No') : "-"}</div>
			}
		}]

		return (
            <Fragment>
				<div className="columns">
					<div className="row">
						<div className="col">
							<div className="panel">
								<div className="panel__head">Club Memberships</div>
								<div className="panel__body">
									<div className="row">
										<GhinTable
                      key={this.state.table_id}
											idKey={'id'}
											hidePagination={true}
											columns={columns}
											loading={this.state.loading}
											hideSelect={true}
											data={this.state.affiliations}
											ref={(r) => this.table = r}
											expandedIndex={this.state.expandedIndex}
											displayMembershipDetails={this.state.displayMembershipDetails}
											defaultSorted={[{
												id: 'SeasonName',
												desc: false
											}]}
											sortable={true}
											SubComponent={ row => {
								          return (
														<Fragment>
															<td colSpan={9} style={{padding: "10px"}}>
															<div className="row">
																<div className="col">
																	<div className="panel">
																		<div className="panel__head">
																			{row.original.club_name}&#xA0;-&#xA0;<span className="gray italic">{row.original.association_name}</span>
																			<div className='panel__end'>{row.original.status === "Active" ? (<span className="pill green sub-component">Active</span>): ''} {row.original.is_home_club  ? (<span className="pill blue sub-component">Home Club</span>): ''}</div>
																		</div>
																		<div className="panel__body">
                                    <div className="row">
																				<div className="col auto push-right" style={{'flexDirection': 'unset'}}>
                                            {(this.props.canEditClub && !this.props.publicView && !this.props.archived) &&
                                              <button onClick={() => this.setState({ selectedClub: row.original, EditClubMembershipModalIsOpen: true })} className="btn lnk">Edit</button>
                                            }
                                            {this.props.canDeleteAffiliations && this.state.affiliations.length > 1 && <button
                                              style={{ marginLeft: "30px" }}
                                              className="btn lnk red"
                                              onClick={() =>
                                                this.setState({
                                                  DeleteModalIsOpen: true,
                                                })
                                              }
                                            >
                                              Remove Club Affiliation
                                            </button>
                                            }
                                      </div>
																			</div>
																			<div className="row">
																				<div className="col jc-fs">
																					<div className="data-set__container">
																						<div className="data-set">
																							<span className="data-label">Association Number:</span>
																							<span>{row.original.association_number}</span>
																						</div>
																						<div className="data-set">
																							<span className="data-label">Association Name:</span>
																							<span>{row.original.association_name}</span>
																						</div>
																						<div className="data-set">
																							<span className="data-label">Club Number:</span>
																							<span>{row.original.club_id}</span>
																						</div>
																						<div className="data-set">
																							<span className="data-label">Club Name:</span>
																							<span>{row.original.club_name}</span>
																						</div>
																						<div className="data-set">
																							<span className="data-label">Membership Code:</span>
																							<span>{row.original.membership_code}</span>
																						</div>
																						<div className="data-set">
																							<span className="data-label">Local Number:</span>
																							<span>{row.original.local_number}</span>
																						</div>
																					</div>
																				</div>

																				<div className="col jc-fs">
																					<div className="data-set__container">
																						<div className="data-set">
																							<span className="data-label">Date Added to Association:</span>
																							<span>{row.original.date_added_to_association && moment(row.original.date_added_to_association).format('MM/DD/YYYY')}</span>
																						</div>
																						<div className="data-set">
																							<span className="data-label">Date Added to Club:</span>
																							<span>{row.original.date_added_to_club && moment(row.original.date_added_to_club).format('MM/DD/YYYY')}</span>
																						</div>
																						<div className="data-set">
																							<span className="data-label">Status Date:</span>
																							<span>{row.original.status_date && moment(row.original.status_date).format('MM/DD/YYYY')}</span>
																						</div>
																						{row.original.inactive_flag === "Activate" && <div className="data-set">
																							<span className="data-label">Membership Auto Start Date:</span>
																							<span>{row.original.inactive_date && moment(row.original.inactive_date).format('MM/DD/YYYY')}</span>
																						</div>}
																						{row.original.inactive_flag === "Inactivate" && row.original.inactive_date && <div className="data-set">
																							<span className="data-label">Membership Auto End Date:</span>
																							<span>{row.original.inactive_date && moment(row.original.inactive_date).format('MM/DD/YYYY')}</span>
																						</div>}
																						{row.original.club_membership_fee_id &&<div className="data-set">
																							<span className="data-label">Auto-Renew Enabled?</span>
																							<span>{row.original.auto_renew_enabled ? "Yes" : "No"}</span>
																						</div>}
																						{row.original.club_membership_fee_id && <div className="data-set">
																							<span className="data-label">Auto-Renew Date:</span>
																							<span>{row.original.auto_renew_date ? moment(row.original.auto_renew_date).format('MM/DD/YYYY') : "-"}</span>
																						</div>}
																						{row.original.hubspot_membership_id && <div className="data-set">
																							<span className="data-label">Hubspot Membership Id:</span>
																							<span>{row.original.hubspot_membership_id}</span>
																							{row.original.hubspot_membership_id != "-" &&
																								<span className="data-label">
																									<button onClick={() => { this.triggerHubspotUpdate(row.original.id) }} className="btn lnk">
																										Trigger Update
																									</button>
																								</span>
																							}
																						</div>}
																					</div>
																				</div>
																			</div>
																			{row.original.golfer_groups && <div className="row">
																				<div className="col is-1-of-2 centered">
																					<table className="data__table normal-overflow">
																						<thead>
																						<tr ref={(el) => el && el.style.setProperty("background-color", "#00365f", "important")}>
																							<th>Golfer Groups</th>
																						</tr>
																						</thead>
																						<tbody>
																							{row.original.golfer_groups.length > 0  && row.original.golfer_groups.map(group => {
																								return (
																									<tr>
																										<td>
																											{group.name}
																										</td>
																									</tr>);
																								})
																							}
																						</tbody>

																					</table>
																				</div>
																			</div>}
																			<hr style={{border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px'}}></hr>
																			<div>
																				<div className="data-set__container">
																					<div className="data-set">
																						<span className="data-label" style={{marginLeft: "30px"}}>Offers Redeemed</span>
																					</div>
																					<div className="data-set">
																						<span style={{paddingLeft:0, marginLeft: "30px"}}>The following codes have been redeemed by this golfer at this club.</span>
																					</div>
																				</div>
																				<br></br>
																				<div className="col is-5-of-9 centered">
																					<GhinTable
																						idKey={'id'}
																						hideSelect={true}
																						sortable={false}
																						hidePagination={false}
																						columns={offers_redeemed_columns}
																						data={this.state.offers}
																						loading={this.state.offersLoading}
																						pages={this.state.pages}
																						page={this.state.page}
																						total={this.state.total}
																						pageSize={this.state.perPage}
																						manual
																						onPageChange={(index) => {this.setState({page: index});}}
																						onPageSizeChange={(size, index) => {
																							this.setState({perPage: size, page: index})
																						}}
																						onSortedChange={(newSorted) => {
																							this.setState({page: 0})
																						}}
																					/>
																				</div>
																				<br></br>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</td>
														</Fragment>
								          );
							        	}
											}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<ConfirmationModalTypeB
					question={`Change Home Club?`}
					modalIcon={"help_outline"}
					confirmLabel={'Submit'}
					cancelLabel={'Cancel'}
					bodyQuestion={`Are you sure you want change the home club for ${this.props.golfer.player_name} to ${this.state.setAsHomeClub ? this.state.setAsHomeClub.club_name : ''} ?`}
					onCancelAction={() => this.setState({confirmationModal: false})}
					onConfirmAction={this.changeHomeClub}
					openModal={this.state.confirmationModal}
					closeModal={() => this.setState({confirmationModal: false})}
				/>

				<ConfirmationModalTypeB
					question={`Inactive Home Club`}
					modalIcon={"error_outline"}
					confirmLabel={'Ok'}
					bodyQuestion={`The current home club is inactive. Please change the home club with an active one.`}
					onConfirmAction={() => this.setState({inactiveHomeClubModal: false})}
					openModal={this.state.inactiveGolfer ? false : this.state.inactiveHomeClubModal}
					closeModal={() => this.setState({inactiveHomeClubModal: false})}
				/>

				<Modal
					isOpen={this.state.EditClubMembershipModalIsOpen}
					onRequestClose={()=>{ this.setState({EditClubMembershipModalIsOpen: false})}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditClubMembershipModal clubMembership={this.state.selectedClub}
											 golfer={this.props.golfer}
											 canEditClub={this.props.canEditClub}
											 canEditAssociation={this.props.canEditAssociation}
											 closeModal={(data)=>{
											 	if(data) {
											 		this.setState({
														EditClubMembershipModalIsOpen: false,
													 }, ()=> {this.getAffiliations(); this.props.updateGolfer(); })
											 	} else {
													this.setState({EditClubMembershipModalIsOpen: false});
												}
											 }}
                         reloadAffiliations = {()=>{this.getAffiliations(); this.setState({table_id: this.state.table_id+1, displayMembershipDetails: false});}}
                       />
				</Modal>

        <ConfirmationModal openModal={this.state.DeleteModalIsOpen}
          onConfirmAction={() => { this.onDelete() }}
          onCancelAction={() => { this.setState({ DeleteModalIsOpen: false }) }}
          closeModal={() => { this.setState({ DeleteModalIsOpen: false }) }}
          question={"Are you sure you want to remove this club affiliation?"}
          cancelLabel={"No"}
          confirmLabel={"Yes"} />

            </Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		rawAccess: state.user.rawAccess,
		userType: state.user.access.user_type,
		selectedClub: state.membership,
		displayMembershipDetails: state.membership.displayMembershipDetails,
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({updateSelectedMembership, clearSelectedMembership, addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ClubMembership);
