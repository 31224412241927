import React, {Component, Fragment} from 'react';
import Modal from "react-modal";
import {addAlert} from "../../shared/actions";
import GhinTable from "../../shared/GhinTable";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import EditGuardianModal from "../../Modals/EditGuardianModal";
import GuardianCreateDigitalProfileLinkModal from "../../Modals/GuardianCreateDigitalProfileLinkModal";
import GuardianResetDigitalProfilePasswordLinkModal from "../../Modals/GuardianResetDigitalProfilePasswordLinkModal";
import moment from "moment";
import GolferService from "../../../services/api/golfer";
import GuardianService from "../../../services/api/guardian";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import ConfirmationModalTypeB from "../../Modals/ConfirmationModalTypeB";
import SharedService from "../../../services/api/shared";
import {formatPhoneNumber} from "../../utils";
const generateGolferName = (row) => {
	return [row.prefix, row.first_name, row.middle_name, row.last_name, row.suffix].join(" ");
};

class PrimaryGuardian extends Component {


	constructor(props) {
		super(props);
		this.state = {
			EditGuardian: undefined,
			digitalProfile: null,
			digitalProfileEmails: [],
			loadingDigitalProfileEmails: true,
			page: 1,
			per_page: 5,
			total: 0,
			pages: 0,
			sendInvitationModal: false,
			resetPasswordModal: false,
			createDigitalProfileLinkModal: false,
			resetDigitalProfilePasswordLinkModal: false,
			digitalProfileLinkError: null,
			removeDigitalProfileModal: false,
			removeDigitalProfileError: null
		}
		this.sendInvitation = this.sendInvitation.bind(this);
		this.resendInvitation = this.resendInvitation.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
		this.removeDigitalProfile = this.removeDigitalProfile.bind(this);
		this.generateCreateDigitalProfileLink = this.generateCreateDigitalProfileLink.bind(this);
		this.generateResetDigitalProfilePasswordLink = this.generateResetDigitalProfilePasswordLink.bind(this);
	}

	componentDidMount() {
		Modal.setAppElement('body');
		this.getDigitalProfile();
		this.getDigitalProfileEmails();
	}

	getDigitalProfile(){
		GuardianService.getDigitalProfile(this.props.guardian.id)
			.then(res => {
				this.setState({digitalProfile: res.digital_profile});
			})
			.catch(err => {
				console.error(err)
			})
	}

	getDigitalProfileEmails(){
		this.setState({
			loadingDigitalProfileEmails: true,
		})
		GolferService.getDigitalProfileEmails(this.state.page, this.state.per_page, this.props.guardian.email)
			.then(res => {
				this.setState({
					digitalProfileEmails: res.digital_profile_emails,
					loadingDigitalProfileEmails: false,
					total: parseInt(res['total-count'], 10),
					per_page: parseInt(res['per-page'], 10),
					pages: parseInt(res['total-pages'], 10),
				});
			})
			.catch(err => {
				this.setState({
					loadingDigitalProfileEmails: false,
				});
				console.error(err)
			})
	}

	getPage(){
		this.setState({
			loadingDigitalProfileEmails: true
		});
		this.getDigitalProfileEmails();
	}

	sendInvitation() {
		GuardianService.sendInvitation(this.props.guardian.id)
			.then(res => {
				this.getDigitalProfile();
				this.setState({sendInvitationModal: false})
				this.props.addAlert({
					type:'success',
					message:'Invitation email has been successfully sent.'
				})
			})
			.catch(err => {
				this.setState({sendInvitationModal: false})
				if(err.data.errors && err.data.errors.digital_profile && err.data.errors.digital_profile.length > 0) {
					this.setState({
						invitationsError: err.data.errors.digital_profile[0]
					})
				}
				console.log(err)
			})
	}

	resendInvitation() {

		let resendData = {};
		if (this.props.guardian.golfer_id) {
			if (this.props.guardian.email) {
				resendData['email'] = this.props.guardian.email;
			} else {
				resendData['last_name'] = this.props.guardian.last_name;
			}
			resendData['ghin_number'] = this.props.guardian.golfer_id;
		} else {
			resendData['last_name'] = this.props.guardian.last_name;
			resendData['email'] = this.props.guardian.email;
		}

		SharedService.resendDigitalProfileInvitationEmail(resendData)
			.then(res => {
				this.getDigitalProfile();
				this.setState({resendInvitationModal: false});
				this.props.addAlert({
					type:'success',
					message:'Invitation email has been successfully sent.'
				})
			})
			.catch(err => {
				this.setState({resendInvitationModal: false});
				if(err.data.errors && err.data.errors.digital_profile && err.data.errors.digital_profile.length > 0) {
					this.setState({
						invitationsError: err.data.errors.digital_profile[0]
					})
				}
				console.log(err)
			})
	}

	resetPassword() {
		GolferService.sendResetPassword(this.props.guardian.email)
			.then(res => {
				this.props.addAlert({
					type:'success',
					message:'Email has been successfully sent.'
				})
				this.getDigitalProfile();
				this.setState({resetPasswordModal: false})
			})
			.catch(err => {
				console.error(err);
			})
	}

	removeDigitalProfile() {
			GuardianService.removeDigitalProfile(this.props.guardian.id)
				.then(res => {
					this.getDigitalProfile();
					this.props.addAlert({
						type:'success',
						message:'Digital Profile has been successfully removed.'
					})
					this.setState({removeDigitalProfileModal: false})
				})
				.catch(err => {
					this.setState({removeDigitalProfileModal: false})
					if(err.data.errors && err.data.errors.digital_profile && err.data.errors.digital_profile.length > 0) {
						this.setState({
							removeDigitalProfileError: err.data.errors.digital_profile[0]
						})
					}
					console.log(err)
				})
	}

	generateCreateDigitalProfileLink() {
		GuardianService.generateInvitationLink(this.props.guardian.id)
			.then((response) => {
				const generated_url = response.digital_profile.generated_url;
				this.setState({
					generatedUrl: generated_url,
					createDigitalProfileLinkModal: true
				});
			})
			.catch((error) => {
				console.error(error);
				this.setState({digitalProfileLinkError: error.data.errors.digital_profile[0]});
			});
	}

	generateResetDigitalProfilePasswordLink() {
		GuardianService.generateResetPasswordLink(this.props.guardian.id)
			.then((response) => {
				const generated_url = response.digital_profile.generated_url;
				this.setState({
					generatedUrl: generated_url,
					resetDigitalProfilePasswordLinkModal: true
				});
			})
			.catch((error) => {
				console.error(error);
				this.setState({digitalProfileLinkError: error.data.errors.digital_profile[0]});
			});
	}

	//todo: what to do with permisions
	render() {
		const guardian = this.props.guardian;
		const digitalProfile = this.state.digitalProfile;
		const digitalProfileEmailsColumns = [
			{
				Header: 'Date/Time Sent',
				accessor: 'date_sent',
				Cell: props => moment(new Date(props.original.date_sent), 'YYYY/MM/DD HH:mm:ss').format('MM/DD/YYYY HH:mm:ss'),
			},
			{
				Header: 'Email Type',
				accessor: 'template_type',
			},
			{
				Header: 'Status',
				accessor: 'status',
			},
			{
				Header: 'Error Message',
				accessor: 'error_message',
			},
		];
		return (
			<Fragment>

				{guardian && <div className="columns">
					<div className="row">
						<div className="col">
							<div className="panel">
								<div className="panel__head">Basic Information</div>
								<div className="panel__body">
									{(this.props.accessLevel > 1 && <div className="row">
										<div className="col auto push-right">
											<button onClick={() => this.setState({EditGuardian: true})} className="btn lnk">Edit</button>
										</div>
									</div>)}
									<div className="row">
										<div className="col jc-fs">
											<div className="data-set__container">
												<div className="data-set">
													<span className="data-label">Name:</span>
													<span>{generateGolferName(guardian)}</span>
												</div>

												{this.props.accessLevel > 0 && <div className="data-set">
													<span className="data-label">Phone Number:</span>
													<span>{formatPhoneNumber(guardian.phone_number)}</span>
												</div>}
												{this.props.accessLevel > 0 && <div className="data-set">
													<span className="data-label">Email Address:</span>
													<span>{guardian.email}</span>
												</div>}
											</div>
										</div>
									</div>
								</div>
							</div>
							{digitalProfile && <div className="panel">
								<div className="panel__head">Digital Profile Information</div>
								<div className="panel__body">
									<div className="row">
										<div className="col is-2-of-3 jc-fs">
											<div className="data-set__container">
												<div className="data-set">
													<span className="data-label">Golfer Profile Creation Date:</span>
													<span>{digitalProfile.creation_date && digitalProfile.creation_date !== '-' ? moment.utc(digitalProfile.creation_date, 'YYYY-MM-DD HH:mm:ss').local().format('MM/DD/YYYY HH:mm:ss') : '-'}</span>
												</div>
												<div className="data-set">
													<span className="data-label">Initial Invitation Sent Date:</span>
													<span>{digitalProfile.sent_invite_for_digital_profile_on && digitalProfile.sent_invite_for_digital_profile_on !== '-' ? moment.utc(digitalProfile.sent_invite_for_digital_profile_on, 'YYYY-MM-DD HH:mm:ss').local().format('MM/DD/YYYY HH:mm:ss') : '-'}</span>
												</div>
												<div className="data-set">
													<span className="data-label">Last Login (Includes Admin Portal & Golfer Products):</span>
													<span>{digitalProfile.last_login && digitalProfile.last_login !== '-' ? moment.utc(digitalProfile.last_login, 'YYYY-MM-DD HH:mm:ss').local().format('MM/DD/YYYY HH:mm:ss') : '-'}</span>
												</div>
												<div className="data-set">
													<span className="data-label">GHIN Terms of Service Accepted (for Golfer Products):</span>
													<span>{digitalProfile.tos_accepted_date && digitalProfile.tos_accepted_date !== '-' ? moment.utc(digitalProfile.tos_accepted_date, 'YYYY-MM-DD HH:mm:ss').local().format('MM/DD/YYYY HH:mm:ss') : '-'}</span>
												</div>
												<div className="data-set">
													<span className="data-label">GHIN Privacy Policy Accepted (for Golfer Products):</span>
													<span>{digitalProfile.privacy_policy_accepted_date && digitalProfile.privacy_policy_accepted_date !== '-' ? moment.utc(digitalProfile.privacy_policy_accepted_date, 'YYYY-MM-DD HH:mm:ss').local().format('MM/DD/YYYY HH:mm:ss') : '-'}</span>
												</div>
											</div>
										</div>
										<div className="col is-1-of-3 ai-fe push-right">
											{digitalProfile.display_send_invitation_button === "true" && <div className={"row"}>
												<button className={'btn fill green'} onClick={()=>{this.setState({sendInvitationModal: true})}}>Send Invitation to Create a Digital Profile</button>
											</div>}
											{digitalProfile.display_send_reset_password_email_button === "true" &&
												<div>
													<div className={"row col is-full"}>
														<button className={'btn fill green'} onClick={()=>{this.setState({resetPasswordModal: true})}}>Send Reset Password Email</button>
													</div>
													<div className={"row col is-full"}>
														<button className={'btn fill green'} onClick={()=>{this.generateResetDigitalProfilePasswordLink()}}>Generate “Reset Digital Password” Link</button>
													</div>
													{
														(this.props.user_type === 'super-admin' || this.props.user_type === 'association-admin') &&
														<div className={"row col is-full"}>
															<button className={'btn fill green'} onClick={()=>{this.setState({removeDigitalProfileModal: true})}}>Remove Digital Profile</button>
														</div>
													}
												</div>
											}
											{digitalProfile.display_resend_invitation_button === "true" &&
												<div>
													<div className={"row col is-full"}>
														<button className={'btn fill green'} onClick={()=>{this.setState({resendInvitationModal: true})}}>Resend Invitation To Create A Digital Profile</button>
													</div>
													<div className={"row col is-full"}>
														<button className={'btn fill green'} onClick={()=>{this.generateCreateDigitalProfileLink()}}>Generate “Digital Profile Creation” Link</button>
													</div>
													{
														(this.props.user_type === 'super-admin' || this.props.user_type === 'association-admin')  &&
														<div className={"row col is-full"}>
															<button className={'btn fill green'} onClick={()=>{this.setState({removeDigitalProfileModal: true})}}>Remove Digital Profile</button>
														</div>
													}
												</div>
											}
										</div>
									</div>
									<div className={'row'}>
										<div className={'col'}>
											<div style={{display:'flex', flexDirection:'row', alignItems: 'center'}}>
												<span className={"red"}>Please note, there may be a slight delay in updating the table.</span>
												<i  className="material-icons-outlined" style={{cursor: 'pointer'}} onClick={() =>{this.getDigitalProfile(); this.getDigitalProfileEmails()}}>refresh</i>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col jc-fs">
											<GhinTable
												loading={this.state.loadingDigitalProfileEmails}
												columns={digitalProfileEmailsColumns}
												data={this.state.digitalProfileEmails}
												ref={(r) => this.table = r}
												hideSlelectAll={true}
												hideSelect
												sortable={false}
												defaultPageSize={5}
												pages={this.state.pages}
												total={this.state.total}
												manual={true}
												pageSize={this.state.per_page}
												onPageChange={(pageIndex) => this.setState({page: pageIndex+1}, () =>  this.getPage())}
												onPageSizeChange={(pageSize, pageIndex) => this.setState({
													per_page: pageSize,
													page: pageIndex + 1
												}, () => this.getPage())}
											/>
										</div>
									</div>
								</div>
							</div>}
						</div>
					</div>
				</div>}

				{
					this.state.invitationsError ? (<ConfirmationModal openModal={this.state.invitationsError}
																	  onConfirmAction={() =>  this.setState({invitationsError: null})}
																	  body={`${this.state.invitationsError.body_line1 || ''}\n\n${this.state.invitationsError.body_line2 || ''}`}
																	  onCancelAction={()=>{this.setState({invitationsError: null})}}
																	  closeModal={()=>{this.setState({invitationsError: null})}}
																	  question={this.state.invitationsError.top_line}
																	  cancelLabel={"Cancel"}
																	  confirmLabel={"OK"}/>) : null
				}

				<ConfirmationModalTypeB
					               wideClass={'narrow'}
					               openModal={this.state.sendInvitationModal}
								   onConfirmAction={this.sendInvitation}
								   onCancelAction={()=>{this.setState({sendInvitationModal: null})}}
								   closeModal={()=>{this.setState({sendInvitationModal: null})}}
								   question={`Are you sure you want to send an email to invite ${guardian.first_name} ${guardian.last_name} to create a digital profile?`}
								   cancelLabel={"No"}
								   confirmLabel={"Yes"}
								   modalIcon={"help_outline"}
				/>

				<ConfirmationModalTypeB
					wideClass={'narrow'}
					openModal={this.state.resendInvitationModal}
					onConfirmAction={this.resendInvitation}
					onCancelAction={()=>{this.setState({resendInvitationModal: null})}}
					closeModal={()=>{this.setState({resendInvitationModal: null})}}
					question={`Are you sure you want to resend an email to invite ${guardian.first_name} ${guardian.last_name} to create a digital profile?`}
					cancelLabel={"No"}
					confirmLabel={"Yes"}
					modalIcon={"help_outline"}
				/>

				<ConfirmationModalTypeB
					wideClass={'narrow'}
					openModal={this.state.resetPasswordModal}
					onConfirmAction={this.resetPassword}
					onCancelAction={()=>{this.setState({resetPasswordModal: null})}}
					closeModal={()=>{this.setState({resetPasswordModal: null})}}
					question={`Are you sure you want to send reset password email to ${guardian.first_name} ${guardian.last_name}?`}
					cancelLabel={"No"}
					confirmLabel={"Yes"}
					modalIcon={"help_outline"}
				/>

				<ConfirmationModalTypeB
					wideClass={'narrow'}
					openModal={this.state.removeDigitalProfileModal}
					onConfirmAction={this.removeDigitalProfile}
					onCancelAction={()=>{this.setState({removeDigitalProfileModal: false})}}
					closeModal={()=>{this.setState({removeDigitalProfileModal: false})}}
					question={`Are you sure you want to remove the digital profile for ${this.props.guardian.first_name} ${this.props.guardian.last_name}?`}
					cancelLabel={"No"}
					confirmLabel={"Yes"}
					modalIcon={"help_outline"}
				/>

				{
					this.state.removeDigitalProfileError ?

					(<ConfirmationModal openModal={this.state.removeDigitalProfileError}
							onConfirmAction={() => {this.setState({removeDigitalProfileError: null}); this.getDigitalProfile();}}
							body={`${this.state.removeDigitalProfileError.body_line1 || ''}\n\n${this.state.removeDigitalProfileError.body_line2 || ''}`}
							onCancelAction={()=>{this.setState({removeDigitalProfileError: null}); this.getDigitalProfile();}}
							closeModal={()=>{this.setState({removeDigitalProfileError: null});  this.getDigitalProfile();}}
							question={this.state.removeDigitalProfileError.top_line}
							cancelLabel={"Cancel"}
							confirmLabel={"OK"}
						/>) : null
				}

				<Modal
					isOpen={this.state.EditGuardian}
					onRequestClose={()=>this.setState({EditGuardian: undefined})}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditGuardianModal
						title={'Edit Guardian Account'}
						guardian={guardian}
						closeModal={() => {
							this.setState({
								EditGuardian: undefined
							});
							this.props.updateGuardian();
						}}
					/>
				</Modal>

				<Modal
					isOpen={this.state.createDigitalProfileLinkModal}
					onRequestClose={()=>this.setState({createDigitalProfileLinkModal: undefined})}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<GuardianCreateDigitalProfileLinkModal generatedUrl={this.state.generatedUrl} closeModal={()=>this.setState({createDigitalProfileLinkModal: undefined})} />
				</Modal>

				<Modal
					isOpen={this.state.resetDigitalProfilePasswordLinkModal}
					onRequestClose={()=>this.setState({resetDigitalProfilePasswordLinkModal: undefined})}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<GuardianResetDigitalProfilePasswordLinkModal generatedUrl={this.state.generatedUrl} closeModal={()=>this.setState({resetDigitalProfilePasswordLinkModal: undefined})} />
				</Modal>

				{
					this.state.digitalProfileLinkError ?

					(<ConfirmationModal openModal={this.state.digitalProfileLinkError}
							onConfirmAction={() =>  this.setState({digitalProfileLinkError: null})}
							body={`${this.state.digitalProfileLinkError.body_line1 || ''}\n\n${this.state.digitalProfileLinkError.body_line2 || ''}`}
							onCancelAction={()=>{this.setState({digitalProfileLinkError: null})}}
							closeModal={()=>{this.setState({digitalProfileLinkError: null})}}
							question={this.state.digitalProfileLinkError.top_line}
							cancelLabel={"Cancel"}
							confirmLabel={"OK"}
						/>) : null
				}
			</Fragment>
		);
	}
}
function mapStateToProps(state){
	return {
		user_type: state.user.access.user_type
	}
}
function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryGuardian);
