import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip'
import moment from 'moment';
import ModifyHandicapIndexModal from '../Modals/ModifyHandicapIndexModal';
import ModifyLowHandicapIndexModal from '../Modals/ModifyLowHandicapIndexModal';
import SpecialUpdateModal from '../Modals/SpecialUpdateModal';
import GolferService from "../../services/api/golfer";
import HandicapIndexHistoryChart from './HandicapIndexHistoryChart';
import ConfirmationModal from "../Modals/ConfirmationModal";
import GhinTable from "../shared/GhinTable";
import WithdrawHandicapIndexModal from "../Modals/WithdrawHandicapIndexModal";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { getCleanName } from '../utils'

class HandicapManagement extends Component {

    constructor(props) {
		super(props);

		this.state = {
            modifyHandicapIndexModalIsOpen: false,
            removeHandicapIndexModalIsOpen: false,
			modifyLowHandicapIndexModalIsOpen: false,
			removeLowHandicapIndexModalIsOpen: false,
            specialUpdateModalIsOpen: false,
            golfer: null,
            loading: true,
            handicap_revisions: [],
			sort : {
            	property: "",
				descending: true
			},
			scores: []
		};

        this.openModifyHandicapIndexModal = this.openModifyHandicapIndexModal.bind(this);
        this.openRemoveHandicapIndexModal = this.openRemoveHandicapIndexModal.bind(this);
		this.openModifyLowHandicapIndexModal = this.openModifyLowHandicapIndexModal.bind(this);
		this.openRemoveLowHandicapIndexModal = this.openRemoveLowHandicapIndexModal.bind(this);
        this.openSpecialUpdateModal = this.openSpecialUpdateModal.bind(this);
		this.openWithdrawHandicapIndexModal = this.openWithdrawHandicapIndexModal.bind(this);
		this.openRemoveWithdrawHandicapIndexModal = this.openRemoveWithdrawHandicapIndexModal.bind(this);
	}


	componentDidMount() {
		Modal.setAppElement('body');
		this.processScores();
	}

	processScores() {
    let {golfer_id} = this.props.props.match.params;
		if(!golfer_id.match(/^[0-9]*$/)) {
			golfer_id = window.atob(golfer_id);
		}
		GolferService.getScores(golfer_id).then(res => {
			let sum = 0;
			res.scores.forEach(score => {
				if(score.used)
					sum += score.adjusted_scaled_up_differential 
						? score.adjusted_scaled_up_differential 
						: score.differential
				score.pcc = score.pcc ? score.pcc : "-";
			});
			this.setState({
				scores: res.scores.map((item) => {
					return {
						...item,
						name:  getCleanName({...item, ...{placeholder:true}}),
						sortable_date: moment(item.played_at).unix(),
            course_and_slope_rating: item.course_rating.toFixed(1) * 10 + item.slope_rating.toFixed(1) / 1000,
						date: this.props.publicView ? moment(item.played_at).format("MM/YYYY") : moment(item.played_at).format("MM/DD/YYYY"),
				}
				}),
				totalDifference: sum.toFixed(1)
			});
		}).catch(error => {
			console.error(error)
		});

		GolferService.getHandicapHistoryLastYear(golfer_id).then((data) => {
			this.setState({
				handicap_revisions: data.handicap_revisions.map((item) => {
					return {
						...item,
						Value: item.Value === "99.9" ? "0" : item.Value
					}
				}).reverse(),
				loading: false
			});
		});
	}

	performSpecialUpdate() {
    	return GolferService.specialUpdate(this.props.golfer.club_id, [this.props.golfer.id])
	}

    openModifyHandicapIndexModal() {
		this.setState({modifyHandicapIndexModalIsOpen: true});
    }

    openRemoveHandicapIndexModal() {
		this.setState({removeHandicapIndexModalIsOpen: true});
    }

	openModifyLowHandicapIndexModal() {

		this.setState({modifyLowHandicapIndexModalIsOpen: true});
	}

	openRemoveLowHandicapIndexModal() {
		this.setState({removeLowHandicapIndexModalIsOpen: true});
	}

	openWithdrawHandicapIndexModal() {
		this.setState({withdrawHandicapIndexModalIsOpen: true});
	}

	openRemoveWithdrawHandicapIndexModal() {
		this.setState({removeWithdrawHandicapIndexModalIsOpen: true});
	}

	removeModification(type) {
    	GolferService.removeHandicapModification(this.props.golfer.id, type)
			.then(res => {
				this.props.addAlert({type: 'success', message: `The ${type === "low_hi" ? "Low H.I." : "Handicap Index"} modification has been successfully removed!`});
				this.props.updateGolfer();
			})
			.catch(err => {
        Object.keys(err.data.errors).forEach((key) => {
          this.props.addAlert({
            type: 'error',
            message: err.data.errors[key]
          })
        })

        this.props.updateGolfer()
        console.log(err);
			})
	}

    openSpecialUpdateModal() {
		this.setState({specialUpdateModalIsOpen: true});
	}

	closeModal(data) {
    	if(data) {
    		this.props.updateGolfer(data);
		}
		this.setState({
            modifyHandicapIndexModalIsOpen: false,
            removeHandicapIndexModalIsOpen: false,
			modifyLowHandicapIndexModalIsOpen: false,
			removeLowHandicapIndexModalIsOpen: false,
            specialUpdateModalIsOpen: false,
			withdrawHandicapIndexModalIsOpen: false,
			removeWithdrawHandicapIndexModalIsOpen: false
        });
	}

	render() {
		const columns = [
			{
				Header: '', //Old header: Used
				accessor: 'used',
				Cell: props => <Fragment>{props.original.used && <i className="material-icons-outlined blue td-align-center small">star_outline</i>}</Fragment>,
				thClassName: 'table-align-center is-1-of-7',
			},
			{
				Header: 'Type',
				accessor: 'score_type_display_full',
				className: 'table-align-center',
				thClassName: 'table-align-center is-1-of-5'
			},
			{
				Header: 'Date',
				accessor: 'sortable_date',
				Cell: props => props.original.date,
				className: 'table-align-center',
				thClassName: 'table-align-center is-2-of-5'
			},
			{
				Header:
				<Fragment>
				   Score
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='score'><i className="material-icons v-mid m-left white small">info</i></a>
					<ReactTooltip id='score' className="table-head__tooltip" type="light" place='top' effect='float' >
					The number of holes played is in parentheses. For 9-hole scores or a score based on less than 18 holes played, the score received an 18-hole Score Differential based on the number of holes played and expected score for the holes not played.
					</ReactTooltip>
				</Fragment>,
				accessor: 'adjusted_gross_score',
				Cell: props => 
				<Fragment>
					{props.original.adjusted_gross_score} 
					{props.original.adjusted_scaled_up_differential != null && 
						props.original.number_of_holes != 9 && 
						<a className='score-number_of_played_holes'>
							{" ("}{props.original.number_of_played_holes}{")"}
						</a>
					}
				</Fragment>,
				className: 'table-align-center',
				thClassName: 'table-align-center is-1-of-4'
			},
			{
				Header: 'C.R./Slope',
				accessor: 'course_and_slope_rating',
				Cell: props => <Fragment>{parseFloat(props.original.course_rating).toFixed(1)} / {props.original.slope_rating}</Fragment>,
				className: 'table-align-center',
				thClassName: 'table-align-center is-1-of-2'
			},
			{
				Header: <Fragment>
					PCC
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='pcc'><i className="material-icons v-mid m-left white small">info</i></a>
					<ReactTooltip id='pcc' className="table-head__tooltip" type="light" place='top' effect={'float'}>
						<strong>Playing Conditions Calculation </strong>
						The playing conditions calculation determines whether playing conditions on the day differed from normal conditions to the extent that an adjustment is needed to compensate. It is a statistical procedure that compares the scores submitted by players on the day against expected scoring patterns.
					</ReactTooltip>
				</Fragment>,
				Cell: props => <Fragment>{props.original.adjustments.filter(a => a.type === 'pcc').length > 0 ? props.original.adjustments.filter(a => a.type === 'pcc')[0].display : '-'}</Fragment>,
				accessor: 'pcc',
				sortable: false,
				className: 'table-align-center',
				thClassName: 'table-align-center is-1-of-3'
			},
			{
				id: 'diff',
				Header: <Fragment>
					Diff.
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='diff'><i className="material-icons v-mid m-left white small">info</i></a>
					<ReactTooltip id='diff' className="table-head__tooltip" type="light" place='top' effect={'float'}>
						<strong>Score Differential </strong> = (113/Slope Rating) * (Adjusted Gross Score – Course Rating – PCC). ESR and Committee Adjustments are applied to the previously calculated score differential.
					</ReactTooltip>
				</Fragment>,
				Cell: props => props.original.adjusted_scaled_up_differential == null
					? parseFloat(props.original.differential).toFixed(1)
					: parseFloat(props.original.adjusted_scaled_up_differential).toFixed(1),
				accessor: row => {
					return row.adjusted_scaled_up_differential !== null
						? parseFloat(row.adjusted_scaled_up_differential)
						: parseFloat(row.differential);
				},
				className: 'table-align-center',
				thClassName: 'table-align-center is-1-of-3'
			},
			{
				Header: <Fragment>
					ESR
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='esr'><i className="material-icons v-mid m-left white small">info</i></a>
					<ReactTooltip id='esr' className="table-head__tooltip" type="light" place='top'effect={'float'}>
						<strong>Exceptional Score Reduction </strong>
						When an exceptional score is posted to a player’s scoring record, the Handicap Index will be reduced in accordance with the exceptional score adjustment table.
					</ReactTooltip>
				</Fragment>,
				accessor: 'esr',
				sortable: false,
				Cell: props => <Fragment>{props.original.adjustments.filter(a => a.type === 'esr').length > 0 ? props.original.adjustments.filter(a => a.type === 'esr')[0].value : '-'}</Fragment>,
				thClassName: 'table-align-center is-1-of-3',
				className: 'table-align-center'
			},
			{
				Header: <Fragment>
					Adj.
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='adj'><i className="material-icons v-mid m-left white small">info</i></a>
					<ReactTooltip  id='adj' className="table-head__tooltip" type="light" place='top' effect={'float'}>
						<strong>Committee Adjustment</strong> When an adjustment is applied by the Handicap Committee, it is intended to provide a better reflection of a player’s demonstrated ability.
					</ReactTooltip>
				</Fragment>,
				accessor: 'adj',
				sortable: false,
				Cell: props => <Fragment>{props.original.adjustments.filter(a => a.type === 'committee').length > 0 ? props.original.adjustments.filter(a => a.type === 'committee')[0].value : '-'}</Fragment>,
				thClassName: 'table-align-center is-1-of-3',
				className: 'table-align-center '
			},
			{
				Header: 'Course Name / Tee',
				accessor: 'name',
				show: !this.props.publicView,
				thClassName: 'table-align-center',
				Cell: props => <Fragment>{getCleanName(props.original)}</Fragment>
			},
		];
		return (
            <Fragment>

                <div className="panel">
                    <div className="panel__head">
                        {this.props.golfer.club_name} - {this.props.golfer.association_name}
                        <div className="panel__end">
                            <span className="status-state info">
                                {/* <i className="material-icons v-mid small red">star</i> */}
                                {/* Handicap Index was Modified on 03/13/2018 by USGA Admin */}
                            </span>
                        </div>
                    </div>
                    <div className="panel__body">

                        <div className="row">

                            <div className="col is-full">

								{(process.env.REACT_APP_TYPE !== "hv" && !this.props.publicView) && !this.props.archived && this.props.canEditClub &&
								<div className="row">
									{!this.props.golfer.hi_modified  ?
										<div className="col auto push-right"><button onClick={this.openModifyHandicapIndexModal} className="btn fill green">Modify H.I.</button></div>
										:
										<div className="col auto push-right"><button onClick={this.openRemoveHandicapIndexModal} className="btn fill green">Remove H.I. Modification</button></div>
									}
									{(this.props.golfer.low_hi !== "-" && this.props.golfer.low_hi !== "WD") && (!this.props.golfer.low_hi_modified  ?
										<div className="col auto"><button onClick={this.openModifyLowHandicapIndexModal} className="btn fill green">Modify Low H.I.</button></div>
										:
										<div className="col auto"><button onClick={this.openRemoveLowHandicapIndexModal} className="btn fill green">Remove Low H.I. Modification</button></div>)
									}
									{this.props.golfer.hi_withdrawn ?
										<div className="col auto"><button onClick={this.openRemoveWithdrawHandicapIndexModal} className="btn fill green">Remove Withdraw H.I.</button></div>
										:
										<div className="col auto"><button onClick={this.openWithdrawHandicapIndexModal} className="btn fill green">Withdraw H.I.</button></div>
									}
                                    <div className="col auto"><button onClick={this.openSpecialUpdateModal} className="btn fill green">Special Update</button></div>
                                </div>}

                                <div className="row">
                                    <div className="col">
                                        <h2 className="section__title">Revision Scores</h2>
                                    </div>
                                    <div className="col jc-c auto white-space push-right">
                                        Total Differential: {this.state.totalDifference}
                                    </div>
                                </div>

								<div className="row">
									<div className="col">
										<GhinTable
											loading={this.state.loading}
											columns={columns}
											ref={(r) => this.table = r}
											hideSelect
											hidePagination={true}
											data={this.state.scores}
											sortable={true}
										/>
										<div className="row">
											<div className="col">
												<ul className="table__legend">
													<li>
														Score Type: <span>H</span> - Home, <span>A</span> - Away, <span>C</span> - Competition, <span>N</span> - { this.props.golfer.use_scaling ? '' : 'Combined' } 9-Hole Rounds, <span>P</span> - Penalty, <span>E</span> - Exceptional
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
                                <div className="row">
                                    <div className="col">
                                        <h2 className="section__title">Handicap Index History</h2>
                                    </div>
									{process.env.REACT_APP_TYPE !== "hv" && <div className={"col auto-size"}>
                                        <div className="panel__end">
                                            <span className="status-state info" style={{justifyContent: 'flex-end'}}>
                                                <i className="material-icons v-mid small red">star</i>
                                                <span>Handicap History displays the Handicap Index of golfers based on the Date Played for each score in the player's record.</span>
                                            </span>
                                        </div>
                                    </div>}
                                </div>
                                <div className="row">
                                    <div className="col" style={{maxWidth: '100%'}}>
                                        {this.state.handicap_revisions.length > 0 ?
                                            <HandicapIndexHistoryChart
                                                handicapRevisions={this.state.handicap_revisions}/> :
                                            <span>No Handicap History</span>}
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>

                <Modal
					isOpen={this.state.modifyHandicapIndexModalIsOpen}
					onRequestClose={() => {this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<ModifyHandicapIndexModal golfer={this.props.golfer} closeModal={(data) => {this.closeModal(data)}} />
				</Modal>

				<Modal
					isOpen={this.state.modifyLowHandicapIndexModalIsOpen}
					onRequestClose={() => {this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<ModifyLowHandicapIndexModal golfer={this.props.golfer} closeModal={(data) => {this.closeModal(data)}} />
				</Modal>

				<Modal
					isOpen={this.state.withdrawHandicapIndexModalIsOpen}
					onRequestClose={() => {this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<WithdrawHandicapIndexModal golfer={this.props.golfer} closeModal={(data) => {this.closeModal(data)}} />
				</Modal>

				<ConfirmationModal
					openModal={this.state.removeHandicapIndexModalIsOpen}
					onConfirmAction={() => this.removeModification('modification')}
					onCancelAction={() => {this.closeModal()}}
					closeModal={() => {this.closeModal()}}
					question={'Are You Sure?'}
          bodyQuestion={`Are you sure you want to remove the Handicap Index Modification for ${this.props.golfer.player_name}?`}
					cancelLabel={"Cancel"}
					confirmLabel={"Confirm"}
				/>

				<ConfirmationModal
					openModal={ this.state.removeLowHandicapIndexModalIsOpen}
					onConfirmAction={() => this.removeModification('low_hi')}
					onCancelAction={() => {this.closeModal()}}
					closeModal={() => {this.closeModal()}}
					question={'Are you sure?'}
          bodyQuestion={`Are you sure you want to remove the Low H.I. Modification for ${this.props.golfer.player_name}?`}
					cancelLabel={"Cancel"}
					confirmLabel={"Confirm"}
				/>

        <ConfirmationModal
					openModal={ this.state.removeWithdrawHandicapIndexModalIsOpen}
					onConfirmAction={() => this.removeModification('withdraw')}
					onCancelAction={() => {this.closeModal()}}
					closeModal={() => {this.closeModal()}}
					question={'Are you sure?'}
          bodyQuestion={`Are you sure you want to remove the Withdraw for ${this.props.golfer.player_name}?`}
					longQuestion={true}
					cancelLabel={"Cancel"}
					confirmLabel={"Confirm"}
				/>




                <Modal
					isOpen={this.state.specialUpdateModalIsOpen}
					onRequestClose={() => {this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<SpecialUpdateModal closeModal={(data)=>{this.closeModal(data)}}
										confirmAction={() => this.performSpecialUpdate()}
										golferScreen={true}
										title={`Special Update golfer: ${this.props.golfer.first_name ? this.props.golfer.first_name : ""} ${this.props.golfer.middle_name ? this.props.golfer.middle_name : ""} ${this.props.golfer.last_name ? this.props.golfer.last_name : ""}`}
										description={`You are about to perform a Special Update.`}/>
				</Modal>

            </Fragment>
		);
	}
}



function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(null, mapDispatchToProps)(HandicapManagement);
