import {connect} from "react-redux";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import {bindActionCreators} from "redux";
import {addAlert} from "../shared/actions";
import React, {Fragment, useEffect, useState} from "react";
import JoinAndRenewServices from "../../services/api/joinAndRenew";
import AssociationService from "../../services/api/association";
import GhinFilterSelect from "../shared/GhinFilterSelect";
import GhinTable from "../shared/GhinTable";

const calculateTotalAmount = (fee) => {
	return parseFloat(fee.golf_association_membership_amount || 0.0) +
		parseFloat(fee.golf_association_fee_amount || 0.0) +
		parseFloat(fee.amount || 0.0)
};

function EditSelectedMembershipsForm(props) {
	const [filters, set_filters] = useState({})
	const [params, setParams] = useState({
		has_club_memberships: 'true',
		hide_used: 'true',
		hide_join_page_ids: 'false',
		join_and_renew_enabled: 'true',
		status: 'Active',
		is_aga: 'false'
	})
	const [memberships, setMemberships] = useState([]);
	const [page, setPage] = useState(0);
	const [perPage, setPerPage] = useState(25);
	const [pages, setPages] = useState(null);
	const [total, setTotal] = useState(null);
	const [loading_memberships, setLoadingMemberships] = useState(false);
	const [membershipCodes, setMembershipCodes] = useState([]);
	const [clubGroups, setClubGroups] = useState([]);
	const [isGroupFiltered, setIsGroupFiltered] = useState(false);
	const [selected, set_selected] = useState([]);
	const [loadSelected, setLoadSelected] = useState(false);
	const [changed, set_changed] = useState(false);
	const isAllSelected = memberships.length > 0 && memberships.length === selected.length;
	
	const handleSelect = (id) => {
		set_changed(true);
		if (selected.includes(id))
			set_selected(selected.filter(item => item !== id));
		else
			set_selected([...selected, id]);
	};

  const reset = (e) => {
    e.preventDefault()
		set_filters({
			club_name: "",
			zip_code: "",
			authorized: "",
			in_group: "",
			is_test_club: ""
		})
		setParams({
			has_club_memberships: 'true',
			hide_used: 'true',
			hide_join_page_ids: 'false',
			join_and_renew_enabled: 'true',
			status: 'Active',
			is_aga: 'false'
		})
		setIsGroupFiltered(false)
	}
	
	useEffect(() => {
		
		function fetchData() { 
			setLoadingMemberships(true);
			JoinAndRenewServices.getAssociationClubMembershipFees(props.join_page.golf_association_id, {
				only_assigned_memeberships_for: props.join_page.id
			}).then((res) => {
				set_selected(res.club_memberships);
				setLoadSelected(true);
			}).catch(err => {
				setLoadingMemberships(false);
			});
			
		}
		
		fetchData();
		
	}, []);

	useEffect(() => {
		let cancel = false;

		function fetchData() {
			AssociationService.getMembershipTypes(props.join_page.golf_association_id, {include_used_membership: 'false'})
				.then((res => {
					if (cancel) return;
					setMembershipCodes(res.membership_types.map(membershipType => {
							return {
								label: membershipType.code + ' - ' + membershipType.description,
								value: membershipType.id
							};
						}).sort((a, b) => a['label']?.toString().localeCompare(b['label']?.toString()))
					)
				}))
				.catch(err => {
					console.error(err);
				})

			AssociationService.getClubGroups(props.join_page.golf_association_id, {include_clubs: 'false'}).then((data) => {
				if (cancel) return;
				setClubGroups(data.club_groups.map((item) => {
					return {
						...item,
						label: item.name,
						value: item.id
					}
				}))
			})
		}

		fetchData();

		return () => {
			cancel = true;
		}
	}, [props.join_page.golf_association_id])

	useEffect(() => {
		let cancel = false;

		function fetchData() {
			setLoadingMemberships(true);

			JoinAndRenewServices.getAssociationClubMembershipFees(props.join_page.golf_association_id, {
				...params,
				only_memberships: true,
				page: page + 1,
				per_page: perPage
			}).then((res) => {
				if (cancel) return;
				setTotal(parseInt(res["total-count"], 10));
				setPerPage(parseInt(res["per-page"], 10));
				setPages(parseInt(res["total-pages"], 10));
				let membership_fees = [];
				const club_memberships = Object.values(res.club_memberships);
				club_memberships.map(club_membership => {
					if (club_membership["club"]["join_and_renew_enabled"] === true) {
						membership_fees.push({
							"club_membership_fee_id": club_membership["id"],
							"club_number": club_membership["club"]["club_id"],
							"club_name": club_membership["club"]["club_name"],
							"club_fee": club_membership["name"] + ' ($' + calculateTotalAmount(club_membership).toFixed(2) + ')',
							"join_page_ids": club_membership["join_page_ids"]
						});
					}
				});
				setMemberships(membership_fees);
				// setSelectedMemberships(membership_fees);
			}).finally(() => {
				setLoadingMemberships(false);
			});
		}
		
		if (loadSelected !== false)
			fetchData(); 

		return () => {
			cancel = true;
		}
	}, [filters, params, props.join_page.golf_association_id, page, perPage, loadSelected])

	const submit = (event) => {
		event.preventDefault();
    return JoinAndRenewServices.assignClubMembershipsToJoinPage(props.join_page.golf_association_id, props.join_page.id, selected).then(() => {
      props.reload_pages()
      props.close_modal();
		}).catch(() => {
			console.log("An error has occurred")
    })
	}

	const setSelectedMemberships = (membership_fees) => {
		let selectedItems = []
		membership_fees.forEach(fee => {
			const include = fee.join_page_ids.includes(props.join_page.id);
			if (include)
				selectedItems.push(fee.club_membership_fee_id);
		});
		set_selected(selectedItems);
	}
	
	const selectAll = () => {
		if (!isAllSelected) {
			const ids = memberships.map(fee => fee.club_membership_fee_id);
			set_selected(ids);
		}	
		else 
			set_selected([]);
		
		set_changed(true);
	}

	const columns = [
		{
			colClassName: "col_holes",
			sortable: false,
			Header: data => {
				return <Fragment>
					<div className={"smart_checkbox"}>
					 	<div 
							className={`checkbox ${isAllSelected ? 'checked' : ''}`} 
							style={{margin: '0px'}}
							onClick={selectAll}
							/>
					</div>
				</Fragment>
			},
			className: 'table-align-center',
			thClassName: 'table-align-center',
			Cell: data => {
				return <Fragment>
					<input type="checkbox"
						   checked={selected.includes(data.original["club_membership_fee_id"])}
						   onChange={() => { }}
					/>
					<label
						style={{ margin: 'auto' }}
						onClick={(e) => {
							handleSelect(data.original["club_membership_fee_id"])
						}}
					></label>
				</Fragment>
			}
		},
		{
			Header: 'Club Number',
			colClassName: 'col_diff',
			accessor: 'club_number'
		},
		{
			Header: 'Club Name',
			colClassName: 'col_date_posted',
			accessor: 'club_name'
		},
		{
			Header: 'Club Fee',
			colClassName: 'col_date_posted',
			accessor: 'club_fee'
		}
	];

	return (
		<form onSubmit={submit}>
			<div className="hr-margin-24-bottom">
				<div className="hr-margin-24-bottom">
					<strong className="pd-l-13px">
						Select Membership
					</strong>
				</div>
				<p className="pd-l-13px">The following clubs have been enabled
					for Join & Renew. Use the filters below to select which
					membership fees from each club that will be included on this
					join page. Clubs without membership fees will not be
					displayed. To add fees, select "Club Management" from the
					left navigation.</p>
			</div>
			<div className="memberships_border_box">
				<div
					className="filters columns hr-margin-24-top hr-margin-24-bottom">
					<div className="row">
						<div className='filters__inputs col'>
							<div className='row'>
								<div className="col is-2-of-13">
									<label htmlFor="golfer-name">Club
										Name</label>
									<input
										type="text"
										autoComplete="off"
										value={filters.club_name}
										disabled={isGroupFiltered}
										name="club_name"
										id="club_name"
										onChange={(e) => {
											setParams({
												...params,
												club_name: e.target.value
											})
											set_filters({
												...filters,
												club_name: e.target.value
											})
										}}
									/>
								</div>

								<div className={"col is-1-of-6"}>
									<GhinFilterSelect
										options={['Type 1', 'Type 2', 'Type 3']}
										value={filters.club_type}
										disabled={isGroupFiltered}
										numberDisplayed = {2}
										onChange={(e) => {
											let types = []
											e.map(type => {
												types.push(type["value"]);
											})
											setParams({
												...params,
												club_type: types
											})
											set_filters({
												...filters,
												club_type: e
											})
										}}
										label={'Club Type'}
									/>
								</div>

								<div className="col is-2-of-11">
									<GhinFilterSelect
										options={['Affiliate', 'Military', 'Private', 'Public', 'Resort', 'Semi-Private', 'Municipal', 'WORE', 'Not Specified']}
										value={filters.club_category}
										disabled={isGroupFiltered}
										numberDisplayed = {2}
										onChange={(e) => {
											let categories = []
											e.map(category => {
												categories.push(category["value"]);
											})
											setParams({
												...params,
												club_category: categories
											})
											set_filters({
												...filters,
												club_category: e
											})
										}}
										label={'Club Category'}
									/>
								</div>

								<div className="col is-1-of-6">
									<GhinFilterSelect
										options={[{
											label: 'Yes',
											value: true
										}, {label: 'No', value: false}]}
										value={filters.authorized}
										singleChoice={true}
										disabled={isGroupFiltered}
										onChange={(e) => {
											setParams({
												...params,
												authorized: e["value"]
											})
											set_filters({
												...filters, authorized: e
											})
										}}
										label={'Authorized?'}
									/>
								</div>

								<div className="col is-1-of-14 ">
									<div
										className="form__separator or top"></div>
								</div>
								<div className="col is-1-of-6">
									<GhinFilterSelect
										singleChoice={true}
										options={
											clubGroups
										}
										value={filters.in_group}
										numberDisplayed = {2}
										onChange={(e) => {
											if (e["value"] === undefined)
												setIsGroupFiltered(false)
											else
												setIsGroupFiltered(true)
											setParams({
												has_club_memberships: 'true',
												hide_used: 'true',
												hide_join_page_ids: 'false',
												in_group: e["value"]
											})
											set_filters({
												club_name: "",
												zip_code: "",
												authorized: "",
												is_test_club: "",
												in_group: e
											})
										}}
										label={'Club Group'}
									/>
								</div>

								<div
									className="filters__controls col is-1-of-10">
									<button className="btn fill gray top"
											onClick={reset}>
										Reset
									</button>
								</div>
							</div>

							<div className='row no-margin-top'>
								<div className="col is-2-of-9">
									<GhinFilterSelect
										options={['Junior', 'Standard']}
										value={filters.membership_type}
										disabled={isGroupFiltered}
										onChange={(e) => {
											let membership_types = []
											e.map(membership_type => {
												membership_types.push(membership_type["value"]);
											})
											setParams({
												...params,
												membership_type: membership_types
											})
											set_filters({
												...filters,
												membership_type: e
											})
										}}
										label={'USGA Membership Type'}
									/>
								</div>

								<div className="col is-2-of-9">
									<GhinFilterSelect
										options={membershipCodes.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1)}
										value={filters.membership_code}
										disabled={isGroupFiltered}
										numberDisplayed = {1}
										onChange={(e) => {
											let memberships = []
											e.map(membership => {
												memberships.push(membership["value"]);
											})
											setParams({
												...params,
												membership_code: memberships
											})
											set_filters({
												...filters,
												membership_code: e
											})
										}}
										label={'Membership Code'}
									/>
								</div>

								<div className="col is-1-of-6">
									<label htmlFor="golfer-name">Zip
										Code</label>
									<input
										type="text"
										autoComplete="off"
										disabled={isGroupFiltered}
										value={filters.zip_code}
										name="name"
										id="name"
										onChange={(e) => {
											setParams({
												...params,
												zip_code: e.target.value
											})
											set_filters({
												...filters,
												zip_code: e.target.value
											})
										}}
									/>
								</div>

								<div className="col is-2-of-11">
									<GhinFilterSelect
										singleChoice={true}
										options={
											[{
												label: 'Yes',
												value: true
											}, {label: 'No', value: false}]
										}
										value={filters.is_test_club}
										disabled={isGroupFiltered}
										onChange={(e) => {
											setParams({
												...params,
												is_test_club: e["value"]
											})
											set_filters({
												...filters,
												is_test_club: e
											})
										}}
										label={'Is Test Club?'}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row no-margin-top">
				<div className="col table-auto">
					<GhinTable
						idKey={'id'}
						columns={columns}
						data={memberships}
						hideSelect={true}
						sortable={true}
						colSpan={4}
						customNoDataMessage={'No clubs have been enabled for Join & Renew or the clubs that are enabled do not have club membership fees available. To enable clubs or add club membership fees, select "Club Management" from the left navigation.'}
						loading={loading_memberships}
						pages={pages}
						page={page}
						total={total}
						pageSize={perPage}
						manual
						onPageChange={(index) => setPage(index)}
						onPageSizeChange={(size, index) => {
							setPerPage(size);
							setPage(index);
						}}
					/>
				</div>
			</div>
			<div className="row">
        <div className="col is-1-of-2">
          <button type={'button'} className="btn fill gray" onClick={() => props.close_modal()}>Cancel</button>
        </div>
        <div className="col is-1-of-2">
          <button type={'submit'} disabled={!changed} className="btn fill blue">Save</button>
        </div>
      </div>
		</form>
	)
}

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ addAlert }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditSelectedMembershipsForm))
