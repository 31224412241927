import React, {Component, Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {
	required,
	isEmailAddress,
  isPhoneNumber,
	isNumber,
	maxLength
} from "../../inputs/validations";
import {InputField} from "../../inputs/InputField";
import diff from 'object-diff';
import {addAlert} from '../shared/actions'
import moment from "moment";
import {DatePickerInput} from "../../inputs/DatePicker";
import GolferService from "../../services/api/golfer";
import ClubService from "../../services/api/club";
import {SelectInput} from "../../inputs/Select";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import ConfirmationModalTypeB from "../Modals/ConfirmationModalTypeB";
import GolferServices from "../../services/api/golfer";
import GhinGuardianFormComponent2 from "../shared/GhinGuardianFormComponent2";
import {processErrorMessage} from "../../services/shared/errorHelper";
import { Textarea } from '../../inputs/Textarea';
import {formatPhoneNumber} from "../utils";


class EditGolferAccountForm extends Component {

	constructor(props){
		super(props);
		this.state = {
			timeZones: [],
			allied_assn: false,
			command_user: false,
			handicap_program_lic: false,
			course_rating_lic: false,
			status: false,
			membershipTypes: [],
			error: null,
			confirmationError: null,
			confirmation: false,
			is_archived: false,
      deceased: false,
      details_count: this.props.golfer.deceased_details ? this.props.golfer.deceased_details.length : 0,
      selectedGuardian: false
		}
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		ClubService.getMembershipTypes(this.props.golfer.club_id, {include_used_membership: 'false'})
			.then(res => {

				this.setState({
					is_archived: this.props.golfer.status === "Archived",
          deceased: this.props.golfer.deceased,
					membershipTypes: res.membership_types.map(membershipType => {
						return {label: membershipType.code + ' - ' + membershipType.description, value: membershipType.id, code: membershipType.code, type: membershipType.type};
					})}, () => {

					let selectedMembership = this.props.golfer.membership_code ?
						this.state.membershipTypes.length > 0 ?
							this.state.membershipTypes.filter(membershipType =>
								membershipType.code === this.props.golfer.membership_code
							)
							:
							[]
						:
						[];

					if (selectedMembership.length > 0) {
						selectedMembership = selectedMembership[0]
					}
					this.props.initialize({
						...this.props.golfer,
						...{
							gender: this.props.golfer.gender === "M" ? {value: "M", label: "Male"} : {
								value: "F",
								label: "Female"
							},
							is_archived: this.props.golfer.status === "Archived",
							date_of_birth: this.props.golfer.date_of_birth ? new Date(moment(this.props.golfer.date_of_birth, 'YYYY-MM-DD')) : null,
							membership_code: selectedMembership ? {
								label: selectedMembership.label,
								value: selectedMembership.value,
								type: selectedMembership.type
							} : null,
							phone_number: formatPhoneNumber(this.props.golfer.phone_number),
						}
					});
				});
			})
			.catch(err => {
				console.error(err)
			})
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

  selectGuardian() {
    this.setState({
      selectedGuardian: !this.state.selectedGuardian
    })
  }

	onSubmit(values, dispatch, props) {
		let data = JSON.parse(JSON.stringify(values));
		let golfer_club = null;
		delete data.placeholderValue;
		delete data.local_number;
		let membership_type = null;
		if(data.primary_address) delete data.primary_address;
		if(data.secondary_address) delete data.secondary_address;
		if(data.date_of_birth) {
			if (moment().diff(moment(data.date_of_birth), 'years') < 13) {
				data.email = '';
			}
			data.date_of_birth = moment(data.date_of_birth).format('YYYY-MM-DD');
		}
    if (this.state.deceased) {
      data.is_archived = true
    }
		if(!this.state.is_archived) {
			data.status = "Active"
		}
		if(data.gender) data.gender = data.gender.value;
		if(data.local_number) {golfer_club = {local_number: data.local_number}; delete data.local_number}
		if(data.membership_code) { membership_type = {id: data.membership_code.value}; delete data.membership_code}

		if (data.guardian) {
			if (data.guardian.relationship) {
				data.guardian.relationship = data.guardian.relationship.value
			}
		}

		data = {golfer: diff(props.golfer, data), golfer_club, membership_type, guardian: data.guardian};


		if(data.golfer.gender && data.golfer.gender !== props.golfer.gender) {
			props.confirmGender(data, golfer_club, membership_type);
			return;
		}

		if (data.golfer.date_of_birth) {
			let age = moment().diff(moment(data.golfer.date_of_birth), 'years')

			if (age <= 5 || age >= 85) {
				props.confirmBirthDate(data, golfer_club, membership_type);
				return;
			}
		}

		return GolferService.updateGolfer(props.golfer.club_id, props.golfer.id, data)
			.then(res=>{
				props.addAlert({
					type:'success',
					message:'Golfer has been successfully edited'
				});
        if (this.state.is_archived || this.state.deceased) {
          const req_params = {
            deceased: this.state.deceased,
            deceased_details: values.deceased_details
          }
          return GolferServices.archiveGolfer(props.golfer.id, req_params)
						.then(res2 => {
							props.addAlert({
								type:'success',
								message:'Golfer has been successfully archived'
							});
							props.close({...res,...{is_archived: true}});
						})
						.catch(err => {
							console.log(err)
						})
				} else {
					props.close(res);
				}
			})
			.catch(error => {
				let errorMessage = processErrorMessage(error.data.errors);

				if (error.data.show_confirmation) {
					data.force = "true";
					this.showConfirm({error: errorMessage, golfer:data});
				} else {
				  this.setState({
						error: errorMessage
					})
        		}
				console.error(error)
			});
	}

	showConfirm(data) {
		this.setState({
			confirmation: true,
			confirmationError: data.error,
			toPost: data.golfer,
		});
	}

	onConfirm(){
		return GolferService.updateGolfer(this.props.golfer.club_id, this.props.golfer.id, this.state.toPost)
			.then(res => {
				this.props.addAlert({
					type:'success',
					message:'Golfer has been successfully edited'
				});
				this.props.close(res);
			})
			.catch(error => {
				console.error(error)
			});
	}


	render() {
		const {handleSubmit, pristine, submitting, invalid} = this.props;
		const membershipForJunior = this.props.membership_code && this.props.membership_code.type === "Junior";
		const golferAge = this.props.date_of_birth ? moment().diff(moment(this.props.date_of_birth), 'years') : 30;
		const golferIsMinor =  golferAge < 13;
		const golferIsJunior =  golferAge < 19 && golferAge >= 13;
    const detailsMaxLength = 100;
    const agaGolfer = this.props.golfer.has_active_aga_memberships;
		return (
            <Fragment>
				{!this.state.confirmation && <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="prefix">Prefix</label>
							<Field component={InputField}  type="text" name="prefix" id="prefix" validate={[maxLength([4, "Value too long (max 4 characters)"])]} />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="name">First Name <span>*</span></label>
							<Field component={InputField} validate={[required]} type="text" name="first_name" id="first_name" />
						</div>
						<div className="col is-1-of-2">
							<label htmlFor="name">Middle Name</label>
							<Field component={InputField}  type="text" name="middle_name" id="middle_name" />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="name">Last Name <span>*</span></label>
							<Field component={InputField} validate={[required]} type="text" name="last_name" id="last_name" />
						</div>
						<div className="col is-1-of-2">
							<label htmlFor="parent-golf-federation">Suffix</label>
							<Field component={InputField}  type="text" name="suffix" id="suffix"  validate={[maxLength([4, "Value too long (max 4 characters)"])]}/>
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="gender">Gender</label>
							<Field name="gender" id="gender"  validate={[required]} component={SelectInput} className="react-select-container" classNamePrefix="react-select" options={[{value:'M',label:'Male'},{value:'F',label:'Female'}]}/>
						</div>
						<div className="col is-1-of-2">
							<label htmlFor="date_of_birth">Birthdate {membershipForJunior && <span>*</span>}</label>
							<Field maxDate={new Date()}
								   className={'date'}
								   showMonthDropdown
								   showYearDropdown
								   name={'date_of_birth'}
								   id={'date_of_birth'}
								   validate={membershipForJunior ? [required] : []}
								   component={DatePickerInput}
								   placeholder="Select ... " />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
              <label htmlFor="email">Email {!golferIsMinor && !this.state.is_archived && !this.state.selectedGuardian && !this.props.golfer.has_active_guardians && !this.state.deceased ? <span>*</span> : null}</label>
							<Field component={InputField}
								   disabled={golferIsMinor}
								   validate={!golferIsMinor && !this.state.is_archived && !this.state.selectedGuardian && !this.props.golfer.has_active_guardians && !this.state.deceased ? [required, isEmailAddress] : [isEmailAddress]}
								   type="text"
								   name={golferIsMinor ? 'placeholderValue' : "email"}
								   id={golferIsMinor ? 'placeholderValue' : "email"}
							/>
						</div>
						<div className="col is-1-of-2">
							<label htmlFor="phone_number">Phone Number</label>
							<Field component={InputField}
                    validate={agaGolfer ? [isPhoneNumber] : []}
                    type="text"
                    name="phone_number"
                    id="phone_number"
            />
						</div>
					</div>
					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="golf_canada_id">Golf Canada Network ID</label>
							<Field component={InputField}
								   validate={[isNumber, maxLength([12, 'Golf Canada Network ID cannot exceed 12 characters.']) ]}
								   type="text"
								   name="golf_canada_id"
								   id="golf_canada_id"
							/>
						</div>
					</div>
					{this.props.canEditAssociation && <div className="row">
						<div className="col is-1-of-4 jc-fe ">
							<Field
								onClick={
									() => {
										this.setState({
											is_archived: !this.state.is_archived
										});
									}
								}
                checked={this.state.is_archived || this.state.deceased}
								id="is_archived"
								value={true}
								name={'is_archived'}
								component={'input'}
								type="checkbox"
                disabled={this.state.deceased}
							/>
							<label htmlFor="is_archived">Archived</label>
						</div>
            <div className="col is-1-of-4 jc-fe ">
              <Field
                onClick={
                  () => {
                    this.setState({
                      deceased: !this.state.deceased
                    });
                  }
                }
                checked={this.state.deceased}
                id="deceased"
                value={true}
                name={'deceased'}
                component={'input'}
                type="checkbox"
                disabled={!this.props.canEditAssociation}
              />
              <label htmlFor="deceased">Deceased</label>
            </div>
          </div>}
          {this.state.deceased && <Fragment>
            <div className="row">
              <div className="col is-1-of-2">
                <label htmlFor="deceased_details">Details <span>*</span> </label>
              </div>
              <div className="col is-2-of-2" style={{ textAlign: "right" }}>
                <label className={(this.state.details_count > detailsMaxLength ? 'length-error' : 'blue')} htmlFor="note">{this.state.details_count}  / {detailsMaxLength}</label>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field component={Textarea}
                  validate={[required, maxLength([100, 'Details cannot exceed 100 characters.'])]}
                  type="text"
                  name="deceased_details"
                  id="deceased_details"
                  className={'medium'}
                  onChange={(e) => this.setState({ details_count: e.target.value.length || 0 })}
                />
              </div>
            </div>
            <div className='row' style={{ 'color': 'red' }}>
              <div className='col jc-fe'>
                <p>You have indicated this golfer is deceased. Saving this form will inactivate all club memberships, archive the golfing record, unsubscribe the golfer from the Newsletter & Push Notifications. In addition, it will trigger an email notification to all administrators of active clubs this golfer belongs to.</p>
                <p style={{ 'paddingTop': '10px' }}>If you proceed and this is a mistake, you will have to restore all settings manually.</p>
                <p style={{ 'paddingTop': '10px' }}>If you are sure you would like to continue, press "Save".</p>
              </div>
            </div>
          </Fragment>}
          {!this.props.canEditAssociation && <div className="row">
            <div className="col jc-fe " style={{ "color": "grey" }}>
              Please contact the association to mark a golfer as deceased.
            </div>
          </div>}
					{
            ((golferIsJunior || golferIsMinor) && !this.props.golfer.has_active_guardians) ? <GhinGuardianFormComponent2 formGuardian={this.props.guardian} selectGuardian={() => this.selectGuardian()} change={this.props.change} untouch={this.props.untouch} junior={golferIsJunior} minor={golferIsMinor} is_archived={this.state.is_archived || this.state.deceased} /> : null
					}
          {(this.state.error || this.props.parent_error) && <span className={'validation__message is-error'}>{this.state.error || this.props.parent_error}</span>}

					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} className="btn fill gray" disabled={submitting} onClick={()=>this.props.close()}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'}  disabled={pristine || submitting || invalid} className="btn fill blue">Save</button>
							{invalid === true &&
								<p className="validation__message is-error">Please provide all required fields.</p>
							}
						</div>
					</div>

				</form>}

				{this.state.confirmation &&
				<ConfirmationModalTypeB openModal={this.state.confirmation}
										wideClass={"narrow"}
										onConfirmAction={() => this.onConfirm()}
										modalIcon={"help_outline"}
										onCancelAction={() => {
											this.setState({confirmation: false})
										}}
										closeModal={() => {
											this.setState({confirmation: false})
										}}
										infoText={[this.state.confirmationError]}
										cancelLabel={"Back"}
										confirmLabel={"Save"}/>
				}

            </Fragment>
		);
	}
}

EditGolferAccountForm = reduxForm({
	form: 'editGolferAccountForm',
	destroyOnUnmount: true,
	touchOnChange: true
})(EditGolferAccountForm);

function mapStateToProps(state) {
	const selector = formValueSelector('editGolferAccountForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
		membership_code: selector(state, 'membership_code'),
		date_of_birth: selector(state, 'date_of_birth'),
		guardian: selector(state, 'guardian'),
		assignGuardian: selector(state, 'assignGuardian')
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditGolferAccountForm));
