import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import JoinAndRenewServices from "../../../../../services/api/joinAndRenew";
import { addAlert } from "../../../../shared/actions";
import AddOns from "./AddOns";
import AssociationJoinPageName from "./AssociationJoinPageName";
import AssociationPageCustomization from "./AssociationPageCustomization";
import JoinPagesDetails from "./JoinPagesDetails";
import SelectMemberships from "./SelectMemberships";

function ManageJoinPage(props) {
  const [data, set_data] = useState({
    header_text: "",
    description: "",
    addon_charges: [],
    club_name_search: false,
    zip_code_search: false,
    digital_profile: false,
    image: "",
    name: "",
    reroute_to_label: "",
    reroute_to_url: ""
  })
  const [join_link_data, set_join_link_data] = useState({})
  const [serverError, setServerError] = useState('');
  
  const history = useHistory()
  const update_link = () => {
    return new Promise((resolve, reject) => {
      if (data.id) {
        var form_data = new FormData();

        for (var key in data) {
          if ((key === "header_image")) {
            if (typeof data.header_image === typeof {} && data.header_image !== null) {
              form_data.append(key, data[key], data[key].name);
            }
          }
          else {
            form_data.append(key, data[key]);
          }
        }
        JoinAndRenewServices.updateJoinPage(props.association.id, data.id, form_data).then((res) => {
          set_data(res.join_page)
          resolve()
        }).catch((e) => {
          var error = ""
          try {
            key = Object.keys(e.data.errors)[0]
            error = e.data.errors[key][0]
          } catch {
            error = "An error has occurred"
          }
          /*
          props.addAlert({
            type: 'error',
            message: error
          })
          */
          setServerError(error);
          reject()
        })
      }
      else {
        JoinAndRenewServices.createJoinPage(props.association.id, {
          name: data.name
        }).then(res => {
          set_data(res.join_page)
          set_join_link_data({ ...data })
          resolve()
        }).catch((e) => {
          var error = ""
          try {
            key = Object.keys(e.data.errors)[0]
            error = e.data.errors[key][0]
          } catch {
            error = "An error has occurred"
          }
          /*
          props.addAlert({
            type: 'error',
            message: error
          })
          */
          setServerError(error);
          reject()
        })
      }
    })
  }
  
  const clearServerError = () => {
    setServerError('');
  }

  const push_join_page_changes = () => {
    return new Promise((resolve, reject) => {
      for (var key in data) {
        if (data[key] !== join_link_data[key]) {
          resolve(update_link())
          break
        }
      }
      resolve()
    })
  }
  useEffect(() => {
    const url = props.match.url
    const pathname = props.location.pathname
    const id_free_urls = [`${url}/join-pages/create/association-join-page-name`, `${url}/join-pages/create`]
    if (id_free_urls.findIndex((e) => e === pathname) === -1 && !data.id) {
      history.push("./association-join-page-name")
    }
  }, [props.match.url, props.location.pathname, data.id, history])

  if (props.location.pathname === `${props.match.url}/join-pages/create/summary`)
    return (
      <div className="panel hr-margin-24-top hr-margin-24-bottom">
        <div className="panel__head">
          Add Join Page
        </div>
        <div className="panel__body">
          <div className="row hr-margin-24-top">
            <div className="col is-1-of-4 push-right">
              <div className="row">
                <div className="col">
                  <button type={'button'} className="btn fill gray" onClick={() => history.push("./add-ons")}>Go Back</button>
                </div>
                <div className="col">
                  <button type={'button'} className="btn fill blue" onClick={() => history.push(`${props.match.path}/join-pages`)} >Done</button>
                </div>
              </div>
            </div>
          </div>
          <div className="hr-margin-24-top">
            <div className="center_join_pages_navigation">
              <p><i>
                Press the <strong>DONE</strong> button to comlete setup. To review a previous section, use the <strong>Go Back</strong> button.</i></p>
            </div>
          </div>
          <hr className="panel__hr" />
          <JoinPagesDetails
            data={data}
            hide_edit={true}
          />
          <div className="row hr-margin-24-top">
            <div className="col is-1-of-4 push-right">
              <div className="row">
                <div className="col">
                  <button type={'button'} className="btn fill gray" onClick={() => history.push("./add-ons")}>Go Back</button>
                </div>
                <div className="col">
                  <button type={'button'} className="btn fill blue" onClick={() => history.push(`${props.match.path}/join-pages`)} >Done</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <div className="panel">
      <div className="panel__head">
        Add Join Page
      </div>
      <div className="panel__body">
        <div className="center_join_pages_navigation">
          <ul className="page__tabs">
            <Fragment>
              <li><NavLink
                className={`join__page__tab`}
                activeClassName="is-active"
                to={`${props.match.url}/join-pages/create/association-join-page-name`}
              >
                ASSOCIATION JOIN PAGE NAME
              </NavLink></li>
              <li><NavLink
                className={`join__page__tab ${data.id ? "" : "disabled"}`}
                activeClassName="is-active"
                to={`${props.match.url}/join-pages/create/select-membership`}
                onClick={(e) => {
                  if (!data.id)
                    e.preventDefault()
                }}
              >
                SELECT MEMBERSHIPS
              </NavLink></li>
              <li><NavLink
                className={`join__page__tab ${data.id ? "" : "disabled"}`}
                activeClassName="is-active"
                to={`${props.match.url}/join-pages/create/association-page-customization`}
                onClick={(e) => {
                  if (!data.id)
                    e.preventDefault()
                }}
              >
                ASSOCIATION PAGE CUSTOMIZATION
              </NavLink></li>
              <li><NavLink
                className={`join__page__tab ${data.id ? "" : "disabled"}`}
                activeClassName="is-active"
                to={`${props.match.url}/join-pages/create/add-ons`}
                onClick={(e) => {
                  if (!data.id)
                    e.preventDefault()
                }}
              >
                ADD-ONS
              </NavLink></li>
            </Fragment>
          </ul>
        </div>
        <div className="hr-margin-24-top">
          <div className="center_join_pages_navigation">
            <p><i>Press the <strong>Next/Save Changes</strong> button at the bottom of the page to advance to the next section. To review a previous section, use the <strong>Go Back</strong> button.</i></p>
          </div>
        </div>
        <hr className="panel__hr" />
        <Switch>
          <Redirect exact from={`${props.match.url}/join-pages/create`} to={`${props.match.url}/join-pages/create/association-join-page-name`} />
          <Route
            path={`${props.match.url}/join-pages/create/association-join-page-name`}
            render={() =>
              <AssociationJoinPageName
                {...props}
                data={data}
                set_data={set_data}
                serverError={serverError}
                clearServerError={clearServerError}
                push_join_page_changes={push_join_page_changes}
              />}
          />
          <Route
            path={`${props.match.url}/join-pages/create/select-membership`}
            render={() =>
              <SelectMemberships
                {...props}
                data={data}
                set_data={set_data}
                push_join_page_changes={push_join_page_changes}
              />}
          />
          <Route
            path={`${props.match.url}/join-pages/create/association-page-customization`}
            render={() =>
              <AssociationPageCustomization
                {...props}
                data={data}
                set_data={set_data}
                push_join_page_changes={push_join_page_changes}
              />}
          />
          <Route
            path={`${props.match.url}/join-pages/create/add-ons`}
            render={() =>
              <AddOns
                {...props}
                data={data}
                set_data={set_data}
              />}
          />
        </Switch>
      </div>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAlert }, dispatch);
}

export default connect(null, mapDispatchToProps)(ManageJoinPage);
