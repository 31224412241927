// To enable the Post / Edit button
// - at least one side must be completed
// - at least 9 holes (9h score) or 10 holes (18h score) must be completed
import React, { Fragment } from 'react'

export function checkHoles(holes)
{
  let front_side_completed = true;
  let back_side_completed = true;
  let total_holes = 18;

  for (let i = 1; i <= 9; ++i) {
    if (holes['hole' + i] === '' ||
        holes['hole' + i] == undefined) {
      front_side_completed = false;
      total_holes--;
    }
  }
  for (let i = 10; i <= 18; ++i) {
    if (holes['hole' + i] === '' ||
        holes['hole' + i] == undefined) {
      back_side_completed = false;
      total_holes--;
    }
  }

  return (front_side_completed || back_side_completed) && total_holes >= 10
}

export function getCleanName(item) {
  let course_name = item.ghin_course_name_display || item.course_display_value;
  if (item.score_type === "C" && course_name) {
    if (course_name.indexOf(';;') !== -1) {
      let name = course_name.split(';;');
      if (item.placeholder) {
        return name[0] + ' ' + name[1];
      } else {
        return <Fragment>{name[0]}<br/>{name[1]}</Fragment>
      }
    } else {
      return course_name;
    }
  }
  return course_name;
}

export function formatPhoneNumber(phoneNumber) {
	phoneNumber = phoneNumber?.replace(/\D/g, '');
	if (phoneNumber?.length === 10) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
	} else if (phoneNumber?.length > 10){
		const countryCode = phoneNumber.slice(0, phoneNumber.length - 10);
		const mainNumber = phoneNumber.slice(phoneNumber.length - 10);
		return `+${countryCode} (${mainNumber.slice(0, 3)}) ${mainNumber.slice(3, 6)}-${mainNumber.slice(6)}`;
	} else {
		return phoneNumber;
	}
}
