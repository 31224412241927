import React, { Component, Fragment } from "react";
import GhinTable from "../../../shared/GhinTable";
import GhinFilterSelect from "../../../shared/GhinFilterSelect";
import { addAlert } from "../../../shared/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Select from "react-select";
import { SelectInput } from "../../../../inputs/Select";
import DatePicker from "react-datepicker";
import moment from "moment";
import Modal from "react-modal";
import AssociationService from "../../../../services/api/association";
import "react-picky/dist/picky.css";
import ConfirmationModalTypeB from "../../../Modals/ConfirmationModalTypeB";
import SuccessModal from "../../../Modals/SuccessModal";
import Spinner from "../../Associations/FederationNewsletter/Spinner";
import {stringDateFormat, stringDateTimeFormat} from '../../../../helpers/dateTimeHelper.js'

const options = [
	{ value: "overAge", label: "Currently 19 or Over" },
	{ value: "underAge", label: "Will be 19" },
];

class AgedOutGolfers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			reports: [],
			clubs: [],
			type: "overAge",
			date_from_error: null,
			date_to_error: null,
			group_error: null,
			date_from: "",
			date_to: "",
			openUpdateModal: false,
			selectedClubs: [],
			golfers: 0,
			selectedReport: null,
			page: 1,
			pages: 0,
			per_page: 10,
			total: 0,
			loadingReports: false,
			loadingCreateReport: false,
			loadingUpdate: false,
			successfully_updated_golfers: 0,
			need_further_review: 0,
			updated_golfers_url: null,
			showExportUpdatedGolfersModal: false,
		};
		this.updateGolfersFromReport = this.updateGolfersFromReport.bind(this);
		this.resetFilters = this.resetFilters.bind(this);
	}
	componentDidMount() {
		this.getClubs();
		this.getAgingOutReportActivities();
	}

	updateFilter = (name, value) => {
		this.setState({ [name]: value });
	};

	resetFilters() {
		this.setState({
			type: "overAge",
			date_from: "",
			date_to: "",
			selectedClubs: this.state.clubs,
			date_from_error: null,
			date_to_error: null,
			group_error: null,
		});
	}

	getClubs() {
		AssociationService.getAllClubs(this.props.associationId, {
			status: "Active",
		})
			.then((res) => {
				let clubs = res.clubs;
				clubs = clubs
					.map((club) => {
						return {
							value: club.id,
							label: club.club_name,
							id: club.id,
						};
					})
					.sort((a, b) =>
						a["label"]
							?.toString()
							.localeCompare(b["label"]?.toString())
					);

				this.setState({
					clubs: clubs,
					selectedClubs: clubs,
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}

	onSubmit() {
		if (this.state.type === "underAge" && this.state.date_from === "") {
			this.setState({ date_from_error: "This field is required." });
			return;
		}
		if (this.state.type === "underAge" && this.state.date_to === "") {
			this.setState({ date_to_error: "This field is required." });
			return;
		}

		if (this.state.type === null) {
			this.setState({ group_error: "This field is required." });
			return;
		}

		let params = {};
		params["group"] =
			this.state.type === "underAge"
				? "Will be 19"
				: "Currently 19 or Over";

		const club_ids = this.state.selectedClubs.map((el) => el.id);
		const select_all_clubs =
			club_ids.length === 0 ||
			club_ids.length === this.state.clubs.length;

		params["all_clubs"] = select_all_clubs ? "true" : "false";
		params["club_ids"] = select_all_clubs ? [] : club_ids;
		
		if (this.state.type === "underAge"){
			params["from"] = moment(this.state.date_from).format("YYYY/MM/DD");
			params["to"] = moment(this.state.date_to).format("YYYY/MM/DD");
		 }
		
		this.setState({loadingCreateReport: true});
		AssociationService.createAgingOutReportActivity(
			this.props.associationId,
			params
		)
			.then(() => {
				this.getAgingOutReportActivities();
				this.resetFilters();
				this.props.addAlert({
					type: "success",
					message: "Report successfully generated",
				});
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				this.setState({ loadingCreateReport: false });
			});
	}

	getAgingOutReportActivities() {
		let params = {};
		params["per_page"] = this.state.per_page;
		params["page"] = this.state.page;

		this.setState({ loadingReports: true });
		AssociationService.getAgingOutReportActivities(
			this.props.associationId,
			params
		)
			.then((res) => {
				this.setState({
					total: parseInt(res["total-count"], 10),
					per_page: parseInt(res["per-page"], 10),
					pages: parseInt(res["total-pages"], 10),
					reports: res.report_activities.map((report) => {
						return {
							...report,
							...{
								id: report.id,
								group: report.group,
								export_date_time:
									report.created_at &&
									moment(report.created_at).format(
										"MM/DD/YYYY HH:mm:ss"
									),
								golfers: report.no_of_golfers,
								date_applied: moment(report.updated_at).format(
									"MM/DD/YYYY HH:mm:ss"
								),
							},
						};
					}),
				});
			})
			.finally(() => {
				this.setState({ loadingReports: false });
			});
	}

	updateGolfersFromReport() {
		this.setState({ openUpdateModal: false, loadingUpdate: true });
		AssociationService.updateGolfersFromReport(
			this.props.associationId,
			this.state.selectedReport
		)
			.then((res) => {
				this.getAgingOutReportActivities();
				// this.props.addAlert({
				// 	type:'success',
				// 	message:'The memberships have been successfully updated'
				// });
				this.setState({
					showExportUpdatedGolfersModal: true,
					updated_golfers_url:
						res.report_activity.updated_golfers_url,
					successfully_updated_golfers:
						res.successfully_updated_golfers,
					need_further_review: res.need_further_review,
				});
			})
			.catch((err) => {
				console.log(err);
				if (err.data && err.data.errors) {
					let key = Object.keys(err.data.errors)[0];
					this.props.addAlert({
						type: "error",
						message: err.data.errors[key],
					});
				}
			})
			.finally(() => this.setState({ loadingUpdate: false }));
	}

	render() {
		let reportActivity = [
			{
				Header: "Export Date/Time",
				accessor: "export_date_time",
				colClassName: "col_ghin",
			},
			{
				Header: "Group",
				accessor: "group",
				colClassName: "col_ghin",
			},
			{
				Header: "Date/Date Range",
				colClassName: "col_ghin",
				Cell: (props) => {
					const from_date =
						props.original.from != null ? (
							<>
								From:{" "} {	stringDateFormat(props.original.from) }
							</>
					) : <>{ stringDateTimeFormat(props.original.export_date_time, "MM/DD/YYYY") } </>;
					const to_date =
						props.original.to != null ? (
							<>
								<br /> To:{" "} {stringDateFormat(props.original.to)}
							</>
						) : null;

					return (
						<span>
							<div>
								{from_date}
								{to_date}
							</div>
						</span>
					);
				},
			},
			{
				Header: "# Golfers",
				accessor: "golfers",
				className: "table-align-center",
				thClassName: "table-align-center",
				colClassName: "col_min",
			},
			{
				Header: "Applied",
				accessor: "update_applied",
				className: "table-align-center",
				thClassName: "table-align-center",
				colClassName: "col_min",
				Cell: (props) =>
					props.original.group === "Will be 19"
						? "-"
						: props.original.update_applied === true
						? "Yes"
						: "No",
			},
			{
				Header: "Date Applied",
				accessor: "date_applied",
				className: "table-align-center",
				thClassName: "table-align-center",
				colClassName: "col_date_applied",
				Cell: (props) =>
					props.original.group === "Will be 19" ||
					props.original.update_applied == false
						? "-"
						: props.original.date_applied.split(" ")[0],
			},
			{
				accessor: "download",
				colClassName:
					this.state.reports.length > 0 ? "col_date_posted" : "",
				Cell: (props) => (
					<Fragment>
						<div className="row">
								{true ? (
									<div
										style={{
											width: "40px",
											marginLeft: "auto",
											marginRight: "auto",
											marginTop: "5px",
										}}>
										<a
											href={props.original.url}
											target={"_blank"}
											rel="noreferrer"
										>
											Download
										</a>
									</div>
								) : (
									<div
										style={{
											marginLeft: "auto",
											marginRight: "auto",
											marginTop: "5px",
										}}>
										<a
											href={
												props.original.updated_golfers_url
											}
											target={"_blank"}
											rel="noreferrer"
										>
											Export Records for review
										</a>
									</div>
								)}
							{props.original.group === "Currently 19 or Over" &&
								props.original.update_applied === false &&
								this.props.canEditAssociation && (
									<div
										style={{
											marginLeft: "auto",
											marginRight: "auto",
										}}
									>
										<button
											href={props.original.url}
											disabled={
												this.state.loadingUpdate &&
												this.state.selectedReport ===
													props.original.id
											}
											target={"_blank"}
											rel="noreferrer"
											onClick={() => {
												this.setState({
													openUpdateModal: true,
													golfers:
														props.original.golfers,
													selectedReport:
														props.original.id,
												});
											}}
											className={"btn fill gray small"}
											style={{ width: "140px" }}
										>
											{this.state.loadingUpdate &&
											this.state.selectedReport ===
												props.original.id
												? "Processing..."
												: "Update Membership Types"}
										</button>
									</div>
								)}
						</div>
					</Fragment>
				),
			},
		];

		return (
			<Fragment>
				{this.props.canEditAssociation && (
					<div className="row">
						<div className="col">
							<div className="panel">
								<div className="panel__head">
									Manage Aged-Out Golfers
								</div>
								<div className="panel__body">
									<div className="row">
										<div className="col">
											<p className="gray">
												<i>
													Use the Aged-Out Report to
													generate a list of golfers
													that have aged out of a
													Junior membership type or
													will aging out in the
													future.
												</i>
											</p>
										</div>
									</div>
									<div className="row">
										<div className="col is-1-of-5">
											<label>
												Select Group <span>*</span>
											</label>
											<Select
												className={`react-select-container ${
													this.state.group_error
														? "is-error"
														: ""
												}`}
												classNamePrefix="react-select"
												value={
													options.filter(
														(el) =>
															el.value ===
															this.state.type
													)[0]
												}
												options={options}
												onChange={(e) =>
													this.setState({
														type: e.value,
														group_error: null,
													})
												}
											/>
										</div>
										<div className="col is-1-of-4">
											<GhinFilterSelect
												component={SelectInput}
												options={this.state.clubs}
												className="react-select-container"
												classNamePrefix="react-select"
												closeMenuOnSelect={false}
												includeFilter={true}
												value={this.state.selectedClubs}
												onChange={(values) => {
													this.setState({
														selectedClubs: values,
													});
												}}
												label={
													<>
														Clubs <span>*</span>{" "}
													</>
												}
											/>
										</div>
										{this.state.type &&
											this.state.type === "underAge" && (
												<div className="col is-1-of-5">
													<label>
														Date From <span>*</span>
													</label>
													<DatePicker
														required
														selected={
															this.state.date_from
																? new Date(
																		this.state.date_from
																  )
																: null
														}
														className={
															this.state
																.date_from_error
																? "date is-error"
																: "date"
														}
														dropdownMode="select"
														dateFormat={
															"MM/dd/yyyy"
														}
														showMonthDropdown
														showYearDropdown
														maxDate={
															this.state.date_to
																? new Date(
																		this.state.date_to
																  )
																: null
														}
														minDate={new Date()}
														onChange={(value) => {
															if (value) {
																this.updateFilter(
																	"date_from",
																	value?.toString()
																);
															} else {
																this.updateFilter(
																	"date_from",
																	""
																);
															}
															this.setState({
																date_from_error:
																	null,
															});
														}}
													/>
												</div>
											)}
										{this.state.type &&
											this.state.type === "underAge" && (
												<div className="col is-1-of-5">
													<label>
														Date To <span>*</span>
													</label>
													<DatePicker
														required
														selected={
															this.state.date_to
																? new Date(
																		this.state.date_to
																  )
																: ""
														}
														className={
															this.state
																.date_to_error
																? "date is-error"
																: "date"
														}
														dropdownMode="select"
														dateFormat={
															"MM/dd/yyyy"
														}
														showMonthDropdown
														showYearDropdown
														minDate={
															this.state.date_from
																? new Date(
																		this.state.date_from
																  )
																: new Date()
														}
														onChange={(value) => {
															if (value) {
																this.updateFilter(
																	"date_to",
																	value?.toString()
																);
															} else {
																this.updateFilter(
																	"date_to",
																	""
																);
															}
															this.setState({
																date_to_error:
																	null,
															});
														}}
													/>
												</div>
											)}
										<div
											className="col is-1-of-8"
											style={{ paddingTop: "10px" }}
										>
											<button
												style={{ marginTop: "15px" }}
												className="btn fill green"
												disabled={
													this.state
														.loadingCreateReport
												}
												onClick={() => this.onSubmit()}
											>
												{this.state
													.loadingCreateReport && (
													<Spinner className="lds-ring white aged-out-spinner-position" />
												)}
												Run
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}

				<div className="row">
					<div className="col">
						<div className="panel">
							<div className="panel__head">Report Activity</div>
							<div className="panel__body">
								<div className="row">
									<div className="col">
										<p>
											The activity log is a snapshot of
											previous report activities for your
											association. Click "Download" to
											download and view the report. To
											update the membership type for
											golfers who have aged out of a
											junior membership type to the
											default membership type for their
											club, press "Update Membership Type"
											next to the applicable report. This
											feature is not available for the
											“Will be 19” report.
                    </p>
                    <br></br>
										<p>
											<strong>Please Note:</strong>{" "}
											Golfers who joined online will not
											be included in the aging out
											reports.
										</p>
                  </div>
								</div>
								<div className="row">
									<div className="col is-full">
										<GhinTable
											idKey={"id"}
											columns={reportActivity}
											hideSelect={true}
											data={this.state.reports}
											sortable={false}
											pages={this.state.pages}
											loading={this.state.loadingReports}
											manual
											total={this.state.total}
											pageSize={this.state.per_page}
											onPageChange={(pageIndex) =>
												this.setState(
													{ page: pageIndex + 1 },
													() =>
														this.getAgingOutReportActivities()
												)
											}
											onPageSizeChange={(
												pageSize,
												pageIndex
											) =>
												this.setState(
													{
														per_page: pageSize,
														page: 1,
													},
													() => {
														this.getAgingOutReportActivities();
													}
												)
											}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<ConfirmationModalTypeB
					question={
						`Update Membership Type for ` +
						this.state.golfers +
						` golfers`
					}
					modalIcon={"help_outline"}
					confirmLabel={"Continue"}
					cancelLabel={"Cancel"}
					bodyQuestion={`Are you sure you would like to update the membership type for golfers listed in this report from “Junior” to the default standard membership type in their club?`}
					onCancelAction={() =>
						this.setState({ openUpdateModal: false })
					}
					onConfirmAction={() => {
						this.updateGolfersFromReport();
					}}
					openModal={this.state.openUpdateModal}
					closeModal={() => this.setState({ openUpdateModal: false })}
				/>
				<SuccessModal
					openModal={this.state.showExportUpdatedGolfersModal}
					onMainAction={() => {
						this.setState({ showExportUpdatedGolfersModal: false });
					}}
					warning={this.state.need_further_review > 0}
					warningTitle={"Please Review"}
					message={
						<Fragment>
							{this.state.successfully_updated_golfers !== 0 && (
								<p>
									The membership type for{" "}
									{this.state.successfully_updated_golfers}{" "}
									{this.state.successfully_updated_golfers > 1
										? "golfers has"
										: "golfer has"}{" "}
									been{" "}
									<span className={"underline"}>successfully</span>{" "}
									updated.
								</p>
							)}
							{this.state.need_further_review !== 0 && (
								<p>
									{" "}
									<span
										className={
											"validation__message is-error"
										}
									>
										The membership type for{" "}
										{this.state.need_further_review}{" "}
										{this.state.need_further_review > 1
											? "golfers"
											: "golfer"}{" "}
										could not be updated.
									</span>
								</p>
							)}
						</Fragment>
					}
					closeModal={() =>
						this.setState({ showExportUpdatedGolfersModal: false })
					}
					mainLabel={"OK"}
					specialButton={
						this.state.updated_golfers_url
							? this.state.updated_golfers_url
							: null
					}
				/>
			</Fragment>
		);
	}
}

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ addAlert }, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AgedOutGolfers);
